import querystring from 'querystring';
const API_HOST = process.env.REACT_APP_API_HOST;
const API_HOST_2 = process.env.REACT_APP_API_MICROSERVICES;
const API_HOST_3 = process.env.REACT_APP_API_TRAXXASIA;

export function get_api_path(path, type = '', query_params, route_params = {}) {

  let prefix;

  switch (type) {
    case 'microservices':
      prefix = API_HOST_2 + 'd3/api/';
      break;
    case 'traxxasia':
      prefix = API_HOST_3 + 'api/';
      break;
    default:
      prefix = API_HOST;
      break;
  }
  
  let url = prefix + path;
  
  // eslint-disable-next-line no-unused-vars
  for (const paramKey in route_params) url = url.replace(`:${paramKey}`, route_params[paramKey]);

  if (query_params) url += '?' + querystring.stringify(query_params);

  // console.log('url: ', url)

  return url;
}