const sections = {
  one: { 
    title: "ML/TF Internal Governance Framework",
    percent: 20
  },
  two: { 
    title: "ML/TF Risk Factor Assessment",
    percent: 40
  },
  three: { 
    title: "ML/TF Risk Mitigation Assessment",
    percent: 30
  },
  four: { 
    title: "ML/TF Training and Coordination",
    percent: 10
  }
}

const Q1 = [
  {
    no: "0101",
    title: "Compliance programme",
    weight: 3,
    text: ["Does the Company have a legal and regulatory compliance programme that includes a designated officer that is responsible for coordinating, overseeing, reviewing and updating the firm’s ML/TF risk assessment framework conducted on an enterprise-wide level covering all business units, product lines and delivery channels?"],
  },
  {
    no: "0102",
    title: "Management support",
    weight: 8,
    text: ["Does the Company’s senior management and Board approve its enterprise-wide ML/FT risk assessment, be apprised of the assessment results and provide full support and adequate resources towards mitigating potential ML/TF risks?"],
  },
  {
    no: "0103",
    title: "Documentary process",
    weight: 3,
    text: [
      "Does the Company have a documentary process evidencing the conduct of ML/TF risk assessment, implementation of technology systems and procedures to address the deficiencies identified and the management reporting and escalation of such matters to senior management and Board?",
      "Does the Company have a process to retain record of and make them available to the Authority upon request?"
    ],
    bullet: [
      "What is the local retention period for record keeping for AML documents?",
      "Does the local retention period comply with the local regulatory requirement and group policy?"
    ]
  },
  {
    no: "0104",
    title: "Risk assessment conduct",
    weight: 3,
    text: ["Does the conduct of ML/TF risk assessment allow the Company to better understand its overall vulnerability to ML/TF risks and form the basis for its overall risk-based approach?"]
  },
  {
    no: "0105",
    title: "Frequency of assessment",
    weight: 3,
    text: ["Will the Company conduct enterprise-wide ML/TF risk assessment at least once in two years or when material trigger events occur, whichever is earlier? Material trigger events include but are not limited to the acquisition of new customer segments or delivery channels or the launch of new products or services."]
  }
]

const Q2 = [
  {
    no: "0201",
    title: "Client risk",
    weight: 15,
    text: ["Does the Company consider ML/TF risk factors concerning its customers across its business units, product lines and delivery channels (in and outside Singapore), such as:"],
    bullet: [
      "Target customer markets and segments;",
      "Profile of existing customers (client segments broken down by number, country of domicile, and AML risk profile); and",
      "Volume and size of its transactions and fund transfers, considering the usual activity and risk profile of its customers."
    ]
  },
  {
    no: "0202",
    title: "Client-country risk",
    weight: 5,
    text: ["In relation to its own business activities and the customers' connected parties, and UBOs, assessed in 2.1., does the Company consider ML/TF risk factors concerning countries or jurisdictions where there is relatively higher levels of corruption, organised crime or inadequate anti- money laundering and countering of financing of terrorism measures (“AML/CFT”)?"]
  },

  {
    no: "0203",
    title: "Candidate-country risk",
    weight: 5,
    text: ["In relation to its own business activities and the customers assessed in 2.1., does the Company consider ML/TF risk factors concerning countries or jurisdictions where there is relatively higher levels of corruption, organised crime or inadequate anti-money laundering and countering of financing of terrorism measures (“AML/CFT”)?"]
  },
  {
    no: "0204",
    title: "Products, services and delivery channels",
    weight: 5,
    text: ["In relation to its products, services, transactions and delivery channels, does the Company consider the following attributes:"],
    bullet: [
      "Nature, scale, diversity and complexity of its business activities;",
      "Nature of products and services offered;",
      "Nature of delivery channels utilised?"
    ],
    text2: ["Does the Company also consider the following factors:"],
    bullet2: [
      "Whether reliance is placed on Customer Due Diligence (CDD) conducted by third parties. If so, what are the checks in place?",
      "What payment processes are utilised? Are payments made to third parties?",
      "Whether the firm’s products, services, transactions and delivery channels particularly susceptible to the following higher risk prevailing crime types: Unlicensed moneylending, Fraud, Criminal breach of trust, Foreign funding of terrorist activities, Corruption"
    ]
  },
  {
    no: "0205",
    title: "Higher risk business activities",
    weight: 10,
    text: ["Does the Company have business relationships with the following persons or entities identified as potentially presenting higher ML/TF risks:"],
    bullet: [
      "Shell banks or shell companies;",
      "Persons or private legal entities controlled or influenced by Politically Exposed Persons, their family and close associates, or state-owned entities;",
      "Entities that provide correspondent account services; and",
      "Higher ML/TF risk entities in certain non-financial sectors identified in Singapore’s National ML/TF Risk Assessment Report (such as, casinos, pawnbrokers, moneylenders and precious stones and metal dealers) or FATF’s Mutual Evaluation Reports?"
    ]
  }
]

const Q3 = [
  {
    no: "0301",
    title: "Vulnerabilities to ML/TF risks",
    weight: 6,
    text: ["Does the Company take into consideration the vulnerabilities to ML/TF risks face into assessment and if there are any risk mitigation approaches?"]
  },
  {
    no: "0302",
    title: "Appropriate EDD process",
    weight: 5,
    text: ["Does the Company determine the appropriate level of enhanced due diligence necessary for categories of customers it has reason(s) to believe pose a heightened risk of conducting illicit activities?"]
  },
  {
    no: "0303",
    title: "Customers' risk mitigation assessment",
    weight: 4,
    text: ["Does the Company assess its customers’ ML/TF risk mitigation policies or practices, where relevant?"]
  },
  {
    no: "0304",
    title: "Customer transaction assessment",
    weight: 5,
    text: ["Does the Company complete a risk-based assessment to understand the normal and expected transactions of its customers?"]
  },
  {
    no: "0305",
    title: "Customer screening procedures",
    weight: 5,
    text: ["Does the Company screen customers against a list of persons, entities and countries issued by governments and competent authorities and against known databases for derogatory information or association to identify changes in customer profile?"]
  },
  {
    no: "0306",
    title: "Business activities review",
    weight: 2,
    text: ["Does the Company conduct a review to assess if existing and new business activities, products and delivery channels would be susceptible to potential abuse and prevailing crime types?"],
    bullet: ["Has there been any significant changes in the business model or client profile impacting the AML/CTF activities?"]
  },
  {
    no: "0307",
    title: "Periodic ML/TF audits",
    weight: 3,
    text: ["Is the Company subject to periodical internal audits and annual statutory audits which cover its adherence with regulatory requirements as part of audit scope?"]
  }
]

const Q4 = [
  {
    no: "0401",
    title: "Communication of new regulations",
    weight: 4,
    text: ["Does the Company communicate new AML/CFT laws and regulations and their changes, and updates to internal policies or practices to employees?"]
  },
  {
    no: "0402",
    title: "Staff training",
    weight: 6,
    text: ["Does the Company provide AML/CFT training to its employees that includes, amongst others, these coverage:"],
    bullet: [
      "Specific AML/CFT regulatory requirements;",
      "Internal policies and procedures on AML/CFT;",
      "Examples of different forms of ML/TF and live case-studies; and",
      "Identification of ML/CFT red flags and reporting of transactions that must be reported to government authorities?",
      "Do all staff undertake AML Training?",
      "How often is the training provided?",
      "Do all staff receive the same training? If no, describe the differences in training e.g. frequency of training, level of training."
    ]
  }
]

export {
  sections,
  Q1,
  Q2,
  Q3,
  Q4
}