const STYLES = {
  "box": {
    border: "3px solid lightgray",
    borderRadius: "5px",
    mb: 4
  },
  "header": {
    backgroundColor: "#f2f0f0",
    p: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "innerBox": {
    padding: "2rem"
  }
}

export default STYLES;