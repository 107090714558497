import { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Title, TraxxButton, TraxxContainer } from "components";
import { API } from "services";
import { countries } from "utils";
import CountryList from "./CountryList";

const STYLES = {
  box: {
    display: 'flex', 
    justifyContent: 'space-between', 
    height: '2rem', 
    mb: 2
  },
  form: {
    mt: 1, 
    mb: 4, 
    minWidth: '20vw'
  },
  countryBox: {
    display: 'flex', 
    flexWrap: 'wrap', 
    gap: 0.5
  }
}

const FatfList = ({ show, selectedCountry, handleChange, blackList, handleDelete, listType, handleButtonClick, handleClick }) => {

  const textList = listType + " List";

  return <TraxxContainer component={
    <>
      <Box sx={ STYLES.box }>
        <Box><Title text={textList} /></Box>
        <Box><AddCircleIcon onClick={handleButtonClick} /></Box>
      </Box>

      { show && <FormControl sx={ STYLES.form }>
        <InputLabel id="list-label">Add to List</InputLabel>
        <Select
          labelId="list-label"
          id="select-multiple-chip"
          multiple
          value={selectedCountry || ''}
          onChange={handleChange}
          name={listType}
          renderValue={(selectedCountry) => (
            <Box key={selectedCountry} sx={ STYLES.countryBox }>
              {selectedCountry.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          label="Add to List"
        >
          {countries.map((country, index) => {
            const { code, label } = country;
            return <MenuItem
              key={code}
              value={label}
              // style={getStyles(name, personName, theme)}
            >
              {label}
            </MenuItem>
          })}
        </Select>
      </FormControl>
      }
      <CountryList countriesList={blackList} handleDelete={handleDelete} handleClick={handleClick} />
    </>
  } />
}

export default FatfList;