// features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { userLogin, userRetrieve } from './authActions';

const initialState = {
  loading: false,
  userInfo: {}, // for user object
  // userToken: null, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
}

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('d3:userToken')
      state.loading = false
      state.userInfo = null
      state.error = null
    }
  },
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      // state.userToken = payload.userToken
      state.success = true
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    // register user reducer...
    [userRetrieve.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userRetrieve.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      // state.userToken = payload.userToken
      state.success = true
    },
    [userRetrieve.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    }
  },
})

export const { logout } = userSlice.actions
export default userSlice.reducer