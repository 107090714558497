import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';

import { API } from 'services';

import BodyComponent from './BodyComponent';
import HeaderComponent from './HeaderComponent';
import { TableContainer } from '@mui/material';

const ViewInstructions = ({ department, handleGenerate, handleAction, change }) => {

  const [instructionsList, setInstructionsList] = useState([]);

  useEffect(() => {
    getInstructions();
  }, [change]);

  const getInstructions = async () => {
    const result = await API.Finance.Instructions.fetch_all_instructions();
    if (result) {
      setInstructionsList(result.reverse());
    }
  };

  return <>
  <TableContainer>
    <Table>
      
      <HeaderComponent />
      <BodyComponent 
        instructionsList={instructionsList} 
        department={department} 
        handleAction={handleAction} 
        handleGenerate={handleGenerate} 
      />
      
    </Table>
    </TableContainer>
  </>
};

export default ViewInstructions;