import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { API } from 'services';
import moment from 'moment';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from 'notistack';
import ShowApplication from './ShowApplication';

import useFetch from 'hooks/useFetch';
import useSubmitData from 'hooks/useSubmitData';
import { CircularProgress } from '@mui/material';

const headers = [
  {
    field: 'fiservId',
    headerName: "ID",
    width: 50, 
    minWidth: 50, 
    maxWidth: 50,
  },
  {
    field: 'id',
    headerName: "App URN",
    width: 150, 
    minWidth: 150, 
    maxWidth: 150,
  },
  {
    field: 'businessName',
    headerName: "Business Name",
    width: 400, 
    minWidth: 300, 
    maxWidth: 400
  },
  {
    field: 'status',
    headerName: "Status",
    width: 150, 
    minWidth: 150, 
    maxWidth: 200
  },
  {
    field: 'createdAt',
    headerName: "Created At",
    width: 150, 
    minWidth: 150, 
    maxWidth: 200
  },
  {
    field: 'updatedAt',
    headerName: "Updated At",
    width: 150, 
    minWidth: 150, 
    maxWidth: 200
  },
]

const ViewApplications = () => {

  const [showModal, setShowModal] = useState(false);
  const [selections, setSelections] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState();

  const { data: applications, loading: applicationsLoading, error: applicationsError, fetchData: fetchApplications } = useFetch();
  const { submitData, submitLoading, submitError } = useSubmitData();

  useEffect(() => {
    fetchApplications(API.Others.FiservOnboarding.fetch_applications)
  }, [])

  useEffect(() => {
    if (applicationsError) enqueueSnackbar('applications list unable to load', { variant: 'error', autoHideDuration: 3000 })
  }, [applicationsError]);

  const applicationOptions = useMemo(() => {
    if (applications) {
      const sortedApplications = applications.sort((a,b) => b.fiservId - a.fiservId)
      return sortedApplications.map(item => ({
        fiservId: item.fiservId,
        businessName: item.businessName,
        id: item.appURN,
        status: item.status ? item.status.toUpperCase() : "CREATED",
        createdAt: moment(item.createdOn).format("D MMM YYYY HH:mm"),
        updatedAt: moment(item.updatedAt).format("D MMM YYYY HH:mm"),
      }));
    }
    return [];
  }, [applications]);

  const handleShowModal = () => {
    const application = applications.find(item => item.appURN === selections[0]);
    setShowModal(true)
    setSelectedMerchant(application);
  }

  const handleStatusUpdate = async () => {
    await submitData(API.Others.FiservOnboarding.enquire_status, selections);
    fetchApplications(API.Others.FiservOnboarding.fetch_applications);
  }

  return <>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
      <Button 
        sx={{ mr: 1, minWidth: "9rem" }} 
        disabled={selections.length === 0} 
        variant="outlined"
        onClick={handleStatusUpdate}
      >
        { submitLoading ? <CircularProgress size={"1rem"} /> : "Update Status" }
      </Button>
      <Button 
        sx={{ mr: 1 }} 
        disabled={selections.length !== 1} 
        variant="outlined"
        onClick={handleShowModal}
      >
        View Application
      </Button>
    </div>

    <DataGrid
      rows={applicationOptions}
      columns={headers}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      pageSizeOptions={[10, 20, 50, 100]}
      checkboxSelection
      rowSelectionModel={selections}
      onRowSelectionModelChange={(selects) => setSelections(selects)}
    />
    
    <ShowApplication merchant={selectedMerchant} open={showModal} handleClose={() => setShowModal(false)}/>

  </>
}

export default ViewApplications;