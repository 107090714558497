
import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material"

import { API } from "services"
import { Title, TraxxContainer } from "components";
import { GridBox } from "./components"

import { EwraStats, CountryStats, ClientStats, CandidateStats } from "./statistics";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const STYLES = {
  "container": {
    my: 2
  },
  "date": {
    minWidth: "10em",
    minHeight: "2.5em",
    textAlign: "center"
  },
  "dateBox": {
    mt: 3,
    mb: 1
  }
}

const DateInput = ({ selectedDate, handleChange }) => {

  const { start, end } = selectedDate;

  return <Box sx={ STYLES.dateBox }>
    Select Date: &nbsp;
    <input type="date" name="start" style={ STYLES.date } value={start} onChange={(ev) => handleChange(ev)} />
    &nbsp;-&nbsp;
    <input type="date" name="end" style={ STYLES.date } value={end} onChange={(ev) => handleChange(ev)} />
  </Box>

}

const defaultDate = {
  start: moment().subtract(1, 'y').add(1, 'd').format('YYYY[-]MM[-]DD'),
  end: moment().format('YYYY[-]MM[-]DD')
}

const RiskAssessment = () => {

  const navigate = useNavigate();

  const [riskList, setRiskList] = useState([]);
  const [lowRiskList, setLowRiskList] = useState([]);
  const [medLRiskList, setMedLRiskList] = useState([]);
  const [medHRiskList, setMedHRiskList] = useState([]);
  const [highRiskList, setHighRiskList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  useEffect(() => {
    getRisks();
  }, [selectedDate])

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setSelectedDate({
      ...selectedDate,
      [name]: value
    })
    console.log('ev: ', ev.target)
  }

  const getRisks = async () => {
    const result = await API.Compliance.Risk.fetch_all_risk();
    if (result) {
      divideRisks(result);
      setRiskList(result);
    }
  }

  const divideRisks = (allRisks) => {

    /**
     * 1. Remove all declined/terminated cases
     * 2. Filter by Selected Date
     */
    const risks_date = allRisks.filter(item => {
      const { createdOn } = item;
      const created = moment(createdOn)
      const start = moment(selectedDate.start);
      const end = moment(selectedDate.end);
      if ((item.status === "establish" || item.status === "maintain") && (created.isSameOrBefore(end, "day") && created.isSameOrAfter(start, "day"))) return item;
    })

    /**
     * 3. Remove duplicated merchant & to keep only the latest one
     */
    const risks_merchant = risks_date.filter((item, index, arr)=> {
      return (arr.findLastIndex(val => {
        if (item.merchantId) return (val.merchantId === item.merchantId || val.entity === item.entity)
        else return (val.entity === item.entity)
      }) === index)
    });

    /**
     * 4. Sort by descending order
     */
    const risks_desc = risks_merchant.reverse();

    /**
     * 5. Divide by risk level
     */
    const lowResult = [], medLResult = [], medHResult = [], highResult = [];
    risks_desc.forEach((item) => {
      if (item.finalRisk === 'low') lowResult.push(item);
      if (item.finalRisk === 'medL') medLResult.push(item);
      if (item.finalRisk === 'medH') medHResult.push(item);
      if (item.finalRisk === 'high') highResult.push(item);
    })
    setLowRiskList(lowResult);
    setMedLRiskList(medLResult);
    setMedHRiskList(medHResult);
    setHighRiskList(highResult);

    
  }

  const defaultSum = {
    low: { black: 0, gray: 0, others: 0 },
    medL: { black: 0, gray: 0, others: 0 },
    medH: { black: 0, gray: 0, others: 0 },
    high: { black: 0, gray: 0, others: 0 }
  }
  
  const defaultTotal = { low: 0, medL: 0, medH: 0, high: 0 }
  
  const defaultScore = { low: 0, medL: 0, medH: 0, high: 0 }

  const [summation, setSummation] = useState(defaultSum);
  const [totals, setTotals] = useState(defaultTotal);
  const [totalScore, setTotalScore] = useState(defaultScore);
  const [firstAverage, setFirstAverage] = useState(0);
  const [secondAverage, setSecondAverage] = useState(0);
  const [thirdAverage, setThirdAverage] = useState(0);
  const [avgs, setAvgs] = useState();
  
  const calculateClient = (arr) => {
    let partner = 0; 
    let merchant = 0;

    arr.forEach(item => {
      const { clientType } = item;
      if (clientType === "p") partner++;
      if (clientType === "m") merchant++;
    })

    return { partner, merchant }
  }

  const calculate = (arr, sum) => {
    arr.forEach((item, index) => {
      const { countryType } = item;
      if (countryType === "gray") sum.gray++
      else if (countryType === "black") sum.black++
      else sum.others++
    })
    return sum;
  }

  const calculatePeople = (arr, sum) => {
    arr.forEach((item, index) => {
      const { people } = item;
      // console.log(people);
      // if (people?.includes("gray")) sum.gray++
      // else if (people?.includes("black")) sum.black++
      // else sum.others++
      sum.gray += (people?.match(/"gray"/g)||[]).length;
      sum.black += (people?.match(/"black"/g)||[]).length;
      const peopleStrung = people?.toString()
      
      sum.others += (peopleStrung?.match(/null/g)||[]).length;
    })
    return sum;
  }

  const getFirstSum = () => {

    const low = calculateClient(lowRiskList);
    const medL = calculateClient(medLRiskList);
    const medH = calculateClient(medHRiskList);
    const high = calculateClient(highRiskList);
    
    setSummation({ low, medL, medH, high });

    const low_total = low.partner + low.merchant;
    const medL_total = medL.partner + medL.merchant;
    const medH_total = medH.partner + medH.merchant;
    const high_total = high.partner + high.merchant;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    const low_score = low_total*1;
    const medL_score = medL_total*2;
    const medH_score = medH_total*3;
    const high_score = high_total*4;

    setTotalScore({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

   
    const totalSum = low_total + medL_total + medH_total + high_total;
    let average = 0;
    if (totalSum !== 0) average = ((low_score + medL_score + medH_score + high_score)/totalSum).toFixed(2)
    
    setFirstAverage(average);
    return(average);
  }

  const getSecondSum = () => {

    let sum = {
      low: { black: 0, gray: 0, others: 0 },
      medL: { black: 0, gray: 0, others: 0 },
      medH: { black: 0, gray: 0, others: 0 },
      high: { black: 0, gray: 0, others: 0 }
    }

    const low = calculate(lowRiskList, sum.low);
    const medL = calculate(medLRiskList, sum.medL);
    const medH = calculate(medHRiskList, sum.medH);
    const high = calculate(highRiskList, sum.high);
    
    setSummation({ low, medL, medH, high });

    
    const low_total = low.black + low.gray + low.others;
    const medL_total = medL.black + medL.gray + medL.others;
    const medH_total = medH.black + medH.gray + medH.others;
    const high_total = high.black + high.gray + high.others;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    setTotalScore({
      low: low_total*1,
      medL: medL_total*2,
      medH: medH_total*3,
      high: high_total*4,
    });

    const totalScore = low_total*1 + medL_total*2 + medH_total*3 + high_total*4;
    const totalSum = low_total + medL_total + medH_total + high_total;

    let averageSum = 0
    if (totalSum !== 0) {
      averageSum = ((totalScore)/(totalSum)).toFixed(2);
    }
    
    setSecondAverage(averageSum);
    return(averageSum);
  }

  const getThirdSum = () => {

    let sum = {
      low: { black: 0, gray: 0, others: 0 },
      medL: { black: 0, gray: 0, others: 0 },
      medH: { black: 0, gray: 0, others: 0 },
      high: { black: 0, gray: 0, others: 0 }
    }

    const low = calculatePeople(lowRiskList, sum.low);
    const medL = calculatePeople(medLRiskList, sum.medL);
    const medH = calculatePeople(medHRiskList, sum.medH);
    const high = calculatePeople(highRiskList, sum.high);
    
    setSummation({ low, medL, medH, high });

    
    const low_total = low.black + low.gray + low.others;
    const medL_total = medL.black + medL.gray + medL.others;
    const medH_total = medH.black + medH.gray + medH.others;
    const high_total = high.black + high.gray + high.others;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    setTotalScore({
      low: low_total*1,
      medL: medL_total*2,
      medH: medH_total*3,
      high: high_total*4,
    });

    const totalScore = low_total*1 + medL_total*2 + medH_total*3 + high_total*4;
    const totalSum = low_total + medL_total + medH_total + high_total;

    let averageSum = 0
    if (totalSum !== 0) {
      averageSum = ((totalScore)/(totalSum)).toFixed(2);
    }
    
    setThirdAverage(averageSum);
    return(averageSum);
  }

  useEffect(()=> { 
    
    getFirstSum();
    getSecondSum(); 
    getThirdSum(); 
    
  },[lowRiskList, medLRiskList, medHRiskList, highRiskList])

  useEffect(()=> { 

    setAvgs(firstAverage + "-" + secondAverage + "-" + thirdAverage) 
    
    
  },[firstAverage, secondAverage, thirdAverage])
  
  
  return (
    <>
      <Title text="Risk Assessment"/>

      <DateInput 
        selectedDate={selectedDate} 
        handleChange={handleChange}
      />

      <Box sx={ STYLES.container }>
        <Grid container spacing={2}>
          {avgs && <GridBox color="white" fullRisk ={{
                  low: lowRiskList, 
                  medL: medLRiskList,
                  medH: medHRiskList,
                  high: highRiskList
                }} avgs={avgs} />}
          <GridBox color="green" risk="low" riskList={lowRiskList} />
          <GridBox color="yellow" risk="medium" riskList={[...medLRiskList, ...medHRiskList]} />
          <GridBox color="red" risk="high" riskList={highRiskList} />
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <div onClick={() => navigate('/compliance/risk-assessment/ewra/latest?data='+avgs)} style={{ cursor: 'pointer' }}>
            <TraxxContainer component={<EwraStats riskList={{
                  low: lowRiskList,
                  medL: medLRiskList,
                  medH: medHRiskList,
                  high: highRiskList
                }}/>} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container>
            {/* <Grid item xs={12}>
              <TraxxContainer component={
                <GeneralStats total={{
                  low: lowRiskList.length,
                  medL: medLRiskList.length,
                  medH: medHRiskList.length,
                  high: highRiskList.length
                }} /> 
              } />
            </Grid> */}
            <Grid item xs={12}>
              <TraxxContainer component={
                <ClientStats riskList={{
                  low: lowRiskList,
                  medL: medLRiskList,
                  medH: medHRiskList,
                  high: highRiskList
                }} />
              } />
            </Grid>
            <Grid item xs={12}>
              <TraxxContainer component={
                <CountryStats riskList={{
                  low: lowRiskList,
                  medL: medLRiskList,
                  medH: medHRiskList,
                  high: highRiskList
                }} />
              } />
            </Grid>
            <Grid item xs={12}>
              <TraxxContainer component={
                <CandidateStats riskList={{
                  low: lowRiskList,
                  medL: medLRiskList,
                  medH: medHRiskList,
                  high: highRiskList
                }} />
              } />
            </Grid>
          </Grid>
        </Grid>

        
      </Grid>
    </>
  )
}

export default RiskAssessment;