import React from "react";
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Title } from "components";
import { useTheme } from "@mui/material";

import { sections, Q1, Q2, Q3, Q4 } from "../texts";
import { Typography } from "@mui/material";
import { API } from "services";
import moment from "moment";

const headers = [
  "Risk Factors",
  "Risk Weight",
  "Risk Score",
  "Weighted Risk Score"
]

const SectionTitle = ({ info, index }) => {
  const { title, percent } = info;
  return <TableRow sx={{ backgroundColor: '#f6f5fa' }}>
    <TableCell><b>{ `${index}. ${title}`}</b></TableCell>
    <TableCell><b>{ `${percent}%` }</b></TableCell>
    <TableCell></TableCell>
    <TableCell></TableCell>
  </TableRow>
}

const SectionBody = ({ arr, ewra }) => {
  return <React.Fragment>
    { arr.map(item => {
    const { no, title , weight } = item;
    const score = ewra.score && (ewra.score[no] || 0);
    return <TableRow key={no}>
      <TableCell>{ title }</TableCell>
      <TableCell>{ weight }%</TableCell>
      <TableCell>{ score }</TableCell>
      <TableCell>{ (weight/100*score).toFixed(2)  }</TableCell>
    </TableRow>
  })}
  </React.Fragment>
}

const EwraStats = ({ riskList }) => {
  const theme = useTheme();

  const STYLES = {
    tableRow: {
      py: 0
    },
    results: {
      color: theme.palette.primary.main,
      fontStyle: "italic"
    }
  }

  const [ewra, setEwra] = useState({});
  const [total, setTotal] = useState(0);

  // console.log('ewra: ', ewra)

  useEffect(() => {
    
    getFirstSum();
    getSecondSum();
    getThirdSum();
    getEwra();
    
  }, [riskList])

  useEffect(() => {
    getEwra();
    
  }, [])
  
  const defaultSum = {
    low: { black: 0, gray: 0, others: 0 },
    medL: { black: 0, gray: 0, others: 0 },
    medH: { black: 0, gray: 0, others: 0 },
    high: { black: 0, gray: 0, others: 0 }
  }
  
  const defaultTotal = { low: 0, medL: 0, medH: 0, high: 0 }
  
  const defaultScore = { low: 0, medL: 0, medH: 0, high: 0 }

  const [summation, setSummation] = useState(defaultSum);
  const [totals, setTotals] = useState(defaultTotal);
  const [totalScore, setTotalScore] = useState(defaultScore);
  const [firstAverage, setFirstAverage] = useState();
  const [secondAverage, setSecondAverage] = useState(0);
  const [thirdAverage, setThirdAverage] = useState(0);

  

  const calculateClient = (arr) => {
    let partner = 0; 
    let merchant = 0;

    arr.forEach(item => {
      const { clientType } = item;
      if (clientType === "p") partner++;
      if (clientType === "m") merchant++;
    })

    return { partner, merchant }
  }

  const calculate = (arr, sum) => {
    arr.forEach((item, index) => {
      const { countryType } = item;
      if (countryType === "gray") sum.gray++
      else if (countryType === "black") sum.black++
      else sum.others++
    })
    return sum;
  }

  const calculatePeople = (arr, sum) => {
    arr.forEach((item, index) => {
      const { people } = item;
      // console.log(people);
      // if (people?.includes("gray")) sum.gray++
      // else if (people?.includes("black")) sum.black++
      // else sum.others++
      sum.gray += (people?.match(/"gray"/g)||[]).length;
      sum.black += (people?.match(/"black"/g)||[]).length;
      const peopleStrung = people?.toString()
      
      sum.others += (peopleStrung?.match(/null/g)||[]).length;
    })
    return sum;
  }

  const getFirstSum = () => {

    const low = calculateClient(riskList.low);
    const medL = calculateClient(riskList.medL);
    const medH = calculateClient(riskList.medH);
    const high = calculateClient(riskList.high);
    
    setSummation({ low, medL, medH, high });

    const low_total = low.partner + low.merchant;
    const medL_total = medL.partner + medL.merchant;
    const medH_total = medH.partner + medH.merchant;
    const high_total = high.partner + high.merchant;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    const low_score = low_total*1;
    const medL_score = medL_total*2;
    const medH_score = medH_total*3;
    const high_score = high_total*4;

    setTotalScore({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

   
    const totalSum = low_total + medL_total + medH_total + high_total;
    let average = 0;
    if (totalSum !== 0) average = ((low_score + medL_score + medH_score + high_score)/totalSum).toFixed(2)
    
    // setFirstAverage(average);
    return(average);
  }

  const getSecondSum = () => {

    let sum = {
      low: { black: 0, gray: 0, others: 0 },
      medL: { black: 0, gray: 0, others: 0 },
      medH: { black: 0, gray: 0, others: 0 },
      high: { black: 0, gray: 0, others: 0 }
    }

    const low = calculate(riskList.low, sum.low);
    const medL = calculate(riskList.medL, sum.medL);
    const medH = calculate(riskList.medH, sum.medH);
    const high = calculate(riskList.high, sum.high);
    
    setSummation({ low, medL, medH, high });

    
    const low_total = low.black + low.gray + low.others;
    const medL_total = medL.black + medL.gray + medL.others;
    const medH_total = medH.black + medH.gray + medH.others;
    const high_total = high.black + high.gray + high.others;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    setTotalScore({
      low: low_total*1,
      medL: medL_total*2,
      medH: medH_total*3,
      high: high_total*4,
    });

    const totalScore = low_total*1 + medL_total*2 + medH_total*3 + high_total*4;
    const totalSum = low_total + medL_total + medH_total + high_total;

    let averageSum = 0
    if (totalSum !== 0) {
      averageSum = ((totalScore)/(totalSum)).toFixed(2);
    }
    
    // setSecondAverage(averageSum);
    return(averageSum);
  }

  const getThirdSum = () => {

    let sum = {
      low: { black: 0, gray: 0, others: 0 },
      medL: { black: 0, gray: 0, others: 0 },
      medH: { black: 0, gray: 0, others: 0 },
      high: { black: 0, gray: 0, others: 0 }
    }

    const low = calculatePeople(riskList.low, sum.low);
    const medL = calculatePeople(riskList.medL, sum.medL);
    const medH = calculatePeople(riskList.medH, sum.medH);
    const high = calculatePeople(riskList.high, sum.high);
    
    setSummation({ low, medL, medH, high });

    
    const low_total = low.black + low.gray + low.others;
    const medL_total = medL.black + medL.gray + medL.others;
    const medH_total = medH.black + medH.gray + medH.others;
    const high_total = high.black + high.gray + high.others;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    setTotalScore({
      low: low_total*1,
      medL: medL_total*2,
      medH: medH_total*3,
      high: high_total*4,
    });

    const totalScore = low_total*1 + medL_total*2 + medH_total*3 + high_total*4;
    const totalSum = low_total + medL_total + medH_total + high_total;

    let averageSum = 0
    if (totalSum !== 0) {
      averageSum = ((totalScore)/(totalSum)).toFixed(2);
    }
    
    // setThirdAverage(averageSum);
    return(averageSum)
  }

  

  const getEwra = async () => {

    const result = await API.Compliance.Risk.fetch_ewra();
    if (result) {
      const { id, createdOn, choice, score, comment } = result[result.length-1];
      const data = {
        id,
        createdOn,
        choice: JSON.parse(choice),
        score: JSON.parse(score),
        comment: JSON.parse(comment)
      }
      setEwra(data);      

      //calculate total weighted risk score
      const questions = [ ...Q1, ...Q2, ...Q3, ...Q4 ];
      let total = 0;
      let newScore = 0;
      questions.forEach(item => {
        const { no, weight } = item;
      //   if (data.score[no]) total += parseInt(data.score[no])*(weight/100)
      // })
      // setTotal(total.toFixed(2))
      //   }
      // }

      if (data.score[no]) { 
          
        total += parseInt(data.score[no])*(weight/100);
        
      }
      setTotal(total.toFixed(2))

      if (item.no === "0201") {
        data.score[no] = getFirstSum();
      }
      if (item.no === "0202") {
        data.score[no] = getSecondSum();
      }
      if (item.no === "0203") {
        data.score[no] = getThirdSum();
      }
    })
    
    const newData = {
      id,
      createdOn,
      choice: JSON.parse(choice),
      score: data.score,
      comment: JSON.parse(comment)
    }
    setEwra(newData);

    
  }
}
    

  return <>
  <Title text="EWRA Scoring" />
  <Typography variant="subtitle2" sx={{ my: 1, color: 'gray' }}>Updated: { moment(ewra?.createdOn).format('LLL') }</Typography>
  <Typography variant="subtitle2" sx={{ mt: 1, mb: 3, color: 'gray' }}>
    A summation of the numbers indicates a Risk Assessment Score of <b>{total}</b> and hence the Company's enterprise-wide ML/TF risk is assessed to be <b>{ total < 2 ? "low" : total < 3 ? "moderate" : "high" }</b>.
  </Typography>
  
  <TableContainer sx={{ width: "100%" }}>
    <Table size="small">
    <TableHead>
      <TableRow>
        { headers.map(item => <TableCell key={item}><b>{ item }</b></TableCell>) }
      </TableRow>
    </TableHead>
    <TableBody>

      <TableRow>
        <TableCell sx={ STYLES.results }>Risk Assessment Score</TableCell>
        <TableCell sx={ STYLES.results }>100%</TableCell>
        <TableCell sx={ STYLES.results }></TableCell>
        <TableCell sx={ STYLES.results }>{ total }</TableCell>
      </TableRow>

      <SectionTitle 
        info={sections.one}
        index={1}
      />
      <SectionBody 
        arr={Q1}
        ewra={ewra}
      />
      
      <SectionTitle 
        info={sections.two}
        index={2}
        /> 
       <SectionBody 
        arr={Q2}
        ewra={ewra}
        />

      <SectionTitle 
        info={sections.three}
        index={3}
      />
      <SectionBody 
        arr={Q3}
        ewra={ewra}
      />

      <SectionTitle 
        info={sections.four}
        index={4}
      />
      <SectionBody 
        arr={Q4}
        ewra={ewra}
      />

    </TableBody>
    </Table>
  </TableContainer>

  <Typography variant="subtitle2" sx={{ mt: 4, color: 'gray' }}>
    Risk Assessment Score:
    <ul>
      <li>1.0 - 2.0: ML/TF risk on an enterprise-wide basis is low</li>
      <li>2.0 - 3.0: ML/TF risk on an enterprise-wide basis is moderate</li>
      <li>3.0 - 4.0: ML/TF risk on an enterprise-wide basis is high</li>
    </ul>
  </Typography>
  </>
}

export default EwraStats;