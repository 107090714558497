import { sessioned_client } from '../auth';
import { get_api_path } from '../api';

export async function fetch_all_instructions() {
  const url = get_api_path('finance/instructions/list');
  const response = await sessioned_client().get(url);
  return response.data;
};

export async function create_instructions(data) {
  // create standing instructions in database
  const url = get_api_path('finance/instructions/create');
  const response = await sessioned_client().post(url, data);
  create_zoho(response.data.instrId, data);
  return response.data;
}

export async function create_zoho(id, data) {
  // create document on zohosign
  const url = get_api_path('finance/createInstructions', 'microservices');
  const zohoResult = await sessioned_client().post(url, data);
  const { requestID } = zohoResult.data.result;

  // update instructions in database with zoho request id
  const url2 = get_api_path('finance/instructions/update/zohoRequestId');
  const updateResult = await sessioned_client().post(url2, { instrId: id, requestID });

  return updateResult;
}

export async function get_report(data) {
  const url = get_api_path('finance/generateReport', 'microservices');
  const result = await sessioned_client().post(url, data);
  return result.data.result;
}