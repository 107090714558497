import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import TextField from "@mui/material/TextField";

import { Title, TraxxContainer } from "components";
import { Section1, Section2, Section3, DirectorUBO } from "./components";
import { 
  Q1P1a, Q1P1b, Q1P2, 
  Q2P1, Q2P2, Q2P3, Q2P4
} from './texts'
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "utils";

import { API } from 'services';

import { enqueueSnackbar, SnackbarProvider } from 'notistack';

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

const STYLES = {
  "container": {
    mt: 2,
    backgroundColor: "white",
    boxShadow: '0px 0px 10px lightgray',
    borderRadius: "6px",
    p: 2
  },
  "inputBox": {
    p: 1,
    border: '1px solid black',
    backgroundColor: 'black'
  }
}

const defaultData = {
  Q0: Array(2).fill(''),
  Q1P1a: Array(Q1P1a.length).fill(''),
  Q1P1b: Array(Q1P1b.length).fill(''),
  Q1P2: Array(Q1P2.length).fill(''),
  Q2P1: Array(Q2P1.length).fill(''),
  Q2P2: Array(Q2P2.length).fill(''),
  Q2P3: Array(Q2P3.length).fill(''),
  Q2P4: Array(Q2P4.length).fill(''),
  Q3: Array(4).fill('')
}

const defaultCount = {
  Q2P1: { low: 0, med: 0, high: 0 },
  Q2P2: { low: 0, med: 0, high: 0 },
  Q2P3: { low: 0, med: 0, high: 0 },
  Q2P4: { low: 0, med: 0, high: 0 }
};

const defaultPeople = {
  director: [{ name: '', country: '', countryType: '' }],
  ubo: [{ name: '', country: '', countryType: '' }],
}

const CreateAssessment = () => {

  const navigate = useNavigate();

  const [ data, setData ] = useState(defaultData);
  const [ count, setCount] = useState(defaultCount);
  const [ loading, setLoading ] = useState(false);
  const [ merchants, setMerchants ] = useState();
  const [ selectedMerchant, setSelectedMerchant ] = useState('');
  const [ result, setResult ] = useState('');
  const [ people, setPeople ] = useState(defaultPeople);

  useEffect(() => {
    calculateRisk();
  }, [data])

  const calculateRisk = () => {
    let newCount = defaultCount;
    for (const question in data) {
      if (!question.match(/Q2/)) continue;
      let low = 0, med = 0, high = 0;
      for (const item of data[question]) {
        if (item === "l-yes" || item === "l-no") low++
        if (item === "m-yes" || item === "m-no") med++
        if (item === "h-yes" || item === "h-no") high++
      }
      newCount = { ...newCount, [question]: { low, med, high }}
    }

    const sumMed = newCount.Q2P1.med + newCount.Q2P2.med + newCount.Q2P3.med + newCount.Q2P4.med;
    const sumHigh = newCount.Q2P1.high + newCount.Q2P2.high + newCount.Q2P3.high + newCount.Q2P4.high;

    // According to Risk Scoring Matrix
    let newResult = '';
    if (sumHigh > 0 || sumMed >= 5) newResult = 'high';
    else if (sumMed === 4) newResult = 'medH';
    else if (sumMed === 3) newResult = 'medL';
    else newResult = 'low'
    setResult(newResult);
    setCount(newCount);
  }

  const handleChange = (ev, qNo, index) => {
    // if (qNo === "Q3" && index === 0) setResult(ev.target.value);
    const dataToChange = JSON.parse(JSON.stringify(data));
    dataToChange[qNo][index] = ev.target.value;
    setData({ ...dataToChange });
  }

  const submitForm = async () => {
    setLoading(true);
    const { Q0, ...answers } = data;
    answers.Q3.splice(0, 1, result);
    if (result === "") {
      enqueueSnackbar("Please enter a risk rating!", { variant: 'error', autoHideDuration: 3000 });
      setLoading(false);
      return;
    }
    if (data.Q3[3] === "") {
      enqueueSnackbar("Please enter a recommendation!", { variant: 'error', autoHideDuration: 3000 });
      setLoading(false);
      return;
    }

    // check whether country is in gray/black list
    const fatfList = await API.Compliance.Risk.fetch_country_risk();
    const country_fatf = fatfList[fatfList.length-1];
    let countryType = ""
    const code = countries.find(item => item.label === data.country)?.code;
    if (country_fatf.blackList?.includes(code)) countryType = "black";
    else if (country_fatf.grayList?.includes(code)) countryType = "gray";
    else if (country_fatf.othersList?.includes(code)) countryType = "others";

    const categorise = (arr) => {
      return arr.map(item => {
        let countryType = ""
        if (!item.countryType) countryType = null;
        else if (country_fatf.blackList?.includes(item.countryType)) countryType = "black"
        else if (country_fatf.grayList?.includes(item.countryType)) countryType = "gray"
        else if (country_fatf.othersList?.includes(item.countryType)) countryType = "others"
        else countryType = null
        return { ...item, countryType }
      })
    }

    const updatedDirector = categorise(people.director);
    const updatedUBO = categorise(people.ubo);
    const updatedPeople = { director: updatedDirector, ubo: updatedUBO }

    const body = {
      entity: data.Q0[0],
      answers: JSON.stringify(answers),
      count: JSON.stringify(count),
      finalRisk: data.Q3[2] !== '' ? data.Q3[2] : result,
      status: data.Q3[3],
      merchantId: data.Q0[1],
      country: data.country,
      clientType: data.Q0[2],
      countryType,
      people: JSON.stringify(updatedPeople)
    };

    console.log('body: ', body)
    try {
      const result = await API.Compliance.Risk.create_risk(body);
      if (result) {
        setLoading(false);
        enqueueSnackbar("Form successfully created!", {
          variant: 'success',
          autoHideDuration: 3000
        })
        navigate(`/compliance/risk-assessment/list`)
      } else {
        enqueueSnackbar("Error creating form", {
          variant: 'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  useEffect(() => {
    list_merchants();
  }, [])

  const list_merchants = async () => {
    const merchantArr = await API.Operations.Agreement.fetch_all_merchants({ nolimit: true });
    if (merchantArr) {
      const completedMerchants = merchantArr.filter(item => item.current_status === 'completed');
      setMerchants(completedMerchants);
    }
  }

  const handleCountryChange = (event, newValue) => {
    setData({
      ...data,
      country: newValue?.label
    })
  }

  const handleMerchantChange = (event, newValue) => {
    setSelectedMerchant(newValue);
    if (!newValue) {
      setData(defaultData)
      return
    }
    const current = merchants.find(item => JSON.parse(item.enquiry_info).companyName === newValue);
    if (current) {
      const { id } = current;
      setData({ ...data, Q0: [newValue, id] })
    } else {
      setData({ ...data, Q0: [newValue, null] })
    }
  }

  const handleInputChange = (event, newValue) => {
    setData({ ...data, Q0: [newValue, null]})
  }

  const handleAddPeople = (type) => {
    setPeople({ ...people, [type]: [ ...people[type], { name: '', country: '', countryType: '' } ]})
  }

  const handleChangePeopleName = (ev, type, index) => {
    const { value } = ev.target;
    setPeople({
      ...people,
      [type]: [ 
        ...people[type].slice(0, index), 
        { ...people[type][index], name: value },
        ...people[type].slice(index+1)
     ]
    })
  }

  const handleChangePeopleCountry = (ev, val, type, index) => {
    setPeople({
      ...people,
      [type]: [
        ...people[type].slice(0, index),
        { ...people[type][index], country: val?.label, countryType: val?.code },
        ...people[type].slice(index+1)
      ]
    })
  }

  return <>
    <Title text="Add New Risk Assessment"/>
      <TraxxContainer component={
        <>
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <Typography>Date of Assessment: <b>{ moment().format('Do MMMM YYYY') }</b></Typography>
          </Box>

          { merchants && <Autocomplete
              freeSolo
              value={selectedMerchant}
              options={merchants.map(item => JSON.parse(item.enquiry_info).companyName)}
              onChange={(event, newValue) => handleMerchantChange(event, newValue)}
              onInputChange={(event, newValue) => handleInputChange(event, newValue)}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Select Merchant" />}
            /> 
          }

          <Autocomplete
            autoComplete={false}
            id="country-select-demo"
            sx={{ my: 2 }}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option?.label}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option?.label} ({option.code})
              </Box>
            )}
            onChange={(ev, val) => handleCountryChange(ev, val)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                inputProps={{
                  ...params.inputProps
                }}
              />
            )}
          />

          <FormControl fullWidth >
            <InputLabel id="type-label">Client Type</InputLabel>
            <Select
              labelId="type-label"
              fullWidth
              label="Client Type"
              onChange={(ev) => handleChange(ev, "Q0", 2)}
              defaultValue=""
            >
              <MenuItem value="m">Merchant</MenuItem>
              <MenuItem value="p">Partner/Master Merchant</MenuItem>
            </Select>
          </FormControl>       
        </>
      }
    />

    <TraxxContainer component={
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DirectorUBO 
            type="director" 
            handleAdd={() => handleAddPeople("director")}
            data={people.director}
            handleChangePeopleName={handleChangePeopleName}
            handleChangePeopleCountry={handleChangePeopleCountry}
          />
        </Grid>
        <Grid item xs={6}> 
          <DirectorUBO 
            type="ubo" 
            handleAdd={() => handleAddPeople("ubo")}
            data={people.ubo}
            handleChangePeopleName={handleChangePeopleName}
            handleChangePeopleCountry={handleChangePeopleCountry}
          />
        </Grid>
      </Grid>
    } />

    <TraxxContainer component={
      <>
        <Section1 defaultData={defaultData} data={data} setData={setData} handleChange={handleChange} />
        <Section2 defaultData={defaultData} data={data} setData={setData} handleChange={handleChange} count={count} />
        <Section3 defaultData={defaultData} data={data} setData={setData} handleChange={handleChange} result={result} />
        {
          loading ? <Box textAlign="center"><Button variant="outlined" sx={{ m: 3, width: "10rem", p: 1 }}><CircularProgress size={"1rem"} /></Button></Box>
          : <Box textAlign="center"><Button variant="outlined" sx={{ m: 3, width: "10rem" }} onClick={submitForm}>Submit</Button></Box>
        }
      </>
      }
    />
  </>
}

export default CreateAssessment;