import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

const inputs = [
  "Card Brand - MDR",
  "Card Brand - MDR (Remark)",

  "Rolling Reserve",
  "Rolling Reserve (Remark)",

  "Transaction Fee (Please include currency)",
  "Transaction Fee (Remark)",

  "Chargeback Fee (Please include currency)",
  "Chargeback Fee (Remark)",

  "Refund Fee (Please include currency)",
  "Refund Fee (Remark)",

  "Settlement Period",
  "Settlement Period (Remark)",

  "Setup Fee (Please include currency)",
  "Setup Fee (Remark)",

  "Annual Fee (Please include currency)",
  "Annual Fee (Remark)",

  "Representment/Dispute Fee (Please include currency)",
  "Representment/Dispute Fee (Remark)",

  "Conversion Fee - FX (Please include currency)",
  "Conversion Fee - FX (Remark)",

  "Wire Fee (Please include currency)",
  "Wire Fee (Remark)",

  "Settlement Fee (Please include currency)",
  "Settlement Fee (Remark)",

  "Fraud Fee (Please include currency)",
  "Fraud Fee (Remark)",

  "Gateway Fee (Please include currency)",
  "Gateway Fee (Remark)",
]

const FeeStructure = ({ fee, handleFeeChange }) => {

  return (
    <Box component="form" noValidate sx={{ px: 2, py: 1 }}>
      <Typography>Fee Structure</Typography>

      <TextField  
        margin="normal"
        fullWidth
        label="Card Type"
        name="0"
        multiline
        onChange={(ev) => handleFeeChange(ev)}
        value={ fee[0] || '' }
        sx={{ mb: 3, pr: 2 }}
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <b>Sell Rate (to merchant)</b>
        </Grid>
        <Grid item xs={6}>
          <b>Remark</b>
        </Grid>
      </Grid>

      <Grid container>
        { inputs.map((item, index1) => {
          const index = index1 + 1;
          return <Grid key={index} item xs={6} sx={{ pr: 2 }}>
          <TextField  
            margin="normal"
            fullWidth
            label={item}
            name={index.toString()}
            multiline
            onChange={(ev) => handleFeeChange(ev)}
            value={ fee[index] || '' }
          />
        </Grid>
        })}
      </Grid>

    </Box>
  )
}

export default FeeStructure;