import { useState, useEffect } from 'react';
import moment from 'moment';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate, useNavigate, useLocation } from 'react-router-dom'

import { API } from 'services';
import { Title, TraxxContainer, HeaderComponent } from 'components';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const header = [
  {
    id: 'index',
    numeric: false,
    disablePadding: true,
    label: 'No.',
  },
  {
    id: 'entity',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'client_type',
    numeric: false,
    disablePadding: false,
    label: 'Client Type',
  },
  {
    id: 'director_ubo',
    numeric: false,
    disablePadding: false,
    label: 'Director/UBO',
  },
  {
    id: 'final_risk',
    numeric: false,
    disablePadding: false,
    label: 'Risk Rating',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
  {
    id: 'sign',
    numeric: false,
    disablePadding: false,
    label: 'Sign Status',
  },
  {
    id: 'document',
    numeric: false,
    disablePadding: false,
    label: 'Document',
  },
  {
    id: 'submit',
    numeric: false,
    disablePadding: false,
    label: 'Send',
  },
  {
    id: 'created_on',
    numeric: false,
    disablePadding: false,
    label: 'Date of Creation',
  },
  {
    id: 'edit',
    numeric: false,
    disablePadding: false,
    label: 'Edit',
  },
];

const COLOR = {
  low: 'success',
  medL: 'warning',
  medH: 'warning',
  high: 'error',
  maintain: 'info',
  establish: 'success',
  decline: 'primary',
  terminate: 'error',
  gray: 'warning',
  black: 'error',
  others: 'error'
}

const BodyComponent = ({ riskList }) => {

  const [loading, setLoading] = useState({})
  const [index, setIndex] = useState()

  const handleSendZoho = async (id, requestID) => {
    setLoading({ [id]: true })
    const result = await API.Misc.send_zoho_doc(requestID);
    if (result) {
      if (result.data.result.status === 'failure') {
        enqueueSnackbar('Unable to send document!', {
          variant: 'error',
          autoHideDuration: 3000
        })
      } else {
        enqueueSnackbar(`Document successfully sent!`, {
          variant: 'success',
          autoHideDuration: 3000
        })
      }
    }
    setLoading({ [id]: false })
  }

  console.log('loading: ', loading)

  const navigate = useNavigate()

  const editClick = (index) => {
    setIndex(index)
    navigate('/compliance/risk-assessment/edit?data='+index)
  }

  const progress = (zohoStatus) => {
    if (zohoStatus === "completed") {
      return <Chip label="Complete" color="success" />
    } else {
      if (zohoStatus === "inprogress") {
        return <Chip label="In Progress" variant="outlined" color="warning" />
      } else {
          return <Chip label="Pending" variant="outlined" color="info" />
      }
    } 
  }


  return (
    <TableBody>
      { riskList.map((item, index) => {
          const { id, entity, createdOn, country, finalRisk, status, requestID, zohoStatus, clientType, people } = item;
          const formattedDate = moment(createdOn).format('lll');
          let peopleType = "";
          if (people?.includes("black")) peopleType = "black";
          else if (people?.includes("others")) peopleType = "others";
          else if (people?.includes("gray")) peopleType = "gray";

          return <TableRow key={id}>
            <TableCell>{ id }</TableCell>
            <TableCell>{ entity }</TableCell>
            <TableCell sx={{ width: "10%" }}>{ country }</TableCell>
            <TableCell>{ clientType === "p" ? "Partner" : "m" ? "Merchant" : "" }</TableCell>

            <TableCell>{ peopleType === "gray" || peopleType === "black" || peopleType === "others" ? <Chip label={peopleType} color={COLOR[peopleType]} variant="outlined"></Chip> : ""}</TableCell>
            <TableCell><Chip label={finalRisk === "medH" ? "med/high" : finalRisk === "medL" ? "low/med" : finalRisk} color={COLOR[finalRisk]} variant="outlined" /></TableCell>
            <TableCell><Chip label={status} color={COLOR[status]} variant="outlined" /></TableCell>
            <TableCell>{ progress(zohoStatus) }</TableCell>
            <TableCell>{ requestID ? <IconButton onClick={() => API.Misc.fetch_zoho_doc(requestID)}><PictureAsPdfIcon /></IconButton> : <IconButton disabled><PictureAsPdfIcon /></IconButton> }</TableCell>
            { loading[id] ?
            <TableCell><IconButton disabled><CircularProgress size={"1rem"} /></IconButton></TableCell>
            :
            <TableCell>{ !zohoStatus ? <IconButton onClick={() => handleSendZoho(id, requestID)}><SendIcon /></IconButton> : <IconButton disabled><SendIcon /></IconButton> }</TableCell>
            }
            <TableCell>{ formattedDate }</TableCell>
            <TableCell><button><EditIcon onClick={() => editClick(index)} style={{ color: 'gray' }}/></button></TableCell>
          </TableRow>
        })
      }    
    </TableBody>
  )
};

const ViewAssessment = () => {

  const [riskList, setRiskList] = useState([]);

  useEffect(() => {
    getRisks();
  }, []);

  const getRisks = async () => {
    const result = await API.Compliance.Risk.fetch_all_risk();
    if (result) {
      setRiskList(result);
    }
  };

  return <>
    <Title text="View Assessments List"></Title>
    {/* <TraxxContainer component={
      <TextField fullWidth label="search"></TextField>
    }
    /> */}
    <TraxxContainer component={
      <>
      <TableContainer>
        <Table>
          <HeaderComponent header={header} />
          <BodyComponent riskList={riskList?.reverse() || []} />
        </Table>
      </TableContainer>
      </>
    } 
    />
  </>
};

export default ViewAssessment;