import { useState, useEffect } from "react";
import { Title, TraxxContainer, TraxxButton } from "components";
import { Q1, Q2, Q3, Q4 } from "./texts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import { useTheme } from '@mui/material/styles';
import { API } from "services";
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from "moment";
import { TableContainer } from "@mui/material";



const STYLES = {
  question: {
    pt: 4,
    pb: 3,
    px: 2,
    borderBottom: '1px solid lightgray'
  },
  col1: {
    width: "50%"
  },
  col2: {
    width: "5%"
  },
  col3: {
    width: "5%"
  },
  col4: {
    width: "30%"
  },
  col5: {
    width: "10%"
  },
  submitBtn: {
    mt: 2
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "2rem",
    
  },
  title: {
    fontSize: 12,
  }
}

const headers = [
  "Questions",
  "Yes/No",
  "Risk Score",
  "Comments",
  "Weighted Risk Score"
];

const EwraQuestions = ({ no, text, bullet , text2, bullet2 }) => {
  return <>
    {
      text.map((q, index) => {
        return <Typography key={index} variant="subtitle2">{ q }</Typography>
      })
    }
    {
      bullet && <ul>
        { bullet.map((points, index) => {
            return <li key={index}>{ points }</li>
          })
        }
      </ul>
    }
    {
      text2 && text2.map((q, index) => {
        return <Typography key={index} variant="subtitle2">{ q }</Typography>
      })
    }
    {
      bullet2 && <ul>
        { bullet2.map((points, index) => {
            return <li key={index}>{ points }</li>
          })
        }
      </ul>
    }
  </>
}

const EwraSection = ({ arr, text, ewra }) => {

  

  return <TraxxContainer component={
    <>
      <Title text={text} sx = {STYLES.title}/>

      <TableContainer>
      <Table sx={ STYLES.table }>
        <TableHead>
          <TableRow>
            {
              headers.map((item, index) => {
                return <TableCell key={index}><b>{ item }</b></TableCell>
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
        {
          arr.map((question, index) => {
            const { no, text, bullet, text2, bullet2 } = question;

            return <TableRow key={index} sx={ STYLES.question }>
              <TableCell sx={ STYLES.col1 }>
                <EwraQuestions no={no} text={text} bullet={bullet} text2={text2} bullet2={bullet2} />
              </TableCell>

              <TableCell sx={ STYLES.col2 }>
                { ewra && (ewra.choice[no] === 'y' ? "Yes" : "No") }
              </TableCell>

              <TableCell sx={ STYLES.col3 }>
                { ewra && ewra.score[no] || 0 }
              </TableCell>

              <TableCell sx={ STYLES.col4 }>
                { ewra && ewra.comment[no] || "-" }
              </TableCell>

              <TableCell sx={ STYLES.col5 }>
                { ewra && (parseInt(ewra?.score[no])*(arr[index].weight/100)).toFixed(2) || "-" }
              </TableCell>

            </TableRow>
          })
        }
        </TableBody>
      </Table>
      </TableContainer>
      
      </>
    } />
}

const LatestEwra = () => {

  const [ewra, setEwra] = useState();

  useEffect(() => {
    getEwra();
  }, [])

  const search = useLocation().search;
	const data = new URLSearchParams(search).get("data");
  const avgs = data.split("-");

  const getEwra = async () => {
    const result = await API.Compliance.Risk.fetch_ewra();
    if (result) {
      const { id, createdOn, choice, score, comment } = result[result.length-1];
      const data = {
        id,
        createdOn,
        choice: JSON.parse(choice),
        score: JSON.parse(score),
        comment: JSON.parse(comment)
      }
      setEwra(data);

      const questions = [ ...Q1, ...Q2, ...Q3, ...Q4 ];
      questions.forEach(item => {
      const { no, weight } = item;

      if (item.no === "0201") {
        data.score[no] = avgs[0]
      }
      if (item.no === "0202") {
        data.score[no] = avgs[1]
      }
      if (item.no === "0203") {
        data.score[no] = avgs[2]
      }
    })
    
    const newData = {
      id,
      createdOn,
      choice: JSON.parse(choice),
      score: data.score,
      comment: JSON.parse(comment)
    }
    setEwra(newData);
    }
  }

  return <>
    <Box sx={ STYLES.box }>
      <Title text={`Latest EWRA [Submitted: ${ewra && moment(ewra.createdOn).format('LLL')}]`} />
    </Box>
    <EwraSection
      ewra={ewra} 
      arr={Q1}
      text="1. ML/TF Risk Internal Governance Framework Assessment"
    />

    <EwraSection 
      ewra={ewra} 
      arr={Q2}
      text="2. Enterprise-wide ML/TF Risk Factors Assessment"
    />

    <EwraSection 
      ewra={ewra} 
      arr={Q3}
      text="3. Enterprise-wide ML/TF Risk Mitigation Assessment"
    />

    <EwraSection 
      ewra={ewra} 
      arr={Q4}
      text="4. Enterprise-wide AML/CFT Training and Coordination Assessment"
    />
  </>
}

export default LatestEwra;