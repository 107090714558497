import React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Title } from 'components';
import { API } from 'services';
import moment from 'moment';


const infoList = [
  {
    label: 'Date',
    name: 'date',
  },
  {
    label: 'Attention',
    name: 'attention',
  },
  {
    label: 'Remitting to',
    name: 'remitting',
  },
  {
    label: 'Address',
    name: 'address',
  },
  {
    label: 'Merchant ID',
    name: 'merchantId',
    type: 'input'
  },
  {
    label: 'Payment Advice Reference',
    name: 'paymentRef',
  },
  {
    label: 'Bank Account Details',
    name: 'bankAcc',
  },
  {
    label: 'For Period',
    name: 'period',
  },
  {
    label: 'Currency',
    name: 'currency',
  },
  {
    label: 'Particulars',
    name: 'particulars',
  }
]

const defaultValues = {
  date: moment().format('LL'),
  attention: '',
  remitting: '',
  address: '',
  merchantId: '',
  paymentRef: '',
  bankAcc: '',
  period: '',
  currency: '',
  particulars: ''
}

const STYLES = {
  info: {
    mt: 4
  },
  item: {
    display: "flex",
    alignItems: "center",
    height: "3rem"
  },
  nextBtn: {
    mt: 2,
    textAlign: "right"
  }
}
const Step1 = () => {

  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [data, setData] = useState(defaultValues);

  useEffect(() => {
    list_merchants();
  }, [])

  const list_merchants = async () => {
    const merchantArr = await API.Finance.Instructions.fetch_all_instructions();
    setMerchants(merchantArr);
  }

  const handleMerchantChange = (event) => {
    console.log('event.target.value: ', event.target.value)
    const { benName, companyName, benAddress, benBankName, benBankAddress, benAccountNo, currency } = event.target.value;
    setData({ 
      ...data, 
      attention: benName, 
      remitting: companyName, 
      address: benAddress,
      bankAcc: `${benBankName} ${benBankAddress} ${benAccountNo}`,
      currency 
    })
  }

  // const handleInputChange = () => {
  //   console.log('input')
  // }

  return <>
    <Title text="Merchant Information" />

    {
      merchants && <FormControl fullWidth>
        <InputLabel id="merchant-label">Select Merchant</InputLabel>
        <Select
          labelId="merchant-label"
          value={selectedMerchant}
          label="selectedMerchant"
          onChange={(ev) => handleMerchantChange(ev)}
          defaultValue=""
        >
          {
            merchants.map((item, index) => {
              const { companyName } = item;
              return (
                <MenuItem key={index} value={item}>{ companyName }</MenuItem>
              )
            })
          }    
        </Select>
      </FormControl>
    }

    <Box sx={ STYLES.info }>
      <Grid container spacing={4}>
      {
        infoList.map((item, index) => {
          const { label, name, type } = item;
          return <React.Fragment key={index}>
              <Grid item xs={3} sx={ STYLES.item }>
                <Typography>{ label }:</Typography>
              </Grid>
              {/* <Grid item xs={9} sx={ STYLES.item }><Typography><b>{data[name]}</b></Typography></Grid> */}
              <Grid item xs={9} sx={ STYLES.item }>
                <TextField 
                  size="small" 
                  variant="standard" 
                  fullWidth 
                  value={data[name]} 
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            </React.Fragment>
        })
      }
      </Grid>
    </Box>

    <Box sx={ STYLES.nextBtn }>
      <Button variant="contained">Next</Button>
    </Box>
      
  </>
}

export default Step1;