import { useState } from "react";
import { TraxxButton, TraxxContainer } from "components";

import CreateSettlement from "./CreateSettlement";
import ViewSettlement from "./ViewSettlement";
import Merchant from "./Merchant";

const STYLES = {
  "btn": {
    ml: 1
  }
}

const Settlement = () => {

  const [choice, setChoice] = useState('create');

  return <>
    <TraxxButton text="Create Settlement Report" onClick={() => setChoice('create')}></TraxxButton>
    <TraxxButton text="View Settlement Reports" style={ STYLES.btn } onClick={() => setChoice('view')}></TraxxButton>
    <TraxxButton text="Merchant" style={ STYLES.btn } onClick={() => setChoice('mid')}></TraxxButton>
  
    <TraxxContainer component={
      <>
        {
          choice === 'create' ? <CreateSettlement /> : choice === 'view' ? <ViewSettlement /> : <Merchant /> 
        }
      </>
    }/>

  </>
}

export default Settlement;