import { sessioned_client } from '../auth';
import { get_api_path } from '../api';

export const fetch_all_merchants = async (filter = {}) => {
  const url = get_api_path('application/list', 'traxxasia', filter);
  const response = await sessioned_client().get(url);
  return response.data.result.models;
}

export const fetch_all_agreements = async () => {
  const url = get_api_path('operations/agreement/list');
  const response = await sessioned_client().get(url);
  return response.data;
}

export const create_agreement = async (body) => {

  const { data, fee, signatory } = body;

  // console.log('fee: ', fee)
  const submitBody = {
    data: JSON.stringify(data),
    fee: JSON.stringify(fee),
    signatory
  }
  // console.log('submitBody: ', submitBody);
  // create agreement in database
  const url = get_api_path('operations/agreement/create');
  const response = await sessioned_client().post(url, submitBody);
  create_zoho(response.data.agreementId, body);
  return response.data;
}

export const create_zoho = async (id, data) => {
  // create document on zohosign
  const url = get_api_path('operations/createAgreement', 'microservices');
  const zohoResult = await sessioned_client().post(url, data);
  const { requestID } = zohoResult.data.result;

  // console.log('request id: ', requestID);
  // update instructions in database with zoho request id
  const url2 = get_api_path('operations/agreement/update/zohoRequestId');
  const updateResult = await sessioned_client().post(url2, { agreementId: id, requestID });

  return updateResult;
}