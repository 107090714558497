import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import { API } from 'services'
import CircularProgress from '@mui/material/CircularProgress';
import FileSaver from 'file-saver';
import { enqueueSnackbar } from 'notistack';

const API_HOST_2 = process.env.REACT_APP_API_MICROSERVICES;

const defaultInput = {
  date: moment().format('YYYY-MM-D'),
  amount: '',
  purpose: ''
}

const GenerateReportDialog = ({ open, handleClose, id }) => {

  const [input, setInput] = useState(defaultInput);
  const [loading, setLoading] = useState(false);

  const submitForm = async () => {
    setLoading(true);
    const data = {
      id,
      date: moment(input.date, 'YYYY-MM-D').format('D.MM.YYYY'),
      amount: input.amount,
      purpose: input.purpose
    }
    try {
      const result = await API.Finance.Instructions.get_report(data);
      if (result) {
        const url = API_HOST_2 + result;
        const companyName = result.slice(0, result.indexOf("-")) + ".xlsx";
        FileSaver.saveAs(url, companyName);
        setLoading(false);
        handleClose();
        enqueueSnackbar("Form generated!", {
          variant: 'success',
          autoHideDuration: 3000
        });
      } else {
        enqueueSnackbar("Error generating form!", {
          variant: 'error',
          autoHideDuration: 3000
        });
      }
    } catch (err) {
      enqueueSnackbar("Server error.", {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  }

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setInput({ ...input, [name]: value })
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Remittance Form</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the information below for form generation:
          </DialogContentText>
          <TextField
            value={input.date}
            margin="normal"
            name="date"
            label="Date"
            type="date"
            fullWidth
            variant="filled"
            onChange={(ev) => handleChange(ev)}
          />
          <TextField
            autoFocus
            margin="normal"
            name="amount"
            label="Remitting Amount"
            fullWidth
            variant="filled"
            value={input.amount}
            onChange={(ev) => handleChange(ev)}
            placeholder='EUR 20,000.00'
          />
          <TextField
            margin="normal"
            name="purpose"
            label="Purpose of Remittance"
            fullWidth
            variant="filled"
            multiline
            rows={2}
            value={input.purpose}
            onChange={(ev) => handleChange(ev)}
          />
        </DialogContent>
        <DialogActions sx={{ mr: 2, mb: 2 }}>
        {
          <>
            { !loading && <Button onClick={handleClose}>Cancel</Button> }
            <Button onClick={submitForm}>{ loading ? <CircularProgress size="1.5rem" /> : "Submit" }</Button>
          </>
        }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default GenerateReportDialog;