const Q1P1a = [
  {
    text: "Routine review of existing merchant",
    type: "check"
  },
  {
    text: "Review as a result of trigger event – Please indicate the trigger event:",
    type: "notes"
  },
  {
    text: "Please provide details regarding the nature of services required by client:",
    type: "notes"
  }
];

const Q1P1b = [
  {
    text: "Referred merchant – Please indicate source of referral below:",
    type: "notes"
  },
  {
    text: "Please provide details regarding the nature of services required by client:",
    type: "notes"
  }
];

const Q1P2 = [
  {
    text: "For new merchant, up-to-date and relevant identification information, including information on shareholders and directors, has been obtained as per Main Merchant App Form.",
    type: "check"
  },
  {
    text: "For existing merchant, information previously obtained for the purposes of client identification can be re-verified.",
    type: "check"
  },
  {
    text: "No country / cross border restrictions and regulations on business activities / products / services offered by merchant (ie. Merchant operates in a sanctioned country)",
    type: "check"
  },
  {
    text: "Merchant and connected persons (beneficial owners, directors, shareholders etc) DO NOT MATCH with terrorist suspects and/or special interest entities in reliable, independent screening databases and under the list of names in the applicable Schedules of the Terrorism (Suppression of Financing) Act and United Nations sanction list.",
    type: "check"
  },
  {
    text: "The ownership of the merchant and requested information from the merchant can be verified (i.e. comparing information based on documents).",
    type: "check"
  },
  {
    text: "The merchant is cooperative and willing to furnish the required information required to facilitate application and KYC process.",
    type: "check"
  },
  {
    text: "There is no anonymity by the merchant / beneficial owners / directors in the initial establishment of business contact and relationship.",
    type: "check"
  },
];

export {
  Q1P1a,
  Q1P1b,
  Q1P2
};