import { sessioned_client } from '../auth';
import { get_api_path } from '../api';


import axios from "axios";

// export async function create_risk() {
//   const url = get_api_path('risk/create', filter);
//   const { access } = store.getState().profile;
//   const response = await sessioned_client({ token: access.token, access }).post(url);
//   return response.data.result;
// }

export async function fetch_all_risk() {
  const url = get_api_path('compliance/risk/list');
  const response = await sessioned_client().get(url);
  return response.data;
};

export async function fetch_country_risk() {
  const url = get_api_path('compliance/risk/fatf/list');
  const response = await sessioned_client().get(url);
  return response.data;
};

export async function create_risk(data) {
  // create risk assessment in database
  const url = get_api_path('compliance/risk/create');
  const response = await sessioned_client().post(url, data);
  create_zoho(response.data.id, data);
  return response.data;
};

export async function create_zoho(id, data) {
  // create document on zohosign
  const url = get_api_path('compliance/createRisk', 'microservices');
  const zohoResult = await sessioned_client().post(url, {id, data});
  const { requestID } = zohoResult.data.result;

  // update risk assessment in database with zoho request id
  // const url2 = get_api_path('compliance/risk/update/zohoRequestId');
  // const updateResult = await sessioned_client().post(url2, { id, requestID });

  // return updateResult;
}

export async function update_fatf(data) {
  const url = get_api_path('compliance/risk/update/fatf');
  const response = await sessioned_client().post(url, data);
  return response.data;
}

export async function create_ewra(data) {
  const url = get_api_path('compliance/ewra/create');
  const response = await sessioned_client().post(url, data);
  return response.data;
}

export async function fetch_ewra() {
  const url = get_api_path('compliance/ewra/list');
  const response = await sessioned_client().get(url);
  return response.data;
}