import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from './routes';
import theme from 'theme';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from "notistack";

const router = createBrowserRouter(routes);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
};

export default App;