import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import principalDetailsFields from 'utils/fiserv/principalDetails';
import { TraxxField } from 'components/TraxxField';
import STYLES from 'utils/fiserv/styles';

const defaultData = {
  position: "",
  title: "",
  firstName: "",
  lastName: "",
  middleName: "",
  dateOfBirth: "",
  nationality: "",
  addressLine: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
  phone1: "",
  phone2: "",
  contactName: "",
  kycCategory: "POI",
  identityType: "PASSPORT",
  identityValue: ""
}

const Step3 = ({ merchant, handleNext }) => {

  const [ principalDetails, setPrincipalDetails ] = useState([defaultData]);

  useEffect(() => {
    if (!merchant) return;
    if (merchant.principalDetails) {
      setPrincipalDetails(JSON.parse(merchant.principalDetails))
    } else if (merchant.selectedMerchant) {
      const info = JSON.parse(merchant.selectedMerchant);
      setPrincipalDetails([{
        ...defaultData,
        firstName: info.firstName,
        lastName: info.lastName,
        addressLine: info.address,
        country: info.country,
        city: info.country === "Singapore" ? "Singapore" : "",
        state: info.country === "Singapore" ? "Singapore" : "",
        phone1: info.contactNum,
        contactName: info.firstName + " " + info.lastName,
      }])
    }
  }, [merchant])

  const removePrincipal = (indexToRemove) => {
    setPrincipalDetails(prev => 
      prev.filter((item, index) => index !== indexToRemove)
    );
  }

  const addPrincipal = () => {
    setPrincipalDetails(prev => [
      ...prev,
      defaultData
    ]);
  }

  const handleChange = (ev, index) => {
    const { name, value } = ev.target;
    setPrincipalDetails(prev => {
      const updatedData = [...prev];
      updatedData[index] = { ...updatedData[index], [name]: value }
      return updatedData;
    })
  }

  console.log('principalDetails: ', principalDetails)
  
  return <>

    {
      principalDetails.map((principal, index) => (
        <Box key={index} sx={ STYLES.box }>
          <Box sx={ STYLES.header }>
            <Typography>Principal Owner {index+1}</Typography>
            { 
              principalDetails.length !== 1 && 
              <IconButton aria-label="delete" onClick={() => removePrincipal(index)}><CancelIcon /></IconButton>
            }
          </Box>
          <Grid container spacing={2} style={ STYLES.innerBox }>
            <TraxxField
              arr={principalDetailsFields}
              data={principal}
              handleChange={(ev) => handleChange(ev, index)}
            />
          </Grid>
        </Box>
      ))
    }

    { 
      principalDetails.length < 3 && 
      <Button sx={{ mb: 1 }} variant="outlined" fullWidth onClick={addPrincipal}>Add Principal Owner</Button>
    }

    <Grid item xs={12} sx={{ mt: 3 }}>
      <Button variant="contained" fullWidth onClick={() => handleNext(principalDetails, "principalDetails")}>
        Save
      </Button>
    </Grid>

  </>
}

export default Step3;