import { sessioned_client } from '../auth';
import { get_api_path } from '../api';

export const fetch_all_merchants = async (filter = { nolimit: true }) => {
  const url = get_api_path('application/list', 'traxxasia', filter);
  const response = await sessioned_client().get(url);
  return response.data.result.models;
}

export const create_urn = async (data) => {
  const url = get_api_path('fiserv/createURN', 'microservices');
  const response = await sessioned_client().post(url, data);
  return response.data;
}

export const save_data = async (data) => {
  const url = get_api_path('others/fiserv/update');
  const response = await sessioned_client().post(url, data);
  return response.data;
}

export const fetch_by_appURN = async (appURN) => {
  const url = get_api_path('others/fiserv/searchByAppURN');
  const response = await sessioned_client().post(url, appURN);
  return response.data;
}

export const submit_new_mid = async (data) => {
  const url = get_api_path('fiserv/submit', 'microservices');
  const response = await sessioned_client().post(url, data);
  return response.data;
}

export const enquire_status = async (arr) => {
  const url = get_api_path('fiserv/enquire', 'microservices');
  const response = await sessioned_client().post(url, arr);
  return response.data;
}

// ******************************************************************************************

export const resubmit_mid = async (data) => {
  const url = get_api_path('others/fiserv/resubmit', 'microservices');
  const response = await sessioned_client().post(url, data);
  return response.data;
}

export const resubmit_mid_2 = async (data) => {
  const url = get_api_path('others/fiserv/resubmit2', 'microservices');
  const response = await sessioned_client().post(url, data);
  return response.data;
}

export const fetch_applications = async () => {
  const url = get_api_path('others/fiserv/list');
  const response = await sessioned_client().get(url);
  return response.data;
}

export const add_mid = async (data) => {
  const url = get_api_path('others/fiserv/addmid', 'microservices');
  const response = await sessioned_client().post(url, data);
  return response.data;
}