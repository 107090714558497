import { Typography } from "@mui/material";

const Title = ({ text, color }) => {
  return (
    <Typography 
      variant="h5"
      sx={{
        color: color ? color : "black",
        mb: 2
      }}
    >
      { text }
    </Typography>
  )
}

export default Title;