import { useState, useEffect } from 'react';
import { Title, TraxxContainer, HeaderComponent } from "components";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer'
import Chip from '@mui/material/Chip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { API } from 'services';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';

const header = [
  {
    id: 'index',
    label: 'No.'
  },
  {
    id: 'created_on',
    label: 'Date of Creation'
  },
  {
    id: 'entity',
    label: 'Company Name'
  },
  {
    id: 'status',
    label: 'Sign Status'
  },
  {
    id: 'document',
    label: 'Document'
  }
]

const BodyComponent = ({ agreementList }) => {

  const reversedAgreementList = agreementList.reverse();

  return (
      <TableBody>
        { reversedAgreementList.map((item, index) => {
            const { agreementId, createdOn, data, requestID, zohoStatus } = item;
            const formattedDate = moment(createdOn).format('lll');

            const company = JSON.parse(data);

            console.log('company: ', company )
            return 
              <TableRow key={agreementId}>
              
                <TableCell>{ index + 1 }</TableCell>
                <TableCell>{ formattedDate }</TableCell>
                <TableCell>{ company?.enquiry_info?.companyName }</TableCell>
                <TableCell>{ zohoStatus === "completed" ? <Chip label="Complete" color="success" /> : <Chip label="In Progress" color="warning" variant="outlined" /> }</TableCell>
                <TableCell>{ requestID ? <IconButton onClick={() => API.Misc.fetch_zoho_doc(requestID)}><PictureAsPdfIcon /></IconButton> : <IconButton disabled><PictureAsPdfIcon /></IconButton> }</TableCell>
              
            </TableRow>
            
          })
        }
      </TableBody>
  )
};

const ViewAgreement = () => {

  const [agreementList, setAgreementList] = useState([]);

  useEffect(() => {
    getAgreements();
  }, []);

  const getAgreements = async () => {
    const result = await API.Operations.Agreement.fetch_all_agreements();
    if (result) {
      setAgreementList(result);
    }
  }

  console.log('agreementlist: ', agreementList);

  return <>
    <Title text="Merchant Agreement List" />

    <TraxxContainer component={
      <TableContainer>
      <Table>
        <HeaderComponent header={header} />
        <BodyComponent agreementList={agreementList} />
      </Table>
      </TableContainer>
    }/>
  </>
}

export default ViewAgreement;