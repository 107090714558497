import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'
import { countries } from "utils";


const CompanyInfo = (props) => {

  const { 
    data,
    merchants, 
    selectedMerchant, 
    handleChange, 
    handleInputChange,
    handleVariableChange,
    handleCountryChange
  } = props;

  return (

      <Box component="form" noValidate sx={{ px: 2, py: 1 }}>

        <Typography sx={{ mb: 3 }}>Company Information</Typography>

        { merchants && <Autocomplete
            // disablePortal
            freeSolo
            value={selectedMerchant}
            options={merchants.map(item => JSON.parse(item.enquiry_info).companyName)}
            onChange={(event, newValue) => handleChange(event, newValue)}
            onInputChange={(event, newValue) => handleInputChange(event, newValue)}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Select Merchant" />}
          /> 
        }

        <TextField
          autoComplete='new-password'
          margin="normal"
          fullWidth
          id="address"
          label="Company Address"
          name="address"
          value={ data.enquiry_info?.address || '' }
          onChange={(ev) => handleVariableChange(ev)}
        /> 

        <Grid container spacing={2}>
          <Grid item xs={6}>  
            <TextField 
              autoComplete='new-password'
              margin="normal"
              fullWidth
              id="regNum"
              label="Company Registration No."
              name="regNum"
              value={ data.enquiry_info?.regNum || '' }
              onChange={(ev) => handleVariableChange(ev)}
            />
          </Grid>    
          <Grid item xs={6}>  
            <TextField 
              autoComplete='new-password'
              margin="normal"
              fullWidth
              id="coreBusiness"
              label="Company is in business of providing..."
              name="coreBusiness"
              value={ data.enquiry_info?.coreBusiness || '' }
              onChange={(ev) => handleVariableChange(ev)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField 
              autoComplete='new-password'
              margin="normal"
              fullWidth
              id="companyRepName"
              label="Company Representative Name"
              name="companyRepName"
              value={ data.enquiry_info?.companyRepName || '' }
              onChange={(ev) => handleVariableChange(ev)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField 
              autoComplete='new-password'
              margin="normal"
              fullWidth
              id="companyRepEmail"
              label="Company Representative Email"
              name="companyRepEmail"
              value={ data.enquiry_info?.companyRepEmail || '' }
              onChange={(ev) => handleVariableChange(ev)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoComplete='new-password' 
              margin="normal"
              fullWidth
              id="signingWitnessName"
              label="Signing Witness Name"
              name="signingWitnessName"
              value={ data.enquiry_info?.signingWitnessName || '' }
              onChange={(ev) => handleVariableChange(ev)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField 
              autoComplete='new-password' 
              margin="normal"
              fullWidth
              id="signingWitnessEmail"
              label="Signing Witness Email"
              name="signingWitnessEmail"
              value={ data.enquiry_info?.signingWitnessEmail || '' }
              onChange={(ev) => handleVariableChange(ev)}
            />
          </Grid>
        </Grid>

        <Autocomplete
          id="country-select-demo"
          sx={{ mt: 2 }}
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} ({option.code})
            </Box>
          )}
          onChange={(ev, val) => handleCountryChange(ev, val)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a country"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />

        <FormGroup sx={{ mt: 1 }}>
          <FormControlLabel 
            control={<Checkbox 
              name="checked"
              checked={data?.checked || false}
              onChange={(ev) => handleVariableChange(ev)}
              inputProps={{ 'aria-label': 'controlled' }}
            />} 
            label="Foreign Entity" />
        </FormGroup>

      </Box>
  )
};

export default CompanyInfo;