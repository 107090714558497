import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "utils";
import Grid from "@mui/material/Grid";

const DirectorUBO = ({ type, handleAdd, data, handleChangePeopleName, handleChangePeopleCountry }) => {
  return <Grid container spacing={2}>
  { data.map((item, index) => {
    return <React.Fragment key={index}>
      <Grid item xs={6}>
        <TextField fullWidth onChange={(ev) => handleChangePeopleName(ev, type, index)}/>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          autoComplete={false}
          id="country-select-demo"
          name="country"
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option?.label}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option?.label} ({option.code})
            </Box>
          )}
          onChange={(ev, val) => handleChangePeopleCountry(ev, val, type, index)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              inputProps={{
                ...params.inputProps
              }}
            />
          )}
        />
      </Grid>
    </React.Fragment>
  })
    
    }
  <Grid item xs={12}>
    <Button variant="outlined" onClick={handleAdd} fullWidth>Add {type}</Button>
  </Grid>
</Grid>
}

export default DirectorUBO;