import { useState } from "react";
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Title } from 'components';
import { Q1P1a, Q1P1b, Q1P2 } from '../texts'
import { Divider } from '@mui/material';
import QuestionGrid from "./QuestionGrid";

const Section1 = ({ defaultData, data, setData, handleChange }) => {

  const theme = useTheme();

  const [ showNew, setShowNew ] = useState(false);

  const handleShowNew = (bool) => {
    if (bool) setData({ ...data, Q1P1a: defaultData.Q1P1a }); //if new
    else setData({ ...data, Q1P1b: defaultData.Q1P1b }) //if existing
    setShowNew(bool);
  }

  return <>
    <Box display="flex" justifyContent="center" sx={{ pb: 1 }}>
      <Title text="Merchant AML / CFT Risk Assessment - Level 1" color={ theme.palette.primary.main } />
    </Box>

    <Divider />

    <Typography sx={{ mt: 2 , textAlign: "center"}}>1.1 Purpose of Merchant Evaluation</Typography>
    
    <Box sx={{ m: 3, display: "flex", justifyContent: "center" }}>
    <Box sx={{ width: "70%" }}>
      <Grid container spacing={2}>
        
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <RadioGroup defaultValue="exist">
            <Box>
              <FormControlLabel value="exist" control={<Radio size="small" onClick={ () => handleShowNew(false) } />} label="Existing merchant" />
              <FormControlLabel value="new" control={<Radio size="small" onClick={ () => handleShowNew(true) } />} label="New merchant" />
            </Box>
          </RadioGroup>
        </Grid>

        </Grid>

        { !showNew ?
          Q1P1a.map((question, index) => {
            return <Grid container spacing={2} key={index}>
              <QuestionGrid data={data} question={question} qNo="Q1P1a" index={index} handleChange={handleChange} />
            </Grid>
          })
          :
          Q1P1b.map((question, index) => {
            return <Grid container spacing={2} key={index}>
              <QuestionGrid data={data} question={question} qNo="Q1P1b" index={index} handleChange={handleChange} /> 
            </Grid>
          })
        }

    </Box>
    </Box>

    <Typography sx={{ mt: 1 , textAlign: "center"}}>1.2 Establishing or continuing business account with the Merchant is NOT allowed if the answer to any of the below questions is "NO"</Typography>

    <Box sx={{ m: 5, display: "flex", justifyContent: "center" }}>
    <Box sx={{ width: "70%" }}>
      <Grid container spacing={2}>

        { Q1P2.map((question, index) => {
            return <Grid container spacing={2} key={index}>
              <QuestionGrid data={data} question={question} qNo="Q1P2" index={index} handleChange={handleChange} /> 
            </Grid>
        })}

      </Grid>

    </Box>
    </Box>   

  </>
}

export default Section1;