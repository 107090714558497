const Q2P1 = [
  {
    text: "Reliable information on the Merchant, beneficial owners, directors is publicly or easily available and matches the customer application business profile.",
    type: "risk"
  },
  {
    text: "Credible and stable financial information on the Merchant is available from the bank letters / statements furnished by the Merchant during application.",
    type: "risk"
  },
  {
    text: "Merchant or beneficial owner or directors matches with persons in the MAS control list.",
    type: "risk"
  },
  {
    text: "Merchant or beneficial owners or connected party has adverse news (eg. Disputes, bankruptcy, offences etc) based on searches from independent search engines and credibility / reference checks.",
    type: "risk"
  },
  {
    text: "Merchant is involved in High-Risk Industry or business activities / products involving conflict of cross-border jurisdictions and infringements.",
    type: "risk"
  },
  {
    text: "Merchant is: a shell company, has complex shareholding structure (e.g., involving 3 layers or more of ownership structure, different jurisdictions, trusts); OR operates without an obvious commercial purpose.",
    type: "risk"
  },
  {
    text: "Merchant is a charitable or non-profit organization that is NOT registered in Singapore (charities.gov.sg/charity/index.do).",
    type: "risk"
  },
  {
    text: "Company is familiar with the merchant’s AML/CFT controls due to previous similar business dealings with the merchant or 3rd party referrals and recommendations.",
    type: "risk"
  },
  {
    text: "Merchant’s business operations and processing history are verifiable - Existing business vs new start-up",
    type: "risk"
  }
]

const Q2P2 = [
  {
    text: "Merchant business activities and operations are in a high-risk jurisdictions country.",
    type: "risk"
  },
  {
    text: "Merchant beneficial owners or natural persons are connected to high risk jurisdictions in any of the above listed aspects.",
    type: "risk"
  },
  {
    text: "The merchant does not have equivalent AML/CFT measures that are able to mitigate the risks of being from a high risk jurisdictions country.",
    type: "risk"
  }
]

const Q2P3 = [
  {
    text: "Merchant is involved in High-Risk Industry or business activities / products involving conflict of cross-border jurisdictions and infringements.",
    type: "risk"
  },
  {
    text: "Merchant product mix history and pattern of sale changes frequently.",
    type: "risk"
  },
  {
    text: "Product and services offered by merchant are subject to cross-border regulatory infringement checks.",
    type: "risk"
  },
  {
    text: "General and common trading products and services with small transaction values.",
    type: "risk"
  },
  {
    text: "Business transaction pattern and history is regular and reliable. (eg. Chargeback history within threshold limits set by credit card associations, refund history, payment processor history, etc)",
    type: "risk"
  },
  {
    text: "Merchant has existing anti-fraud systems for their transaction monitoring.",
    type: "risk"
  },
  {
    text: "Merchant business portal and operation mode is clear. Allows ID account log-in to check products and transaction flow.",
    type: "risk"
  }
]

const Q2P4 = [
  {
    text: "Merchant relationship is established through non-face-to-face approach (eg. Online enquiry, telephone, postal modes)",
    type: "risk"
  },
  {
    text: "Merchant relationship is established through referral or recommendation.",
    type: "risk"
  }
]

export { 
  Q2P1, 
  Q2P2, 
  Q2P3, 
  Q2P4
}