import { useState, useEffect } from "react";
import { Title, TraxxContainer, TraxxButton } from "components";
import { Q1, Q2, Q3, Q4 } from "./texts";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
import { API } from "services";
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';

const STYLES = {
  question: {
    pt: 4,
    pb: 3,
    px: 2,
    borderBottom: '1px solid lightgray'
  },
  col1: {
    width: "55%"
  },
  col2: {
    width: "5%"
  },
  col3: {
    width: "8%"
  },
  col4: {
    width: "32%"
  },
  submitBtn: {
    mt: 2
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    height: "2rem"
  }
}

const headers = [
  "Questions",
  "Yes/No",
  "Risk Score",
  "Comments"
];

const EwraQuestions = ({ no, text, bullet , text2, bullet2 }) => {
  return <>
    {
      text.map((q, index) => {
        return <Typography key={index} variant="subtitle2">{ q }</Typography>
      })
    }
    {
      bullet && <ul>
        { bullet.map((points, index) => {
            return <li key={index}>{ points }</li>
          })
        }
      </ul>
    }
    {
      text2 && text2.map((q, index) => {
        return <Typography key={index} variant="subtitle2">{ q }</Typography>
      })
    }
    {
      bullet2 && <ul>
        { bullet2.map((points, index) => {
            return <li key={index}>{ points }</li>
          })
        }
      </ul>
    }
  </>
}

const EwraSection = ({ arr, text }) => {

  const [error, setError] = useState({})

  const checkValue = (ev, no) => {
    const value = ev.target.value;
    if (value > 4 || value < 0) setError({ ...error, [no]: true })
    else setError({ ...error, [no]: false })
  }

  const [ewra, setEwra] = useState();

  useEffect(() => {
    getEwra();
  }, [])

  const getEwra = async () => {
    const result = await API.Compliance.Risk.fetch_ewra();
    if (result) {
      const { id, createdOn, choice, score, comment } = result[result.length-1];
      const data = {
        id,
        createdOn,
        choice: JSON.parse(choice),
        score: JSON.parse(score),
        comment: JSON.parse(comment)
      }
      setEwra(data);
    }
  }

  const search = useLocation().search;
	const data = new URLSearchParams(search).get("data");
  const avgs = data.split("-");
  // console.log(avgs[0])

  return <TraxxContainer component={
    <>
      <Title text={text} />

      <Table sx={ STYLES.table }>
        <TableHead>
          <TableRow>
            {
              headers.map((item, index) => {
                return <TableCell key={index}><b>{ item }</b></TableCell>
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
        {
          arr.map((question, index) => {
            const { no, text, bullet, text2, bullet2 } = question;
            return <TableRow key={index} sx={ STYLES.question }>
              <TableCell sx={ STYLES.col1 }>
                <EwraQuestions no={no} text={text} bullet={bullet} text2={text2} bullet2={bullet2} />
              </TableCell>

              <TableCell sx={ STYLES.col2 }>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="y"
                  name={ `choice-${no}` }
                >
                  <FormControlLabel value="y" control={<Radio />} label="Yes" />
                  <FormControlLabel value="n" control={<Radio />} label="No" />
                </RadioGroup>
              </TableCell>

               <TableCell sx={ STYLES.col3 }>
                {no !== "0201" && no !== "0202" && no !== "0203" && ewra && <TextField
                   
                  type="number" 
                  
                  defaultValue={no === "0201" ? ewra.score["0201"] : '' || no === "0202" ? ewra.score["0202"] : '' || no === "0203" ? ewra.score["0203"] : ''}
                  InputProps={{ inputProps: {step: 'any'} }}
                  name={ `score-${no}` }
                  onBlur={(ev) => checkValue(ev, no)}
                  error={error[no]}
                  helperText={ error[no] ? "incorrect entry" : '' }
                />}

                {(no === "0201" || no === "0202" || no === "0203") && ewra && <TextField
                   
                   type="number" 
                   defaultValue={no === "0201" ? avgs[0] : '' || no === "0202" ? avgs[1] : '' || no === "0203" ? avgs[2] : ''}
                   InputProps={{ readOnly: true, inputProps: {step: 'any'} }}
                   name={ `score-${no}`}   
                   onBlur={(ev) => checkValue(ev, no)}
                   error={error[no]} 
                   helperText={ error[no] ? "incorrect entry" : '' }
                 />}

              </TableCell>

              <TableCell sx={ STYLES.col4 }>
                <TextField 
                  name={ `comment-${no}` }
                  multiline 
                  fullWidth 
                />
              </TableCell>
            </TableRow>
          })
        }
        </TableBody>
      </Table>
      
      </>
    } />
}

const Ewra = () => {

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('event: ', event.target[`score-0101`].value)
    const parts = [5, 5, 7, 2]; //no of questions for each part

    let choice = {};
    let score = {};
    let comment = {}
    parts.forEach((item, index) => {
      for (let i=0; i<item; i++) {
        const numbering = String(index+1).padStart(2, '0') + String(i+1).padStart(2, '0');
        choice[numbering] = event.target[`choice-${numbering}`].value;
        score[numbering] = event.target[`score-${numbering}`].value;
        comment[numbering] = event.target[`comment-${numbering}`].value;
      }
    })

    const data = { 
      choice: JSON.stringify(choice), 
      score: JSON.stringify(score), 
      comment: JSON.stringify(comment) 
    }
    console.log('data: ', data)

    try {
      const result = await API.Compliance.Risk.create_ewra(data);
      if (result) {
        enqueueSnackbar("Form successfully created", {
          variant: 'success',
          autoHideDuration: 3000
        })
        navigate("/compliance/risk-assessment")
      } else {
        enqueueSnackbar("Error creating form", {
          variant: 'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
        enqueueSnackbar(err.message, {
          variant: 'error',
          autoHideDuration: 3000
        })
    }
  }

  return <>
    <form onSubmit={handleSubmit}>
    <Box sx={ STYLES.box }>
      <Title text="EWRA Questionnaire" />
      <TraxxButton text="Submit Form" type="submit" />
    </Box>
    <EwraSection 
      arr={Q1}
      text="1. ML/TF Risk Internal Governance Framework Assessment"
    />

    <EwraSection 
      arr={Q2}
      text="2. Enterprise-wide ML/TF Risk Factors Assessment"
    />

    <EwraSection 
      arr={Q3}
      text="3. Enterprise-wide ML/TF Risk Mitigation Assessment"
    />

    <EwraSection 
      arr={Q4}
      text="4. Enterprise-wide AML/CFT Training and Coordination Assessment"
    /> 
    </form>
  </>
}

export default Ewra;