import axios from 'axios';
import moment from 'moment';

// export function sessioned_client({ token, access }) {
//   let accessToken = token;
//   if (!token) {
//     accessToken = access.token;
//   }

//   if (!accessToken) throw new Error('no access token');
//   if (access && moment().isAfter(moment(access.expires_at * 1000))) throw new Error('token expired');

//   const defaultOptions = {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//     },
//   };

//   return {
//     get: (url, options = {}) => axios.get(url, { ...defaultOptions, ...options }),
//     post: (url, data, options = {}) => axios.post(url, data, { ...defaultOptions, ...options }),
//     put: (url, data, options = {}) => axios.put(url, data, { ...defaultOptions, ...options }),
//     delete: (url, options = {}) => axios.delete(url, { ...defaultOptions, ...options }),
//   };
// }


export function sessioned_client() {

  // const token = localStorage.getItem("d3:userToken")
  // if (!token) throw new Error('no access token')

  const defaultOptions = {
    headers: {
      authorization: `Bearer ${btoa(encodeURIComponent(new Date().getTime()))}`
    }
  }

  return {
    get: (url, options = { ...defaultOptions }) => axios.get(url, options),
    post: (url, data, options = {}) => axios.post(url, data, options),
    put: (url, data, options = {}) => axios.put(url),
    delete: (url, options = {}) => axios.delete(url),
  };
}