import { Grid, Box, Typography, ListItemButton, ListItemIcon, ListItemText, Link as LinkBase } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotesIcon from '@mui/icons-material/Notes';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { Link } from 'react-router-dom'
import moment from "moment";
import { useState, useEffect } from "react";

const GridBox = ({ color, risk, riskList, fullRisk, avgs }) => {

  const theme = useTheme();

  const STYLES = {
    "container": {
      width: '100%',
      height: '100%',
      minHeight: '10rem',
      boxShadow: '0px 0px 10px lightgray',
      borderRadius: '8px',
      padding: '1rem',
      backgroundColor: color === 'red' 
      ? theme.palette.red.main 
      : color === 'yellow' 
      ? theme.palette.yellow.main 
      : color === 'green' 
      ? theme.palette.green.main
      : 'white',
      color: color === 'white' ? 'black' : 'white', 
    },
    "addIcon": {
      width: '30%',
      height: '30%'
    }
  }

  const [recents, setRecents] = useState([]);

  // useEffect(()=> {
  //   // setTimeout(() => {
  //   //   console.log("fullList", fullRisk);
  //   // }, 5000);
  // },[])

  // console.log("full list top useeffect", fullRisk)

  

  useEffect(()=> { 
    if (fullRisk) { 
    getFirstSum();
    getSecondSum();
    getThirdSum(); 
    } 
  },[])

  useEffect(() => {
    
    // getFirstSum();
    // getSecondSum();
    // getThirdSum();
    if (riskList) getRecentList();
  }, [riskList])

  const testShit = () => {
    // if (fullRisk.high) {
    //   console.log("yes")
    // }
    // console.log("button list", fullRisk);
    // console.log("button list", fullRisk.low);
    
  }

  const getRecentList = () => {
    // console.log('risklist: ', riskList);
    setRecents(riskList.slice(-3));
  }

  const defaultSum = {
    low: { black: 0, gray: 0, others: 0 },
    medL: { black: 0, gray: 0, others: 0 },
    medH: { black: 0, gray: 0, others: 0 },
    high: { black: 0, gray: 0, others: 0 }
  }
  
  const defaultTotal = { low: 0, medL: 0, medH: 0, high: 0 }
  
  const defaultScore = { low: 0, medL: 0, medH: 0, high: 0 }

  const [summation, setSummation] = useState(defaultSum);
  const [totals, setTotals] = useState(defaultTotal);
  const [totalScore, setTotalScore] = useState(defaultScore);
  const [firstAverage, setFirstAverage] = useState(0);
  const [secondAverage, setSecondAverage] = useState(0);
  const [thirdAverage, setThirdAverage] = useState(0);

  useEffect(()=> {
    
    // if (thirdAverage) {
    //   const concat = firstAverage + secondAverage + thirdAverage
    //   console.log(concat)
    // }
    if (fullRisk) { 
      getFirstSum();
      getSecondSum();
      getThirdSum(); 
    } 

  },[thirdAverage])

  const calculateClient = (arr) => {
    let partner = 0; 
    let merchant = 0;

    arr.forEach(item => {
      const { clientType } = item;
      if (clientType === "p") partner++;
      if (clientType === "m") merchant++;
    })

    return { partner, merchant }
  }

  const calculate = (arr, sum) => {
    arr.forEach((item, index) => {
      const { countryType } = item;
      if (countryType === "gray") sum.gray++
      else if (countryType === "black") sum.black++
      else sum.others++
    })
    return sum;
  }

  const calculatePeople = (arr, sum) => {
    arr.forEach((item, index) => {
      const { people } = item;
      // console.log(people);
      // if (people?.includes("gray")) sum.gray++
      // else if (people?.includes("black")) sum.black++
      // else sum.others++
      sum.gray += (people?.match(/"gray"/g)||[]).length;
      sum.black += (people?.match(/"black"/g)||[]).length;
      const peopleStrung = people?.toString()
      
      sum.others += (peopleStrung?.match(/null/g)||[]).length;
    })
    return sum;
  }

  const getFirstSum = () => {

    const low = calculateClient(fullRisk.low);
    const medL = calculateClient(fullRisk.medL);
    const medH = calculateClient(fullRisk.medH);
    const high = calculateClient(fullRisk.high);
    
    setSummation({ low, medL, medH, high });

    const low_total = low.partner + low.merchant;
    const medL_total = medL.partner + medL.merchant;
    const medH_total = medH.partner + medH.merchant;
    const high_total = high.partner + high.merchant;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    const low_score = low_total*1;
    const medL_score = medL_total*2;
    const medH_score = medH_total*3;
    const high_score = high_total*4;

    setTotalScore({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

   
    const totalSum = low_total + medL_total + medH_total + high_total;
    let average = 0;
    if (totalSum !== 0) average = ((low_score + medL_score + medH_score + high_score)/totalSum).toFixed(2)
    
    setFirstAverage(average);
    return(average);
  }

  const getSecondSum = () => {

    let sum = {
      low: { black: 0, gray: 0, others: 0 },
      medL: { black: 0, gray: 0, others: 0 },
      medH: { black: 0, gray: 0, others: 0 },
      high: { black: 0, gray: 0, others: 0 }
    }

    const low = calculate(fullRisk.low, sum.low);
    const medL = calculate(fullRisk.medL, sum.medL);
    const medH = calculate(fullRisk.medH, sum.medH);
    const high = calculate(fullRisk.high, sum.high);
    
    setSummation({ low, medL, medH, high });

    
    const low_total = low.black + low.gray + low.others;
    const medL_total = medL.black + medL.gray + medL.others;
    const medH_total = medH.black + medH.gray + medH.others;
    const high_total = high.black + high.gray + high.others;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    setTotalScore({
      low: low_total*1,
      medL: medL_total*2,
      medH: medH_total*3,
      high: high_total*4,
    });

    const totalScore = low_total*1 + medL_total*2 + medH_total*3 + high_total*4;
    const totalSum = low_total + medL_total + medH_total + high_total;

    let averageSum = 0
    if (totalSum !== 0) {
      averageSum = ((totalScore)/(totalSum)).toFixed(2);
    }
    
    setSecondAverage(averageSum);
  }

  const getThirdSum = () => {

    let sum = {
      low: { black: 0, gray: 0, others: 0 },
      medL: { black: 0, gray: 0, others: 0 },
      medH: { black: 0, gray: 0, others: 0 },
      high: { black: 0, gray: 0, others: 0 }
    }

    const low = calculatePeople(fullRisk.low, sum.low);
    const medL = calculatePeople(fullRisk.medL, sum.medL);
    const medH = calculatePeople(fullRisk.medH, sum.medH);
    const high = calculatePeople(fullRisk.high, sum.high);
    
    setSummation({ low, medL, medH, high });

    
    const low_total = low.black + low.gray + low.others;
    const medL_total = medL.black + medL.gray + medL.others;
    const medH_total = medH.black + medH.gray + medH.others;
    const high_total = high.black + high.gray + high.others;

    setTotals({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    setTotalScore({
      low: low_total*1,
      medL: medL_total*2,
      medH: medH_total*3,
      high: high_total*4,
    });

    const totalScore = low_total*1 + medL_total*2 + medH_total*3 + high_total*4;
    const totalSum = low_total + medL_total + medH_total + high_total;

    let averageSum = 0
    if (totalSum !== 0) {
      averageSum = ((totalScore)/(totalSum)).toFixed(2);
    }
    
    setThirdAverage(averageSum);
  }
  const [concat, setConcat] = useState();

  useEffect(()=> {
    if (fullRisk) { 
    setConcat(getFirstSum() + "-" + secondAverage + "-" + thirdAverage);
    
    }
  },[firstAverage, secondAverage, thirdAverage])
  useEffect(()=> {
    
  },[])
  
  // console.log('recents: ', recents);
  
  if (risk) {
    return (
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        <Box sx={ STYLES.container }>
          <Typography display="inline" variant="h4"><b>{ riskList.length }</b></Typography>&nbsp;&nbsp;
          <Typography display="inline" variant="h6">{ risk.toUpperCase() } RISK cases</Typography>
          { recents.length > 0 &&
            <Box sx={{ my: 2 }}>
              { recents.map((item, index) => {
                  return <Typography key={index}>{moment.utc(item.createdOn).format('D/M/YYYY')}: {item.entity}</Typography>
                })
              }
            </Box>
          }
        </Box>
      </Grid>
    );
  } else {
    return (
      
      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
        {/* <button onClick={testShit}>
            test
        </button> */}
        <Box sx={ STYLES.container }>
          <LinkBase component={Link} to={"create"}>
            <ListItemButton>
              <ListItemIcon><AddCircleOutlineIcon/></ListItemIcon>
              <ListItemText primary="Add New Assessment" />
            </ListItemButton>
          </LinkBase>
          <LinkBase component={Link} to={"list"}>
            <ListItemButton>
              <ListItemIcon><VisibilityIcon/></ListItemIcon>
              <ListItemText primary="View Assessments List" />
            </ListItemButton>
          </LinkBase>
          <LinkBase component={Link} to={"fatf"}>
            <ListItemButton>
              <ListItemIcon><NotesIcon/></ListItemIcon>
              <ListItemText primary="FATF List" />
            </ListItemButton>
          </LinkBase> 
          <LinkBase component={Link} to={"ewra?data=" + avgs}>
            <ListItemButton>
              <ListItemIcon><AccountBalanceIcon/></ListItemIcon>
              <ListItemText primary="EWRA Submission" />
            </ListItemButton>
          </LinkBase>
          
        </Box>
      </Grid>
    )
  }
}

export default GridBox;