import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TraxxContainer, Title }from 'components';
import { Typography } from '@mui/material';
import moment from 'moment';
import CountryList from './CountryList';
import { countries } from 'utils';
import Chip from '@mui/material/Chip';

const STYLES = {
  chip: {
    // width: '100%', 
    // justifyContent: 'space-between', 
    px: '5px',
    mx: "2px",
    my: "3px"
  }
}

const headers = [
  {
    title: 'No.',
    styles: { width: '5%' }
  },
  {
    title: 'Date',
    styles: { width: '5%' }
  },
  {
    title: 'Black List',
    styles: { width: '20%' }
  },
  {
    title: 'Gray List',
    styles: { width: '20%' }
  },
  {
    title: 'Others List',
    styles: { width: '15%' }
  },
  {
    title: 'Remarks',
    styles: { width: '25%' }
  },
  {
    title: 'Modified by',
    styles: { width: '10%' }
  }
]

const CountriesDisplay = ({ countriesList }) => {
  return <>
    {
      countriesList?.map((item, index) => {
        const country = countries.find(country => country.code === item);

        if (!country) return;

        const { label, code } = country;
        // return <Chip
        //   key={index}
        //   label={<><img
        //     loading="lazy"
        //     width="20"
        //     src={`https://flagcdn.com/w20/${code.toLowerCase()}.png`}
        //     srcSet={`https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`}
        //     alt=""
        //   /> &nbsp;{label}</>}
        //   variant="outlined"
        //   sx={ STYLES.chip }
        // />
        return <li key={index}>{label}</li>
      })
    }
  </>
}

const FatfHistory = ({ countryList }) => {

  const sortCountry = (countries) => {
    return countries?.sort((a,b) => {
      if (a > b) return 1;
      else return -1;
    })
  }

  return <TraxxContainer component={
    <>
      <Title text="History" />
      <Table>
        <TableHead>
          <TableRow>
            { headers.map((item, index) => <TableCell sx={item.styles} key={index}><b>{ item.title }</b></TableCell>) }
          </TableRow>
        </TableHead>
        <TableBody>
          { countryList?.map((item, index) => {
            const { createdOn, blackList, grayList, othersList, changeLog, remarks } = item;
            const updatedBlackList = sortCountry(blackList?.split(','));
            const updatedGrayList = sortCountry(grayList?.split(','));
            const updatedOthersList = sortCountry(othersList?.split(','));

            return <TableRow key={index}>
              <TableCell>{ index + 1 }</TableCell>
              <TableCell>{ moment(createdOn).format('LLL') }</TableCell>
              <TableCell><CountriesDisplay countriesList={updatedBlackList} /></TableCell>
              <TableCell><CountriesDisplay countriesList={updatedGrayList} /></TableCell>
              <TableCell><CountriesDisplay countriesList={updatedOthersList} /></TableCell>
              <TableCell>{ remarks }</TableCell>
              <TableCell>{ changeLog && JSON.parse(changeLog).user.name }</TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </>
  } />
}

export default FatfHistory;