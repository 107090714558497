import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const header = [
  {
    id: 'index',
    numeric: false,
    disablePadding: true,
    label: 'No.',
  },
  {
    id: 'created_on',
    numeric: false,
    disablePadding: false,
    label: 'Date of Creation',
  },
  {
    id: 'company_name',
    numeric: false,
    disablePadding: true,
    label: 'Company Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'zohoStatus',
    numeric: false,
    disablePadding: false,
    label: 'Sign Status',
  },
  {
    id: 'document',
    numeric: false,
    disablePadding: false,
    label: 'Document',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  }
];

const HeaderComponent = () => {
  return (
    <TableHead>
      <TableRow>
        { header.map((item) => {
            return <TableCell key={item.id}><b>{ item.label }</b></TableCell>
          })
        }
      </TableRow>
    </TableHead>
  )
};

export default HeaderComponent;