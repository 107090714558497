import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';

import { enqueueSnackbar } from 'notistack';
import { API } from 'services';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

const CreateInstructions = () => {

  const [loading, setLoading] = useState(false);
  const [otherCurrency, setOtherCurrency] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    setLoading(true);
    let error = false;
    const data = {
      companyName: form.get('companyName'),
      benName: form.get('benName'),
      benAccountNo: form.get('benAccountNo'),
      benAddress: form.get('benAddress'),
      fullName: form.get('fullName'),
      telephone: form.get('telephone'),
      email: form.get('email'),
      benBankName: form.get('benBankName'),
      benBankAddress: form.get('benBankAddress'),
      swiftCode: form.get('swiftCode'),
      ibanNo: form.get('ibanNo'),
      corrBank: form.get('corrBank'),
      purpose: form.get('purpose'),
      furtherCredit: form.get('furtherCredit'),
      remarks: form.get('remarks'),
      currency: form.get('currency') === 'Other' ? form.get('currency2') : form.get('currency'),
      authorisedName: form.get('authorisedName'),
      authorisedEmail: form.get('authorisedEmail')
    }

    // console.log('data: ', data)

    for (const key in data) {
      if (key === 'furtherCredit' || key === 'remarks') continue;
      if (data[key] === '') error = true;
    }
    if (error) {
      enqueueSnackbar("Please enter all the required fields!", {
        variant: 'error',
        autoHideDuration: 3000
      })
      setLoading(false);
      return;
    }
    // console.log('data: ', data)

    try {
      const result = await API.Finance.Instructions.create_instructions(data); 
      if (result) {
        setLoading(false);
        enqueueSnackbar("You will receive an email from Zoho Sign soon.", {
          variant: 'success',
          autoHideDuration: 5000
        });
        navigate('/forms/success');
      } else {
        enqueueSnackbar("Error submitting form.", {
          variant: 'error',
          autoHideDuration: 3000
        });
      }
    } catch (err) {
      console.log('err: ', err);
      enqueueSnackbar(err.message, {
        variant: 'error',
        autoHideDuration: 3000
      });
    }

  };

  return (

    <>
      <Typography variant="h5">
        Form for Standing Instructions
      </Typography>
      <Typography variant="subtitle1" color="gray">
        Note: After form submission, you will receive an email from ZohoSign for e-signature.
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ my: 2 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="companyName"
          label="Company Name"
          name="companyName"
          autoFocus
        />

        {/* Beneficiary Payee */}
        <TextField
          margin="normal"
          required
          fullWidth
          name="benName"
          label="Beneficiary Name"
          id="benName"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="benAccountNo"
          label="Beneficiary Account No"
          id="benAccountNo"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="benAddress"
          label="Beneficiary Address"
          id="benAddress"
        />

        {/* Contact Person */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="fullName"
              label="Contact Person Full Name"
              id="fullName"
              autoComplete="name"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="telephone"
              label="Contact Person Telephone"
              id="telephone"
              autoComplete="tel"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              type="email"
              name="email"
              label="Contact Person Email"
              id="email"
              autoComplete="email"
            />
          </Grid>
        </Grid>
        
        {/* Beneficiary Bank */}
        <TextField
          margin="normal"
          required
          fullWidth
          name="benBankName"
          label="Beneficiary Bank Name"
          id="benBankName"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="benBankAddress"
          label="Beneficiary Bank Address"
          id="benBankAddress"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="swiftCode"
          label="SWIFT Code"
          id="swiftCode"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="ibanNo"
          label="ABA / FEDWIRE / IBAN No"
          id="ibanNo"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="corrBank"
          label="Correspondent Bank"
          id="corrBank"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="purpose"
          label="Purpose of Remittance"
          id="purpose"
        />
        <TextField
          margin="normal"
          fullWidth
          name="furtherCredit"
          label="Further Credit To"
          id="furtherCredit"
        />
        <TextField
          margin="normal"
          fullWidth
          name="remarks"
          label="Other Remarks"
          id="remarks"
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Transferred Funds Currency *</InputLabel>
          <Select
            required
            fullWidth
            name="currency"
            label="Transferred Funds Currency"
            id="currency"
            defaultValue="USD"
          >
            <MenuItem value="USD" onClick={() => setOtherCurrency(false)}>USD</MenuItem>
            <MenuItem value="EURO" onClick={() => setOtherCurrency(false)}>EURO</MenuItem>
            <MenuItem value="Other" onClick={() => setOtherCurrency(true)}>Other</MenuItem>
          </Select>
        </FormControl> 
        { otherCurrency &&
          <TextField
            margin="normal"
            fullWidth
            name="currency2"
            label="Transferred Funds Currency (Other)"
            id="currency2"
          />
        }
        {/* Authorised Signatory */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="authorisedName"
              label="Authorised Signatory Name"
              id="authorisedName"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="authorisedEmail"
              label="Authorised Signatory Email"
              id="authorisedEmail"
            />
          </Grid>
        </Grid>
        {
          loading ?
          <Button
            fullWidth
            sx={{ mt: 3, mb: 2 }}
          >
            <CircularProgress size={"1.5rem"} />
          </Button>
          :
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            required
          >
            Submit
          </Button>
        }
      </Box>
    </>  
  );
}

export default CreateInstructions;