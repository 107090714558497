import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { userRetrieve } from "store";

import { routes } from "routes";

const DashboardGuard = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const self = useSelector((state) => state.auth.userInfo);
  const token = localStorage.getItem('d3:userToken')
  ? localStorage.getItem('d3:userToken')
  : null

  const retrieveProfile = async () => {
    await dispatch(userRetrieve({ userId: token }))
  }

  useEffect(() => {
    if (!self) return;
    if (Object.keys(self).length !== 0) {
      const found = routes[1].children.find(item => {
        return item.path === location.pathname;
      })
      if (!self?.access?.includes(found?.access)) navigate('/')
    }
  }, [self])

  useEffect(() => {
    if (token && Object.keys(self).length === 0) retrieveProfile();
    if (!token) navigate('/access/login')
  }, [])

  return <></>
}

export default DashboardGuard;