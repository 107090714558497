import { sessioned_client } from '../auth';
import { get_api_path } from '../api';
import { saveAs } from 'file-saver';

export const fetch_zoho_doc = async (requestID) => {

  const url = get_api_path('misc/getZohoDoc', 'microservices');
  const response = await sessioned_client().post(url, { requestID });

  const filename = response.data.result;
  saveAs(`${process.env.REACT_APP_API_MICROSERVICES}${filename}`, filename)

  return true;
}

export const send_zoho_doc = async (requestID) => {

  const url = get_api_path('misc/sendZohoDoc', 'microservices');
  const response = await sessioned_client().post(url, { requestID });

  return response;
}
