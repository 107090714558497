import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import businessSummaryFields from 'utils/fiserv/businessSummary';
import { TraxxField } from 'components';

const defaultData = {
  briefSummary: "",
  mccCode: "",
  mccDescription: "",
  cardTurnover: "",
  totalTurnover: "",
  avgTicketAmt: "44",
  tranVolumeInternet: "100",
  tranVolumeMoto: "0",
  tranVolumeInStore: "0",
  deliveryDays0: "90",
  deliveryDays7: "10",
  deliveryDays14: "0",
  deliveryDays30: "0",
  deliveryDaysOver30: "0",
  tranTypeMagStrip: "80",
  tranTypeChip: "20",
  tranTypeKeyed: "0"
}

const Step5 = ({ merchant, handleNext }) => {

  const [ businessSummary, setBusinessSummary ] = useState(defaultData);

  useEffect(() => {
    if (!merchant) return;
    if (merchant.businessSummary) {
      setBusinessSummary(JSON.parse(merchant.businessSummary))
    } else if (merchant.selectedMerchant) {
      const info = JSON.parse(merchant.selectedMerchant)
      setBusinessSummary({
        ...defaultData,
        briefSummary: info.coreBusiness,
        mccDescription: info.businessCat
      })
    }
  }, [merchant])

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setBusinessSummary(prev => ({...prev, [name]: value}))
  }
  
  return <>
  <Grid container spacing={2}>

    <TraxxField 
      arr={businessSummaryFields}
      data={businessSummary}
      handleChange={handleChange}
    />

    <Grid item xs={12} sx={{ mt: 3 }}>
      <Button variant="contained" fullWidth onClick={() => handleNext(businessSummary, "businessSummary")}>
        Save
      </Button>
    </Grid>

  </Grid>
  </>
}

export default Step5;