const countries = [
  { code: 'AD', label: 'Andorra', isoNumeric: '020' },
  { code: 'AE', label: 'United Arab Emirates', isoNumeric: '784' },
  { code: 'AF', label: 'Afghanistan', isoNumeric: '004' },
  { code: 'AG', label: 'Antigua and Barbuda', isoNumeric: '028' },
  { code: 'AI', label: 'Anguilla', isoNumeric: '660' },
  { code: 'AL', label: 'Albania', isoNumeric: '008' },
  { code: 'AM', label: 'Armenia', isoNumeric: '051' },
  { code: 'AO', label: 'Angola', isoNumeric: '024' },
  { code: 'AQ', label: 'Antarctica', isoNumeric: '010' },
  { code: 'AR', label: 'Argentina', isoNumeric: '032' },
  { code: 'AS', label: 'American Samoa', isoNumeric: '016' },
  { code: 'AT', label: 'Austria', isoNumeric: '040' },
  { code: 'AU', label: 'Australia', isoNumeric: '036', suggested: true },
  { code: 'AW', label: 'Aruba', isoNumeric: '533' },
  { code: 'AX', label: 'Alland Islands', isoNumeric: '248' },
  { code: 'AZ', label: 'Azerbaijan', isoNumeric: '031' },
  { code: 'BA', label: 'Bosnia and Herzegovina', isoNumeric: '070' },
  { code: 'BB', label: 'Barbados', isoNumeric: '052' },
  { code: 'BD', label: 'Bangladesh', isoNumeric: '050' },
  { code: 'BE', label: 'Belgium', isoNumeric: '056' },
  { code: 'BF', label: 'Burkina Faso', isoNumeric: '854' },
  { code: 'BG', label: 'Bulgaria', isoNumeric: '100' },
  { code: 'BH', label: 'Bahrain', isoNumeric: '048' },
  { code: 'BI', label: 'Burundi', isoNumeric: '108' },
  { code: 'BJ', label: 'Benin', isoNumeric: '204' },
  { code: 'BL', label: 'Saint Barthelemy', isoNumeric: '652' },
  { code: 'BM', label: 'Bermuda', isoNumeric: '060' },
  { code: 'BN', label: 'Brunei Darussalam', isoNumeric: '096' },
  { code: 'BO', label: 'Bolivia', isoNumeric: '068' },
  { code: 'BR', label: 'Brazil', isoNumeric: '076' },
  { code: 'BS', label: 'Bahamas', isoNumeric: '044' },
  { code: 'BT', label: 'Bhutan', isoNumeric: '064' },
  { code: 'BV', label: 'Bouvet Island', isoNumeric: '074' },
  { code: 'BW', label: 'Botswana', isoNumeric: '072' },
  { code: 'BY', label: 'Belarus', isoNumeric: '112' },
  { code: 'BZ', label: 'Belize', isoNumeric: '084' },
  { code: 'CA', label: 'Canada', isoNumeric: '124', suggested: true },
  { code: 'CC', label: 'Cocos (Keeling) Islands', isoNumeric: '166' },
  { code: 'CD', label: 'Congo, Democratic Republic of the', isoNumeric: '180' },
  { code: 'CF', label: 'Central African Republic', isoNumeric: '140' },
  { code: 'CG', label: 'Congo, Republic of the', isoNumeric: '178' },
  { code: 'CH', label: 'Switzerland', isoNumeric: '756' },
  { code: 'CI', label: "Cote d'Ivoire", isoNumeric: '384' },
  { code: 'CK', label: 'Cook Islands', isoNumeric: '184' },
  { code: 'CL', label: 'Chile', isoNumeric: '152' },
  { code: 'CM', label: 'Cameroon', isoNumeric: '120' },
  { code: 'CN', label: 'China', isoNumeric: '156' },
  { code: 'CO', label: 'Colombia', isoNumeric: '170' },
  { code: 'CR', label: 'Costa Rica', isoNumeric: '188' },
  { code: 'CU', label: 'Cuba', isoNumeric: '192' },
  { code: 'CV', label: 'Cape Verde', isoNumeric: '132' },
  { code: 'CW', label: 'Curacao', isoNumeric: '531' },
  { code: 'CX', label: 'Christmas Island', isoNumeric: '162' },
  { code: 'CY', label: 'Cyprus', isoNumeric: '196' },
  { code: 'CZ', label: 'Czech Republic', isoNumeric: '203' },
  { code: 'DE', label: 'Germany', isoNumeric: '276', suggested: true },
  { code: 'DJ', label: 'Djibouti', isoNumeric: '262' },
  { code: 'DK', label: 'Denmark', isoNumeric: '208' },
  { code: 'DM', label: 'Dominica', isoNumeric: '212' },
  { code: 'DO', label: 'Dominican Republic', isoNumeric: '214' },
  { code: 'DZ', label: 'Algeria', isoNumeric: '012' },
  { code: 'EC', label: 'Ecuador', isoNumeric: '218' },
  { code: 'EE', label: 'Estonia', isoNumeric: '233' },
  { code: 'EG', label: 'Egypt', isoNumeric: '818' },
  { code: 'EH', label: 'Western Sahara', isoNumeric: '732' },
  { code: 'ER', label: 'Eritrea', isoNumeric: '232' },
  { code: 'ES', label: 'Spain', isoNumeric: '724' },
  { code: 'ET', label: 'Ethiopia', isoNumeric: '231' },
  { code: 'FI', label: 'Finland', isoNumeric: '246' },
  { code: 'FJ', label: 'Fiji', isoNumeric: '242' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', isoNumeric: '238' },
  { code: 'FM', label: 'Micronesia, Federated States of', isoNumeric: '583' },
  { code: 'FO', label: 'Faroe Islands', isoNumeric: '234' },
  { code: 'FR', label: 'France', isoNumeric: '250', suggested: true },
  { code: 'GA', label: 'Gabon', isoNumeric: '266' },
  { code: 'GB', label: 'United Kingdom', isoNumeric: '826' },
  { code: 'GD', label: 'Grenada', isoNumeric: '308' },
  { code: 'GE', label: 'Georgia', isoNumeric: '268' },
  { code: 'GF', label: 'French Guiana', isoNumeric: '254' },
  { code: 'GG', label: 'Guernsey', isoNumeric: '831' },
  { code: 'GH', label: 'Ghana', isoNumeric: '288' },
  { code: 'GI', label: 'Gibraltar', isoNumeric: '292' },
  { code: 'GL', label: 'Greenland', isoNumeric: '304' },
  { code: 'GM', label: 'Gambia', isoNumeric: '270' },
  { code: 'GN', label: 'Guinea', isoNumeric: '324' },
  { code: 'GP', label: 'Guadeloupe', isoNumeric: '312' },
  { code: 'GQ', label: 'Equatorial Guinea', isoNumeric: '226' },
  { code: 'GR', label: 'Greece', isoNumeric: '300' },
  { code: 'GS', label: 'South Georgia and the South Sandwich Islands', isoNumeric: '239' },
  { code: 'GT', label: 'Guatemala', isoNumeric: '320' },
  { code: 'GU', label: 'Guam', isoNumeric: '316' },
  { code: 'GW', label: 'Guinea-Bissau', isoNumeric: '624' },
  { code: 'GY', label: 'Guyana', isoNumeric: '328' },
  { code: 'HK', label: 'Hong Kong', isoNumeric: '344' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', isoNumeric: '334' },
  { code: 'HN', label: 'Honduras', isoNumeric: '340' },
  { code: 'HR', label: 'Croatia', isoNumeric: '191' },
  { code: 'HT', label: 'Haiti', isoNumeric: '332' },
  { code: 'HU', label: 'Hungary', isoNumeric: '348' },
  { code: 'ID', label: 'Indonesia', isoNumeric: '360' },
  { code: 'IE', label: 'Ireland', isoNumeric: '372' },
  { code: 'IL', label: 'Israel', isoNumeric: '376' },
  { code: 'IM', label: 'Isle of Man', isoNumeric: '833' },
  { code: 'IN', label: 'India', isoNumeric: '356' },
  { code: 'IO', label: 'British Indian Ocean Territory', isoNumeric: '086' },
  { code: 'IQ', label: 'Iraq', isoNumeric: '368' },
  { code: 'IR', label: 'Iran, Islamic Republic of', isoNumeric: '364' },
  { code: 'IS', label: 'Iceland', isoNumeric: '352' },
  { code: 'IT', label: 'Italy', isoNumeric: '380' },
  { code: 'JE', label: 'Jersey', isoNumeric: '832' },
  { code: 'JM', label: 'Jamaica', isoNumeric: '388' },
  { code: 'JO', label: 'Jordan', isoNumeric: '400' },
  { code: 'JP', label: 'Japan', isoNumeric: '392', suggested: true },
  { code: 'KE', label: 'Kenya', isoNumeric: '404' },
  { code: 'KG', label: 'Kyrgyzstan', isoNumeric: '417' },
  { code: 'KH', label: 'Cambodia', isoNumeric: '116' },
  { code: 'KI', label: 'Kiribati', isoNumeric: '296' },
  { code: 'KM', label: 'Comoros', isoNumeric: '174' },
  { code: 'KN', label: 'Saint Kitts and Nevis', isoNumeric: '659' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", isoNumeric: '408' },
  { code: 'KR', label: 'Korea, Republic of', isoNumeric: '410' },
  { code: 'KW', label: 'Kuwait', isoNumeric: '414' },
  { code: 'KY', label: 'Cayman Islands', isoNumeric: '136' },
  { code: 'KZ', label: 'Kazakhstan', isoNumeric: '398' },
  { code: 'LA', label: "Lao People's Democratic Republic", isoNumeric: '418' },
  { code: 'LB', label: 'Lebanon', isoNumeric: '422' },
  { code: 'LC', label: 'Saint Lucia', isoNumeric: '662' },
  { code: 'LI', label: 'Liechtenstein', isoNumeric: '438' },
  { code: 'LK', label: 'Sri Lanka', isoNumeric: '144' },
  { code: 'LR', label: 'Liberia', isoNumeric: '430' },
  { code: 'LS', label: 'Lesotho', isoNumeric: '426' },
  { code: 'LT', label: 'Lithuania', isoNumeric: '440' },
  { code: 'LU', label: 'Luxembourg', isoNumeric: '442' },
  { code: 'LV', label: 'Latvia', isoNumeric: '428' },
  { code: 'LY', label: 'Libya', isoNumeric: '434' },
  { code: 'MA', label: 'Morocco', isoNumeric: '504' },
  { code: 'MC', label: 'Monaco', isoNumeric: '492' },
  { code: 'MD', label: 'Moldova, Republic of', isoNumeric: '498' },
  { code: 'ME', label: 'Montenegro', isoNumeric: '499' },
  { code: 'MF', label: 'Saint Martin (French part)', isoNumeric: '663' },
  { code: 'MG', label: 'Madagascar', isoNumeric: '450' },
  { code: 'MH', label: 'Marshall Islands', isoNumeric: '584' },
  { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', isoNumeric: '807' },
  { code: 'ML', label: 'Mali', isoNumeric: '466' },
  { code: 'MM', label: 'Myanmar', isoNumeric: '104' },
  { code: 'MN', label: 'Mongolia', isoNumeric: '496' },
  { code: 'MO', label: 'Macao', isoNumeric: '446' },
  { code: 'MP', label: 'Northern Mariana Islands', isoNumeric: '580' },
  { code: 'MQ', label: 'Martinique', isoNumeric: '474' },
  { code: 'MR', label: 'Mauritania', isoNumeric: '478' },
  { code: 'MS', label: 'Montserrat', isoNumeric: '500' },
  { code: 'MT', label: 'Malta', isoNumeric: '470' },
  { code: 'MU', label: 'Mauritius', isoNumeric: '480' },
  { code: 'MV', label: 'Maldives', isoNumeric: '462' },
  { code: 'MW', label: 'Malawi', isoNumeric: '454' },
  { code: 'MX', label: 'Mexico', isoNumeric: '484' },
  { code: 'MY', label: 'Malaysia', isoNumeric: '458' },
  { code: 'MZ', label: 'Mozambique', isoNumeric: '508' },
  { code: 'NA', label: 'Namibia', isoNumeric: '516' },
  { code: 'NC', label: 'New Caledonia', isoNumeric: '540' },
  { code: 'NE', label: 'Niger', isoNumeric: '562' },
  { code: 'NF', label: 'Norfolk Island', isoNumeric: '574' },
  { code: 'NG', label: 'Nigeria', isoNumeric: '566' },
  { code: 'NI', label: 'Nicaragua', isoNumeric: '558' },
  { code: 'NL', label: 'Netherlands', isoNumeric: '528' },
  { code: 'NO', label: 'Norway', isoNumeric: '578' },
  { code: 'NP', label: 'Nepal', isoNumeric: '524' },
  { code: 'NR', label: 'Nauru', isoNumeric: '520' },
  { code: 'NU', label: 'Niue', isoNumeric: '570' },
  { code: 'NZ', label: 'New Zealand', isoNumeric: '554' },
  { code: 'OM', label: 'Oman', isoNumeric: '512' },
  { code: 'PA', label: 'Panama', isoNumeric: '591' },
  { code: 'PE', label: 'Peru', isoNumeric: '604' },
  { code: 'PF', label: 'French Polynesia', isoNumeric: '258' },
  { code: 'PG', label: 'Papua New Guinea', isoNumeric: '598' },
  { code: 'PH', label: 'Philippines', isoNumeric: '608' },
  { code: 'PK', label: 'Pakistan', isoNumeric: '586' },
  { code: 'PL', label: 'Poland', isoNumeric: '616' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', isoNumeric: '666' },
  { code: 'PN', label: 'Pitcairn', isoNumeric: '612' },
  { code: 'PR', label: 'Puerto Rico', isoNumeric: '630' },
  { code: 'PS', label: 'Palestine, State of', isoNumeric: '275' },
  { code: 'PT', label: 'Portugal', isoNumeric: '620' },
  { code: 'PW', label: 'Palau', isoNumeric: '585' },
  { code: 'PY', label: 'Paraguay', isoNumeric: '600' },
  { code: 'QA', label: 'Qatar', isoNumeric: '634' },
  { code: 'RE', label: 'Reunion', isoNumeric: '638' },
  { code: 'RO', label: 'Romania', isoNumeric: '642' },
  { code: 'RS', label: 'Serbia', isoNumeric: '688' },
  { code: 'RU', label: 'Russian Federation', isoNumeric: '643' },
  { code: 'RW', label: 'Rwanda', isoNumeric: '646' },
  { code: 'SA', label: 'Saudi Arabia', isoNumeric: '682' },
  { code: 'SB', label: 'Solomon Islands', isoNumeric: '090' },
  { code: 'SC', label: 'Seychelles', isoNumeric: '690' },
  { code: 'SD', label: 'Sudan', isoNumeric: '729' },
  { code: 'SE', label: 'Sweden', isoNumeric: '752' },
  { code: 'SG', label: 'Singapore', isoNumeric: '702' },
  { code: 'SH', label: 'Saint Helena', isoNumeric: '654' },
  { code: 'SI', label: 'Slovenia', isoNumeric: '705' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', isoNumeric: '744' },
  { code: 'SK', label: 'Slovakia', isoNumeric: '703' },
  { code: 'SL', label: 'Sierra Leone', isoNumeric: '694' },
  { code: 'SM', label: 'San Marino', isoNumeric: '674' },
  { code: 'SN', label: 'Senegal', isoNumeric: '686' },
  { code: 'SO', label: 'Somalia', isoNumeric: '706' },
  { code: 'SR', label: 'Suriname', isoNumeric: '740' },
  { code: 'SS', label: 'South Sudan', isoNumeric: '728' },
  { code: 'ST', label: 'Sao Tome and Principe', isoNumeric: '678' },
  { code: 'SV', label: 'El Salvador', isoNumeric: '222' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', isoNumeric: '534' },
  { code: 'SY', label: 'Syrian Arab Republic', isoNumeric: '760' },
  { code: 'SZ', label: 'Swaziland', isoNumeric: '748' },
  { code: 'TC', label: 'Turks and Caicos Islands', isoNumeric: '796' },
  { code: 'TD', label: 'Chad', isoNumeric: '148' },
  { code: 'TF', label: 'French Southern Territories', isoNumeric: '260' },
  { code: 'TG', label: 'Togo', isoNumeric: '768' },
  { code: 'TH', label: 'Thailand', isoNumeric: '764' },
  { code: 'TJ', label: 'Tajikistan', isoNumeric: '762' },
  { code: 'TK', label: 'Tokelau', isoNumeric: '772' },
  { code: 'TL', label: 'Timor-Leste', isoNumeric: '626' },
  { code: 'TM', label: 'Turkmenistan', isoNumeric: '795' },
  { code: 'TN', label: 'Tunisia', isoNumeric: '788' },
  { code: 'TO', label: 'Tonga', isoNumeric: '776' },
  { code: 'TR', label: 'Turkey', isoNumeric: '792' },
  { code: 'TT', label: 'Trinidad and Tobago', isoNumeric: '780' },
  { code: 'TV', label: 'Tuvalu', isoNumeric: '798' },
  { code: 'TW', label: 'Taiwan, Republic of China', isoNumeric: '158' },
  { code: 'TZ', label: 'United Republic of Tanzania', isoNumeric: '834' },
  { code: 'UA', label: 'Ukraine', isoNumeric: '804' },
  { code: 'UG', label: 'Uganda', isoNumeric: '800' },
  { code: 'US', label: 'United States', isoNumeric: '840', suggested: true },
  { code: 'UY', label: 'Uruguay', isoNumeric: '858' },
  { code: 'UZ', label: 'Uzbekistan', isoNumeric: '860' },
  { code: 'VA', label: 'Holy See (Vatican City State)', isoNumeric: '336' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', isoNumeric: '670' },
  { code: 'VE', label: 'Venezuela', isoNumeric: '862' },
  { code: 'VG', label: 'British Virgin Islands', isoNumeric: '092' },
  { code: 'VI', label: 'US Virgin Islands', isoNumeric: '850' },
  { code: 'VN', label: 'Vietnam', isoNumeric: '704' },
  { code: 'VU', label: 'Vanuatu', isoNumeric: '548' },
  { code: 'WF', label: 'Wallis and Futuna', isoNumeric: '876' },
  { code: 'WS', label: 'Samoa', isoNumeric: '882' },
  { code: 'XK', label: 'Kosovo', isoNumeric: '926' },
  { code: 'YE', label: 'Yemen', isoNumeric: '887' },
  { code: 'YT', label: 'Mayotte', isoNumeric: '175' },
  { code: 'ZA', label: 'South Africa', isoNumeric: '710' },
  { code: 'ZM', label: 'Zambia', isoNumeric: '894' },
  { code: 'ZW', label: 'Zimbabwe', isoNumeric: '716' },
];

export default countries;
