import { sessioned_client } from '../auth';
import { get_api_path } from '../api';

export const Login = async (data) => {
  const url = get_api_path('user/login');
  const response = await sessioned_client().post(url, data);
  return response.data;
}

export const Retrieve = async (data) => {
  const url = get_api_path('user/retrieve');
  const response = await sessioned_client().post(url, data);
  return response.data;
}