import * as React from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { API } from 'services';

import { SnackbarProvider, enqueueSnackbar } from 'notistack'

import CircularProgress from '@mui/material/CircularProgress';

import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from 'store'

const Login = () => {

  const navigate = useNavigate();
  // const { loading, error } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const hello = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.auth);

  // console.log('hello: ', hello)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const body = ({
      email: data.get('email'),
      password: data.get('password'),
    });
    const result = await dispatch(userLogin(body));
    // console.log('result: ', result);
    if (result) navigate('/')
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {/* <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        /> */}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          { loading ? <CircularProgress size={"2rem"} /> : "Sign In" }
        </Button>
        <Grid container>
          <Grid item xs>
            {/* <Link href="#" variant="body2">
              Forgot password?
            </Link> */}
          </Grid>
        </Grid>
      </Box>
      </>  
  );
}

export default Login;