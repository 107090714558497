import animatedImage from "assets/images/Control Panel.gif";
import { useState, useEffect } from 'react';
import { API } from 'services';

const Dashboard = () => {

  const [riskList, setRiskList] = useState([]);

  useEffect(() => {
    getRisks();
  }, []);

  const getRisks = async () => {
    const result = await API.Compliance.Risk.fetch_all_risk();
    if (result) {
      setRiskList(result);
    }
  };

  return (
  <div className = 'flex flex-col'>
    <h1 className = 'text-2xl font-semibold'> Welcome to TRAXX D3 Portal</h1>
    <div className = 'p-2' />
    <div className = 'flex flex-row h-[75vh] w-[90vw]'>
      <div className = 'flex flex-col w-[70%]'>
        <div className = 'flex flex-row w-full h-[50%]'>
          <div className = 'aspect-square bg-neutral-50 rounded-3xl shadow-lg h-full '>
            <img src={animatedImage} className=' rounded-3xl'>
            </img>
          </div>
          <div className = 'p-1' />
          <div className = 'w-full bg-neutral-50 rounded-3xl shadow-lg h-full'>
            
          </div>
        </div>
        <div className = 'p-1' />
        <div className = 'flex flex-row bg-neutral-50 rounded-3xl shadow-lg h-[50%] '>

        </div>
      </div>
      <div className = 'p-1' />
      <div className = 'flex flex-col rounded-3xl bg-neutral-50 w-[30%] h-full shadow-lg p-10 overflow-y-auto no-scrollbar'>
        <div>
          <p className = 'text-lg font-[550]'>
            Risk Assessment Sign Status
          </p>
          <div className = 'p-1'/>
          <div className = 'flex flex-col'>
          {
            riskList.map((entities, entityIndex) => (
              
              <div key={entityIndex} className = 'my-[6px] flex flex-row  '>
                <p className = 'w-full truncate'>
                  {riskList[entityIndex].entity}
                </p>

                <p className = 'w-[150px] text-right flex justify-end'>
                  {riskList[entityIndex].zohoStatus === 'completed' ? 'Completed' : 'Pending'}
                </p>

              </div>
            ))
          }
          </div>
        </div>
        
      </div>
    </div>


  </div>
  )
}

export default Dashboard;