import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import { countries } from "utils";

const STYLES = {
  chip: {
    width: '100%', 
    justifyContent: 'space-between', 
    px: '1rem'
  }
}

const CountryList = ({ countriesList, handleDelete }) => {
  return <>
    <Grid container spacing={2}>
      { countriesList && countriesList.map((item, index) => {
        const label = countries.find(country => country.code === item)?.label;
        return <Grid item key={index}>
          <Chip
            label={label}
            onDelete={() => handleDelete(item, label)}
            deleteIcon={<DeleteIcon />}
            variant="outlined"
            sx={ STYLES.chip }
          />
        </Grid>
      })}
    </Grid>
  </>
}

export default CountryList;