import countries from "utils/countries"

const merchantDetailsFields = [
  {
    prop: "businessName",
    required: true,
    grid: { xs: 6 },
    label: "Business Name",
  },
  {
    prop: "legalName",
    required: true,
    grid: { xs: 6 },
    label: "Legal Name",
  },
  {
    prop: "businessType",
    type: "select",
    required: true,
    grid: { xs: 6 },
    label: "Business Type",
    options: [
      {
        value: "Partnership",
      },
      {
        value: "Public Limited",
      },
      {
        value: "Trusts",
      },
      {
        value: "Sole Proprietor",
      },
      {
        value: "Ltd Liab Partnership",
      },
      {
        value: "Private Limited",
      },
      {
        value: "Individual - Non Professional",
      },
      {
        value: "Associations and Societies",
      },
      {
        value: "Individual - Professional"
      },
    ],
  },
  {
    prop: "registrationNo",
    required: true,
    grid: { xs: 6 },
    label: "Business Registration No.",
  },
  {
    prop: "fundingType",
    required: true,
    grid: { xs: 6 },
    label: "Funding Type",
  },
  {
    prop: "merchantSegment",
    type: "select",
    required: true,
    grid: { xs: 6 },
    label: "Merchant Segment",
    options: [
      {
        value: "SMB",
        label: "Small to Medium Business"
      },
      {
        value: "ENT",
        label: "Others"
      }
    ],
  },
  {
    prop: "taxidType",
    type: "select",
    required: true,
    grid: { xs: 6 },
    label: "Tax ID Type",
    options: [
      {
        value: "UEN",
        label: "UEN (for ASEAN)"
      },
      {
        value: "ABN",
        label: "ABN (for AU)"
      },
      {
        value: "PAN",
        label: "PAN (for India)"
      }
    ]
  },
  {
    prop: "taxidValue",
    required: true,
    grid: { xs: 6 },
    label: "Tax ID Value",
  },
  {
    prop: "dateIncorporated",
    required: true,
    grid: { xs: 6 },
    label: "Date of Incorporation",
    placeholder: "DD-MM-YYYY",
  },
  {
    prop: "websiteURL",
    required: false,
    grid: { xs: 6 },
    label: "Website URL",
  },
  {
    prop: "communicationEmail",
    required: true,
    grid: { xs: 6 },
    label: "Communication Email",
    fieldType: "email"
  },
  {
    prop: "acceptanceType",
    type: "select",
    required: true,
    grid: { xs: 6 },
    label: "Acceptance Type",
    options: [
      {
        value: "POSMOB",
        label: "POS Merchants"
      },
      {
        value: "ECOM",
        label: "ECOM Merchants"
      }
    ]
  },
  {
    prop: "addressLine",
    required: true,
    grid: { xs: 12 },
    label: "Legal Address Line 1",
  },
  {
    prop: "addressLine2",
    required: true,
    grid: { xs: 12 },
    label: "Legal Address Line 2",
  },
  {
    prop: "addressLine3",
    required: true,
    grid: { xs: 12 },
    label: "Legal Address Line 3",
  },
  {
    prop: "city",
    required: true,
    grid: { xs: 6 },
    label: "City",
  },
  {
    prop: "pincode",    
    required: true,
    grid: { xs: 6 },
    label: "Postcode",
  },
  {
    prop: "state",
    required: true,
    grid: { xs: 6 },
    label: "State",
  },
  {
    prop: "country",
    type: "select",
    required: true,
    grid: { xs: 6 },
    label: "Country",
    options: countries.map((country) => {
      return { value: country.label }
    })
  },
  {
    prop: "contactName",
    required: true,
    grid: { xs: 12 },
    label: "Contact Name",
  },
  {
    prop: "email",
    required: false,
    grid: { xs: 6 },
    label: "Email Address",
    fieldType: "email"
  },
  {
    prop: "phone1", 
    required: true,
    grid: { xs: 6 },
    label: "Phone Number",
  },
]

export default merchantDetailsFields;