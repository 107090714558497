import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Title, TraxxButton, TraxxContainer } from "components";
import { API } from "services";
import { countries } from "utils";
import { CountryList, FatfList, AddFatfDialog, FatfHistory } from "./components";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const init = {
  add: [],
  remove: []
}

const Fatf = () => {

  const user = useSelector(state => state.auth.userInfo);

  const [blackList, setBlackList] = useState([]);
  const [grayList, setGrayList] = useState([]);
  const [othersList, setOthersList] = useState([]);
  const [countryList, setCountryList] = useState();
  const [showAddBlack, setShowAddBlack] = useState('');
  const [showAddGray, setShowAddGray] = useState('');
  const [showAddOthers, setShowAddOthers] = useState('');
  const [selectedCountryBlack, setSelectedCountryBlack] = useState(init)
  const [selectedCountryGray, setSelectedCountryGray] = useState(init)
  const [selectedCountryOthers, setSelectedCountryOthers] = useState(init)
  const [openDialog, setOpenDialog] = useState(false);
  const [remarks, setRemarks] = useState('');

  useEffect(() => {
    getCountryRisks();
  }, [])

  const getCountryRisks = async () => {
    const sortCountry = (countries) => {
      if (!countries) return []
      const arr = countries.split(",")
      return arr?.sort((a,b) => {
        if (a > b) return 1;
        else return -1;
      })
    }

    const result = await API.Compliance.Risk.fetch_country_risk();

    if (result) {
      const sortedBlack = sortCountry(result[result.length-1].blackList)
      const sortedGray = sortCountry(result[result.length-1].grayList)
      const sortedOthers = sortCountry(result[result.length-1].othersList)
      setBlackList(sortedBlack);
      setGrayList(sortedGray);
      setOthersList(sortedOthers);
      setCountryList(result);
    }
  };

  const handleDeleteBlack = (code, label) => {
    const newList = blackList.filter(item => item !== code)
    setBlackList(newList);
    setSelectedCountryBlack({ 
      ...selectedCountryBlack, 
      remove: [ 
        ...selectedCountryBlack.remove,
        label
      ]
    })
  }

  const handleDeleteGray = (code, label) => {
    const newList = grayList.filter(item => item !== code)
    setGrayList(newList);
    setSelectedCountryGray({ 
      ...selectedCountryGray, 
      remove: [ 
        ...selectedCountryGray.remove,
        label
      ]
    })
  }

  const handleDeleteOthers = (code, label) => {
    const newList = othersList.filter(item => item !== code)
    setOthersList(newList);
    setSelectedCountryOthers({ 
      ...selectedCountryOthers, 
      remove: [ 
        ...selectedCountryOthers.remove,
        label
      ]
    })
  }

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    if (name === "Black") setSelectedCountryBlack({ ...selectedCountryBlack, add: value })
    else if (name === "Gray") setSelectedCountryGray({ ...selectedCountryGray, add: value })
    else if (name === "Others") setSelectedCountryOthers({ ...selectedCountryOthers, add: value })
  }

  const handleButtonClick = (listType) => {
    if (listType === "Gray") {
      setShowAddGray(!showAddGray);
      setSelectedCountryGray({ ...selectedCountryGray, add: [] })
    } else if (listType === "Black") {
      setShowAddBlack(!showAddBlack);
      setSelectedCountryBlack({ ...selectedCountryBlack, add: [] })
    } else if (listType === "Others") {
      setShowAddOthers(!showAddOthers);
      setSelectedCountryOthers({ ...selectedCountryOthers, add: [] })
    }
  }

  const handleSubmit = async () => {
    
    const { add: addBlack, remove: removeBlack } = selectedCountryBlack;
    const { add: addGray, remove: removeGray } = selectedCountryGray;
    const { add: addOthers, remove: removeOthers } = selectedCountryOthers;
    
    const getCodes = (arr) => {
      const codes = []
      arr.forEach(item => {
        const code = countries.find(countryItem => countryItem?.label === item).code;
        codes.push(code)
      })
      return codes;
    }

    const addBlackCodes = getCodes(addBlack);
    const addGrayCodes = getCodes(addGray);
    const addOthersCodes = getCodes(addOthers);

    const updatedBlackList = addBlackCodes.length !== 0 ? [ ...blackList, ...addBlackCodes ] : blackList;
    const updatedGrayList = addGrayCodes.length !== 0 ? [ ...grayList, ...addGrayCodes ] : grayList;
    const updatedOthersList = addOthersCodes.length !== 0 ? [ ...othersList, ...addOthersCodes ] : othersList;

    const changeLog = {
      black: { ...selectedCountryBlack },
      gray: { ...selectedCountryGray },
      others: { ...selectedCountryOthers },
      user
    }

    const data = {
      blackList: updatedBlackList.toString(),
      grayList: updatedGrayList.toString(),
      othersList: updatedOthersList.toString(),
      changeLog: JSON.stringify(changeLog),
      remarks
    }

    const result = await API.Compliance.Risk.update_fatf(data);

    if (result) {
      enqueueSnackbar(`List updated!`, {
        variant: 'success',
        autoHideDuration: 3000
      })
      getCountryRisks();
      setOpenDialog(false);
      setSelectedCountryBlack(init);
      setSelectedCountryGray(init);
      setSelectedCountryOthers(init);
    } else {
      enqueueSnackbar('an error occured!', {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  return <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '2rem' }}>
      <Box><Title text="FATF List & MAS Sanctions List" /></Box>
      <Box>
        <AddFatfDialog 
          handleSubmit={handleSubmit} 
          selectedCountryBlack={selectedCountryBlack} 
          selectedCountryGray={selectedCountryGray}
          selectedCountryOthers={selectedCountryOthers}
          open={openDialog}
          setOpen={setOpenDialog}
          remarks={remarks}
          setRemarks={setRemarks}
        />
      </Box>
    </Box>

    <FatfList 
      show={showAddBlack} 
      setShow={setShowAddBlack} 
      selectedCountry={selectedCountryBlack.add}
      handleChange={handleChange}
      blackList={blackList}
      handleDelete={handleDeleteBlack}
      listType="Black"
      handleButtonClick={() => handleButtonClick("Black")}
    />

    <FatfList 
      show={showAddGray} 
      setShow={setShowAddGray} 
      selectedCountry={selectedCountryGray.add}
      handleChange={handleChange}
      blackList={grayList}
      handleDelete={handleDeleteGray}
      listType="Gray"
      handleButtonClick={() => handleButtonClick("Gray")}
    />

    <FatfList 
      show={showAddOthers}
      setShow={setShowAddOthers}
      selectedCountry={selectedCountryOthers.add}
      handleChange={handleChange}
      blackList={othersList}
      handleDelete={handleDeleteOthers}
      listType="Others"
      handleButtonClick={() => handleButtonClick("Others")}
    />

    <FatfHistory countryList={countryList?.reverse()} />
  
  </>
}

export default Fatf;