import Typography from "@mui/material/Typography";
import successImg from "assets/images/success.gif";

const SuccessForm = () => {

  return (
    <>
      <Typography sx={{ mt: 3 }} variant="h6">Thank you. Form successfully submitted!</Typography>
      <img 
        src={successImg} 
        width="400"
      />
    </>
  )
}

export default SuccessForm;