import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const Signatory = ({ signatory, handleSignatoryChange }) => {

  return (
    <Box component="form" noValidate sx={{ px: 2, py: 1 }}>
      <FormControl>
      <Typography sx={{ mb: 3 }}>Internal Signatory Sequence</Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="celia"
          name="radio-buttons-group"
          value={signatory}
          onChange={handleSignatoryChange}
        >
          <FormControlLabel value="first" control={<Radio />} label="Send to Derek first" />
          <FormControlLabel value="second" control={<Radio />} label="Send to Celia first" />
        </RadioGroup>
      </FormControl>      
    </Box>
  )
}

export default Signatory;