import { Outlet } from "react-router-dom";
import { 
  Box,
  Container,
  CssBaseline
} from '@mui/material';
import { TraxxLogo } from "components";

const AuthLayout = () => {
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: '20vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '0px 0px 10px lightgray',
          borderRadius: '6px',
          backgroundColor: 'rgba(255,255,255,0.9)',
          px: 7,
          py: 3
        }}
      >
        <Box sx={{ m: 3 }}><TraxxLogo size="medium" /></Box>
        <Outlet />
      </Box>
    </Container>
  )
}

export default AuthLayout;