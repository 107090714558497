import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FiservDisplay } from "components";
import Grid from "@mui/material/Grid";

const STYLES = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60vw",
    maxHeight: "90vh",
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
  }
}

const ShowApplication = ({ merchant, open, handleClose }) => {

  const [submission, setSubmission] = useState({});

  useEffect(() => {
    if (merchant) {
      setSubmission({
        merchantDetails: JSON.parse(merchant.merchantDetails),
        tradingLocations: JSON.parse(merchant.tradingLocations),
        principalDetails: JSON.parse(merchant.principalDetails),
        uboDetails: JSON.parse(merchant.uboDetails),
        businessSummary: JSON.parse(merchant.businessSummary),
        creditApproval: JSON.parse(merchant.creditApproval),
        ipgProvisioning: JSON.parse(merchant.ipgProvisioning),
        enquireResponse: JSON.parse(merchant.enquireResponse),
      })
    }
  }, [merchant])

  if (!merchant) return;
  return <Modal
    open={open}
    onClose={handleClose}
  >
    <Box sx={ STYLES.box }>

      {
        submission.enquireResponse && <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Enquiry Status</Typography>

          <Box sx={{ mb: 2 }}>
            <Grid container>
              <Grid item sx={{ color: "gray" }} xs={3}>uwDecision: </Grid>
              <Grid item xs={9}>{ submission.enquireResponse.uwDecision }</Grid>
            </Grid>

            <Grid container>
              <Grid item sx={{ color: "gray" }} xs={3}>comments: </Grid>
              <Grid item xs={9}>{ submission.enquireResponse.comments }</Grid>
            </Grid>

            <Grid container>
              <Grid item sx={{ color: "gray" }} xs={3}>lastUpdatedDate: </Grid>
              <Grid item xs={9}>{ submission.enquireResponse.lastUpdatedDate }</Grid>
            </Grid>

            <Grid container>
              <Grid item sx={{ color: "gray" }} xs={3}>lsgMid: </Grid>
              <Grid item xs={9}>{ submission.enquireResponse.lsgMid }</Grid>
            </Grid>
          </Box>
          <hr />
        </Box>
      }

      { submission.ipgProvisioning && <Box>
          <Typography sx={{ fontWeight: "bold" }}>IPG Provisioning Status</Typography>
          <Box sx={{ mb: 2 }}>
            {
              submission.ipgProvisioning && Object.keys(submission.ipgProvisioning).map((key) => {
                if (key === "ipgStores") {
                  const arr = submission.ipgProvisioning[key];
                  for (let item of arr) {
                    const { ipgStatus, merchantId, sharedSecret, storeId, terminals } = item;
                    return <Grid container key={key}>
                        <Grid item style={{ color: "gray" }} xs={12}>&nbsp;</Grid>
                        <Grid item style={{ color: "gray" }} xs={3}>IPG Status: </Grid><Grid item xs={9}>{ipgStatus}</Grid>
                        <Grid item style={{ color: "gray" }} xs={3}>Merchant ID: </Grid><Grid item xs={9}>{merchantId}</Grid>
                        <Grid item style={{ color: "gray" }} xs={3}>Store ID: </Grid><Grid item xs={9}>{storeId}</Grid>
                        <Grid item style={{ color: "gray" }} xs={3}>Shared Secret: </Grid><Grid item xs={9}>{sharedSecret}</Grid>
                        <Grid item style={{ color: "gray" }} xs={3}>Terminals: </Grid>
                        <Grid item xs={9}>
                          {
                            terminals.map((terminal, tIndex) => <li key={tIndex}>{ terminal }</li>)
                          }
                        </Grid>
                    </Grid>
                  }
                }
                return <Grid container key={key}>
                  <Grid item xs={3} sx={{ color: "gray" }}>{ key }:</Grid>
                  <Grid item xs={9}>{ submission.creditApproval[key] }</Grid>
                </Grid>
              })
            }
          </Box>
          <hr />
        </Box>
      }

      { submission.creditApproval && <Box sx={{ my: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Credit Approval Status</Typography>
          <Box sx={{ mb: 2 }}>
          {
            submission.creditApproval && Object.keys(submission.creditApproval).map((key) => {
              if (key === "sgarray") {
                const arr = submission.creditApproval[key];
                for (let item of arr) {
                  const { locationNo, merchantId, subgroupmId, submerchantId, terminals } = item;
                  return <Grid container key={key}>
                      <Grid item style={{ color: "gray" }} xs={12}>&nbsp;</Grid>
                      <Grid item style={{ color: "gray" }} xs={3}>Location No: </Grid><Grid item xs={9}>{locationNo}</Grid>
                      <Grid item style={{ color: "gray" }} xs={3}>Merchant ID: </Grid><Grid item xs={9}>{merchantId}</Grid>
                      <Grid item style={{ color: "gray" }} xs={3}>Subgroup MID: </Grid><Grid item xs={9}>{subgroupmId}</Grid>
                      <Grid item style={{ color: "gray" }} xs={3}>Submerchant ID: </Grid><Grid item xs={9}>{submerchantId}</Grid>
                      <Grid item style={{ color: "gray" }} xs={3}>Terminals: </Grid>
                      <Grid item xs={9}>
                        {
                          terminals.map((terminal, tIndex) => <li key={tIndex}>{ terminal }</li>)
                        }
                      </Grid>
                  </Grid>
                }
              }
              return <Grid container key={key}>
                <Grid item xs={3} sx={{ color: "gray" }}>{ key }:</Grid>
                <Grid item xs={9}>{ submission.creditApproval[key] }</Grid>
              </Grid>
            })
          }
          </Box>

          <hr />
          
        </Box>
      }

      <FiservDisplay 
        name="Merchant Details"
        submission={submission.merchantDetails}
      />
      <FiservDisplay 
        name="Trading Locations"
        submission={submission.tradingLocations}
        type="Location"
      />
      <FiservDisplay 
        name="Principal Owner Details"
        submission={submission.principalDetails}
        type="Principal Owner"
      />
      <FiservDisplay 
        name="UBO Details"
        submission={submission.uboDetails}
        type="UBO"
      />
      <FiservDisplay 
        name="Business Summary"
        submission={submission.businessSummary}
      />
    </Box>
  </Modal>
}

export default ShowApplication;