import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  palette: {
    background: {
      default: "#f5f6fa",
    },
    primary: {
      main: "#530000",
      light: "#7e0000"
    },
    green: {
      main: "#3FA796",
      light: "#f0fcfa"
    },
    yellow: {
      main: "#f5b54c"
    },
    red: {
      main: "#b81148"
    }
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#000",
          textDecoration: "none"
        }
      }
    }
  },
  typography: {
    "fontFamily": 'DM Sans',
  }
});

export default defaultTheme;