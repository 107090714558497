import Typography from "@mui/material/Typography";

const Step3 = () => {
  return <>
    <Typography>Fees</Typography>
    <Typography>Setup Fee</Typography>
    <Typography>Annual Fee</Typography>
    <Typography>Merchant Discount Rate</Typography>
    <Typography>Transaction Fee</Typography>
    <Typography>Refund Fee</Typography>
    <Typography>Chargeback Fee</Typography>
    <Typography>CB-Refund Fee</Typography>
    <Typography>Representment Fee</Typography>
  </>
}

export default Step3;