import { useState } from "react";
import { TraxxContainer, Title, ViewInstructions } from "components";
import { GenerateReportDialog } from "./components";

const FinanceViewInstructions = () => {

  const [showDialog, setShowDialog] = useState(false);
  const [id, setId] = useState();

  const handleGenerate = (id) => {
    setShowDialog(true);
    setId(id);
  }

  return <>
    <Title text="Generate Remittance Form" />
    <TraxxContainer component={<ViewInstructions department="finance" handleGenerate={handleGenerate} />} />
    <GenerateReportDialog open={showDialog} handleClose={() => setShowDialog(false)} id={id} />
  </>
}

export default FinanceViewInstructions;