import React, { useState, useMemo, useCallback, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueSnackbar } from 'notistack';
import { API } from 'services';

import useSubmitData from 'hooks/useSubmitData';
import useFetch from 'hooks/useFetch';

const defaultData = {
  businessName: "",
  legalName: "",
  selectedMerchant: {}
}

const CreateURN = ({ handleChoice }) => {
  const { data: merchants, loading: merchantsLoading, error: merchantsError, fetchData: fetchMerchants } = useFetch();
  const { submitData, submitLoading, submitError } = useSubmitData();
  const [data, setData] = useState(defaultData);

  useEffect(() => {
    fetchMerchants(API.Others.FiservOnboarding.fetch_all_merchants)
  }, []);

  useEffect(() => {
    if (merchantsError) enqueueSnackbar('merchants list unable to load', { variant: 'error', autoHideDuration: 3000 })
  }, [merchantsError]);

  const merchantOptions = useMemo(() => {
    if (merchants) {
      const completedMerchants = merchants.filter(item => item.current_status === 'completed');
      return completedMerchants.map(merchant => ({ ...JSON.parse(merchant.enquiry_info) }));
      // return merchants.map(merchant => ({ ...JSON.parse(merchant.enquiry_info) }));
    }
    return [];
  }, [merchants]);

  const handleMerchantChange = (event, newValue) => {
    if (newValue) {
      setData({
        businessName: newValue.companyName,
        legalName: newValue.companyName,
        selectedMerchant: newValue
      })
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    await submitData(API.Others.FiservOnboarding.create_urn, data);
    if (!submitError) {
      setData(defaultData);
      handleChoice(0);
    }
  }
  
  return <>
    <Box>
      <Autocomplete
        disablePortal
        value={data.selectedMerchant}
        options={merchantOptions}
        getOptionLabel={(option) => option?.companyName || ""}
        isOptionEqualToValue={(option, value) => option.companyName === value.companyName}
        onChange={handleMerchantChange}
        fullWidth
        renderInput={(params) => <TextField {...params} label={ merchantsLoading ? "Loading..." : "Select Merchant"} name="merchant" />}
      />
    </Box>

    <Box sx={{ my: 6 }}>
      <TextField 
        required={true}
        fullWidth
        label="Business Name"
        value={data.businessName}
        name="businessName"
        onChange={handleChange}
      />
      <TextField 
        required={true}
        fullWidth
        label="Legal Name"
        value={data.legalName}
        name="legalName"
        sx={{ mt: 2 }}
        onChange={handleChange}
      />
    </Box>

    <Button variant="contained" fullWidth onClick={handleSubmit}>
      {submitLoading ? <CircularProgress size="1.3rem" sx={{ color: 'white' }}/> : 'Create URN'}
    </Button>
  </>
};

export default CreateURN;