import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import merchantDetailsFields from 'utils/fiserv/merchantDetails';
import { TraxxField } from 'components';

const defaultData = {
  businessName: "",
  legalName: "",
  businessType: "Private Limited",
  registrationNo: "",
  dateIncorporated: "",
  websiteURL: "",
  communicationEmail: "",
  addressLine: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  state: "",
  pincode: "",
  country: "Singapore",
  phone1: "",
  phone2: "",
  contactName: "",
  email: "",
  acceptanceType: "ECOM",
  fundingType: "SGD",
  taxidType: "UEN",
  taxidValue: "",
  merchantSegment: "SMB"
}

const Step1 = ({ merchant, handleNext }) => {

  const [ merchantDetails, setMerchantDetails ] = useState(defaultData);

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setMerchantDetails(prev => ({...prev, [name]: value}))
  }
  
  useEffect(() => {
    if (!merchant) return;
    if (merchant.merchantDetails) {
      setMerchantDetails({
        ...JSON.parse(merchant.merchantDetails),
      })
    } else if (merchant.selectedMerchant) {
      const info = JSON.parse(merchant.selectedMerchant);
      setMerchantDetails({
        ...defaultData,
        businessName: info.companyName,
        legalName: info.companyName,
        registrationNo: info.regNum,
        websiteURL: info.website,
        communicationEmail: info.email,
        addressLine: info.address,
        phone1: info.contactNum,
        contactName: info.firstName + " " + info.lastName,
        email: info.email,
        country: info.country,
        city: info.country === "Singapore" ? "Singapore" : "",
        state: info.country === "Singapore" ? "Singapore" : ""
      })
    }
  }, [merchant])

  return <>
  <Grid container spacing={2}>

    <TraxxField 
      arr={merchantDetailsFields}
      data={merchantDetails}
      handleChange={handleChange}
    />

    <Grid item xs={12} sx={{ mt: 3 }}>
      <Button variant="outlined" fullWidth onClick={() => handleNext(merchantDetails, "merchantDetails")}>
        Save
      </Button>
    </Grid>

  </Grid>
  </>
}

export default Step1;