import React from "react";
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Title } from "components";
import { countries } from "utils";
import { Typography } from "@mui/material";
import { API } from "services";
import { riskScore } from "../texts/RiskScore";

const headers = [
  "Risk Level",
  "Risk Score",
  "No. of clients in Black List",
  "No. of clients in Gray List",
  "Others",
  "Total No. of clients",
  "Score"
]

const defaultSum = {
  low: { black: 0, gray: 0, others: 0 },
  medL: { black: 0, gray: 0, others: 0 },
  medH: { black: 0, gray: 0, others: 0 },
  high: { black: 0, gray: 0, others: 0 }
}

const defaultTotal = { low: 0, medL: 0, medH: 0, high: 0 }

const defaultScore = { low: 0, medL: 0, medH: 0, high: 0 }

const CountryStats = ({ riskList }) => {

  const [summation, setSummation] = useState(defaultSum);
  const [total, setTotal] = useState(defaultTotal);
  const [totalScore, setTotalScore] = useState(defaultScore);
  const [average, setAverage] = useState(0);

  useEffect(() => {
    getSum();
  }, [riskList])

  const getSum = () => {

    let sum = {
      low: { black: 0, gray: 0, others: 0 },
      medL: { black: 0, gray: 0, others: 0 },
      medH: { black: 0, gray: 0, others: 0 },
      high: { black: 0, gray: 0, others: 0 }
    }

    const low = calculate(riskList.low, sum.low);
    const medL = calculate(riskList.medL, sum.medL);
    const medH = calculate(riskList.medH, sum.medH);
    const high = calculate(riskList.high, sum.high);
    
    setSummation({ low, medL, medH, high });

    const low_total = low.black + low.gray + low.others;
    const medL_total = medL.black + medL.gray + medL.others;
    const medH_total = medH.black + medH.gray + medH.others;
    const high_total = high.black + high.gray + high.others;

    setTotal({
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total,
    });

    setTotalScore({
      low: low_total*1,
      medL: medL_total*2,
      medH: medH_total*3,
      high: high_total*4,
    });

    const totalScore = low_total*1 + medL_total*2 + medH_total*3 + high_total*4;
    const totalSum = low_total + medL_total + medH_total + high_total;

    let averageSum = 0
    if (totalSum !== 0) {
      averageSum = ((totalScore)/(totalSum)).toFixed(2);
    }
    
    setAverage(averageSum);
  }

  const calculate = (arr, sum) => {
    arr.forEach((item, index) => {
      const { countryType } = item;
      if (countryType === "gray") sum.gray++
      else if (countryType === "black") sum.black++
      else sum.others++
    })
    return sum;
  }

  return <>
    <Title text="Client-Country Risk Scoring" />
    <TableContainer>
      <Table size="small">
        <TableHead>
        <TableRow>
          { headers.map((item, index) => <TableCell align={index === 0 ? 'left' : 'center'} key={index}><b>{ item }</b></TableCell>) }
        </TableRow>
        </TableHead>
        <TableBody>
          { riskScore.map((item, index) => {
            const { title, score, id } = item;
            return <TableRow key={index}>
              <TableCell>{ title }</TableCell>
              <TableCell align='center'>{ score }</TableCell>
              <TableCell align='center'>{ summation[id].black }</TableCell>
              <TableCell align='center'>{ summation[id].gray }</TableCell>
              <TableCell align='center'>{ summation[id].others }</TableCell>
              <TableCell align='center'>{ total[id] }</TableCell>
              <TableCell align='center'>{ totalScore[id] }</TableCell>
            </TableRow>
          })}
          <TableRow>
            <TableCell><b>Total</b></TableCell>
            <TableCell></TableCell>
            <TableCell align='center'><b>{ summation.low.black + summation.medL.black + summation.medH.black + summation.high.black }</b></TableCell>
            <TableCell align='center'><b>{ summation.low.gray + summation.medL.gray + summation.medH.gray + summation.high.gray }</b></TableCell>
            <TableCell align='center'><b>{ summation.low.others + summation.medL.others + summation.medH.others + summation.high.others }</b></TableCell>
            <TableCell align='center'><b>{ total.low + total.medL + total.medH + total.high }</b></TableCell>
            <TableCell align='center'><b>{ totalScore.low + totalScore.medL + totalScore.medH + totalScore.high }</b></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Typography variant="subtitle2" sx={{ mt: 2, ml: 2, color: 'gray' }}>
      Average Score: <b>{ average }</b>
    </Typography>
  </>
}

export default CountryStats;