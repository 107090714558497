import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PaidIcon from '@mui/icons-material/Paid';
import WarningIcon from '@mui/icons-material/Warning';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ArticleIcon from '@mui/icons-material/Article';
import StoreIcon from '@mui/icons-material/Store';
import RocketIcon from '@mui/icons-material/Rocket';


const navItems = [
  {
    title: '',
    access: 'all',
    items: [
      {
        icon: <DashboardIcon />,
        text: 'Dashboard',
        nav: '/',
      }
    ]
  },
  // {
  //   title: 'Admin',
  //   access: 'adm',
  //   items: [
  //     {
  //       icon: <AdminPanelSettingsIcon />,
  //       text: 'Manage Users',
  //       nav: '/admin/users',
  //     }
  //   ]
  // },
  {
    title: 'Operations',
    access: 'ops',
    items: [
      {
        icon: <ArticleIcon />,
        text: 'Merchant Agreement',
        nav: '/operations/merchant-agreement'
      },
      // {
      //   icon: <EngineeringIcon />,
      //   text: 'Settlement',
      //   nav: '/operations/settlement'
      // },
      // {
      //   icon: <StoreIcon />,
      //   text: 'Settlement Report',
      //   nav: '/operations/settlement'
      // },
    ]
  },
  {
    title: 'Finance',
    access: 'fin',
    items: [
      {
        icon: <PaidIcon />,
        text: 'Standing Instructions',
        nav: '/finance/standing-instructions/'
      }
    ]
  },
  {
    title: 'Compliance',
    access: 'com',
    items: [
      {
        icon: <WarningIcon />,
        text: 'Risk Assessment',
        nav: '/compliance/risk-assessment'
      },
      {
        icon: <FingerprintIcon />,
        text: 'KYC',
        nav: '/compliance/kyc'
      }
    ]
  },
  // {
  //   title: 'Sales',
  //   access: 'sal',
  //   items: [
  //     {
  //       icon: <AutoGraphIcon />,
  //       text: 'Something',
  //       nav: '/sales/'
  //     }
  //   ]
  // },
  // {
  //   title: 'Others',
  //   access: 'fiserv',
  //   items: [
  //     {
  //       icon: <RocketIcon />,
  //       text: 'Fiserv Onboarding',
  //       nav: '/others/fiserv'
  //     }
  //   ]
  // },
  {
    title: 'Fiserv',
    access: 'fiserv',
    items: [
      {
        icon: <AutoGraphIcon />,
        text: 'Fiserv Onboarding',
        nav: '/fiserv'
      }
    ]
  }
]

export default navItems;