import { Title, TraxxContainer, ViewInstructions } from 'components';
import { API } from 'services';
import { enqueueSnackbar } from 'notistack';
import { ConfirmDialog, LogsDialog } from './components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { TraxxButton } from 'components';

const STYLES = {
  "viewLogsBtn": {
    position: "absolute",
    right: '1.5rem'
  }
}

const KYC = () => {

  const user = useSelector(state => state.auth.userInfo);

  const [change, setChange] = useState(false);
  const [data, setData] = useState();
  const [instructions, setInstructions] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [showLogsDialog, setShowLogsDialog] = useState(false);

  const handleAction = async (item, status) => {
    setData({ 
      instrId: item.instrId, 
      kycStatus: status 
    });
    setInstructions(item);
    setShowDialog(true);
  }

  const submitForm = async () => {
    const result = await API.Compliance.Kyc.update_instructions(data, user, instructions);
    if (result) {
      enqueueSnackbar(`success`, {
        variant: 'success',
        autoHideDuration: 3000
      })
      setChange(!change);
      setShowDialog(false);
    } else {
      enqueueSnackbar("error performing action", {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  return <>
    <TraxxButton onClick={() => setShowLogsDialog(true)} text="View Logs" style={ STYLES.viewLogsBtn } />
    <Title text="KYC - Standing Instructions Form" />
    <LogsDialog open={showLogsDialog} setOpen={setShowLogsDialog} />
    <ConfirmDialog submitForm={submitForm} showDialog={showDialog} handleClose={() => setShowDialog(false)} data={data} />
    <TraxxContainer component={<ViewInstructions department="compliance" handleAction={handleAction} change={change} />} />
  </>
}

export default KYC;