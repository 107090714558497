import { Outlet, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';

import { 
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Link as LinkBase
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { constants } from 'utils';
import { navItems } from 'routes';
import { DrawerHeader, AppBar, Drawer } from './styles';
import LogoutDialog from './LogoutDialog';

import { useSelector } from "react-redux";
import { DashboardGuard } from "components";

const DashboardLayout = () => {

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);

  const self = useSelector((state) => state.auth.userInfo);

  // useEffect(() => {
  //   if (Object.keys(self).length !== 0) console.log('self: ', self);
  // }, [self])

  const STYLES = {
    "box": {
      display: 'flex',
      // [theme.breakpoints.down('sm')]: {
      //   display: 'none',
      // }
    },
    "toolbar": {
      display: 'flex',
      justifyContent: 'space-between'
    },
    "toolbarBox": {
      display: 'flex',
      alignItems: 'center'
    },
    "btn": {
      marginRight: 5,
      ...(open && { display: 'none' }),
    },
    "navSection": { 
      my: 3 
    },
    "headerText": {
      opacity: open ? 1 : 0, 
      color: 'grey', 
      mx: '15px'
    },
    "listItem": {
      display: 'block'
    },
    "listItemBtn": {
      minHeight: 48,
      justifyContent: open ? 'initial' : 'center',
    },
    "listItemText": { 
      opacity: open ? 1 : 0,
      ml: 1
    },
    "page": { 
      flexGrow: 1, 
      p: 3,
      [theme.breakpoints.down('sm')]: {
        width: '80vw',
      }
    },
    "activeNav": {
      color: theme.palette.primary.light
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={ STYLES.box }>
      <CssBaseline />

      <DashboardGuard />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={ STYLES.toolbar }>
            <Box sx={ STYLES.toolbarBox }>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={ STYLES.btn }
              >
                <MenuIcon />
              </IconButton>
              
              <Typography variant="h6" noWrap>
                { constants.WEBSITE_TITLE }
              </Typography>
            </Box>
            <Box sx={ STYLES.toolbarBox } >
              <Typography color="inherit" sx={{ mr: 1 }}>{ self && self.name }</Typography>
              <IconButton color="inherit" onClick={() => setOpenLogout(true)}><PowerSettingsNewIcon /></IconButton>
            </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {
            self && navItems.map((item, index) => {
              const { title, access, items } = item;
              if (self.access?.includes(access) || access === 'all') return (
              <Box sx={ STYLES.navSection } key={index}>
                <Typography sx={ STYLES.headerText } variant="subtitle2">{ title.toUpperCase() }</Typography>
                { items.map((navItem, index) => {
                  const { icon, text, nav } = navItem
                  return (
                    <LinkBase component={NavLink} key={index} to={nav} style={({ isActive }) => isActive ? STYLES.activeNav : {} } onClick={handleDrawerClose}>
                      <ListItem disablePadding sx={ STYLES.listItem }>
                        <ListItemButton sx={ STYLES.listItemBtn }>
                          { icon }
                          <ListItemText primary={text} sx={ STYLES.listItemText } />
                        </ListItemButton>
                      </ListItem>
                    </LinkBase>
                  )
                })}
              </Box>
              )
            })
          }
        </List>
      </Drawer>
      <Box component="main" sx={ STYLES.page }>
        <DrawerHeader />
        { openLogout && <LogoutDialog open={openLogout} handleClose={() => setOpenLogout(false)}/>}
        <Outlet />
      </Box>

      
    </Box>
  );
}

export default DashboardLayout;