import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { API } from 'services';

export const userLogin = createAsyncThunk(
  'auth/login',
  async (body, { rejectWithValue }) => {
    try {
      // const { data } = await axios.post(backendURL + 'user/login', body)
      const data = await API.Access.Login(body)
      if (!data) {
        enqueueSnackbar('Invalid credentials', {
          variant: 'error',
          autoHideDuration: 3000
        })
        return rejectWithValue()
      }
      localStorage.setItem('d3:userToken', data.userId)
      enqueueSnackbar(`Welcome ${data.name}!`, {
        variant: 'success',
        autoHideDuration: 3000
      })
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const userRetrieve = createAsyncThunk(
  'auth/update',
  async (body, { rejectWithValue }) => {
    try {
      const data = await API.Access.Retrieve(body);
      if (!data) return rejectWithValue();
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)