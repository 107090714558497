import { useState, useEffect, useMemo } from 'react';
import { API } from 'services';
import { enqueueSnackbar } from 'notistack';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';

import useFetch from 'hooks/useFetch';
import useSubmitData from 'hooks/useSubmitData';

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

const STYLES = {
  topBox: {
    mb: 2
  },
  leftBox: {
    backgroundColor: 'primary.main',
    color: 'white',
    borderRadius: '6px',
    padding: 3,
    height: '100%'
  },
  rightBox: {
    padding: 3
  },
  stepsBtn: {
    my: 4,
    cursor: 'pointer',
    color: '#8f5353'
  },
  selectedStepsBtn: {
    my: 4,
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  stepsNo: {
    color: 'white',
    border: '1px solid #8f5353',
    height: '1.5rem',
    width: '1.5rem',
    fontSize: '1rem',
    color: '#8f5353'
  },
  selectedStepsNo: {
    color: 'white',
    border: '1px solid white',
    height: '1.5rem',
    width: '1.5rem',
    fontSize: '1rem'
  }
}

const choices = [
  'Merchant Details',
  'Trading Locations',
  'Principal Details',
  'UBO Details',
  'Business Summary',
  'Submit'
]

const SubmitMID = () => {
  const [choice, setChoice] = useState(1);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const { data: merchants, loading: merchantsLoading, error: merchantsError, fetchData: fetchMerchants } = useFetch();
  const { submitData, submitLoading, submitError } = useSubmitData();

  useEffect(() => {
    fetchMerchants(API.Others.FiservOnboarding.fetch_applications)
  }, [])

  const merchantOptions = useMemo(() => {
    if (merchants) {
      const sortedMerchants = merchants.sort((a,b) => b.fiservId - a.fiservId);
      return sortedMerchants.filter((item) => (item.status === "URN_CREATED" || item.status?.toLowerCase() === "created") && item.appURN)
    }
    return [];
  })

  const handleMerchantChange = (something, merchant) => {
    if (!merchant) return;
    setSelectedMerchant(merchant);
  }

  const handleNext = async (data, dataType) => {
    if (!selectedMerchant || !selectedMerchant.appURN) {
      enqueueSnackbar("Please select merchant", { variant: 'error', autoHideDuration: 3000 })
      return;
    }
    const postData = ({
      appURN: selectedMerchant.appURN,
      [dataType]: JSON.stringify(data)
    });
    await submitData(API.Others.FiservOnboarding.save_data, postData);
  }

  const stepComponents = {
    2: Step2,
    3: Step3,
    4: Step4,
    5: Step5,
    6: Step6
  };
  
  const StepComponent = stepComponents[choice] || Step1;

  return <>

    <Box sx={ STYLES.topBox }>
      <Autocomplete
        disablePortal
        value={selectedMerchant}
        options={merchantOptions}
        getOptionLabel={(option) => option ? `[${option.appURN}] ${option.businessName}` : ""}
        isOptionEqualToValue={(option, value) => option.appURN === value.appURN}
        onChange={handleMerchantChange}
        fullWidth
        renderInput={(params) => <TextField {...params} label={ merchantsLoading ? "Loading..." : "Select Merchant"} name="merchant" />}
      />
    </Box>

    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box sx={ STYLES.leftBox }>
          <Typography variant="h5"><b>Step {choice}</b></Typography>
          { choices.map((item, index) => {
              return <Box key={index} onClick={() => setChoice(index + 1)} sx={ choice === index+1 ? STYLES.selectedStepsBtn : STYLES.stepsBtn }>
                <Grid container spacing={2}>
                  <Grid item>
                    <IconButton sx={ choice === index+1 ? STYLES.selectedStepsNo : STYLES.stepsNo }>{index + 1}</IconButton>
                  </Grid>
                  <Grid item>
                    {item}
                  </Grid>
                </Grid>
              </Box>
            })
          }
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box sx={ STYLES.rightBox }>
          <StepComponent merchant={selectedMerchant} handleNext={handleNext} />
        </Box>
      </Grid>
    </Grid>

  </>
}

export default SubmitMID;