import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const TraxxField = ({ arr, data, handleChange }) => {
  
  return <>

  { arr.map((field, index) => {
      if (field.type === "select") {
        return <Grid key={field.prop} item {...field.grid}>
        <FormControl fullWidth required size="small">
          <InputLabel id={field.prop}>{field.label}</InputLabel>
          <Select 
            labelId={field.prop}
            value={data[field.prop]}
            name={field.prop}
            label={field.label}
            onChange={handleChange}
          >
            {field.options.map(opt => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label ? opt.label : opt.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      }
      else {
        return <Grid key={field.prop} item {...field.grid}>
          <TextField
            required={field.required}
            fullWidth
            label={field.label}
            value={data[field.prop]}
            size="small"
            name={field.prop}
            placeholder={field.placeholder}
            type={field.fieldType}
            onChange={handleChange}
          />
        </Grid>
      }
    })}
  
  </>
}

export default TraxxField;