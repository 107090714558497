import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Title } from 'components';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { FormLabel, TextField } from '@mui/material';

const Section3 = ({ handleChange, result }) => {

  const theme = useTheme();

  return (
    <>
    <Box display="flex" justifyContent="center" sx={{ mt: 5, pb: 1 }}>
      <Title text="Risk Evaluation and CDD Measures Application" color={ theme.palette.primary.main } />
    </Box>
    <Divider />

    <Typography sx={{ mt: 2 , textAlign: "center"}}></Typography>
    <Box sx={{ m: 3, display: "flex", justifyContent: "center" }}>
    <Box sx={{ width: "70%" }}>
      <Grid container spacing={2}>
        <Grid item xs={5} display="flex" textAlign="right" justifyContent="flex-end" alignItems="center" sx={{ pr: 2 }}><FormLabel><b>Section 1</b>: Overall Risk Rating of Client</FormLabel></Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center"}}>
          { result && <Typography>{ result === 'high' ? 'High' : result === 'medL' ? "Medium Low" : result === 'medH' ? "Medium High" : "Low" }</Typography> }
        </Grid>

        <Grid item xs={5} display="flex" textAlign="right" justifyContent="flex-end" alignItems="center" sx={{ pr: 2 }}><FormLabel><b>Section 2</b>: Justifications</FormLabel></Grid>
        <Grid item xs={6}><TextField onBlur={(ev) => handleChange(ev, "Q3", 1)} fullWidth></TextField></Grid>

        <Grid item xs={5} display="flex" textAlign="right" justifyContent="flex-end" alignItems="center" sx={{ pr: 2 }}><FormLabel><b>Section 3</b>:  Final Risk Rating – Adjusted</FormLabel></Grid>
        <Grid item xs={6}>
        <Select 
          // value={data.Q3[2]}
          defaultValue=""
          onBlur={(ev) => handleChange(ev, "Q3", 2)}
          fullWidth
        >
          <MenuItem value="">NA</MenuItem>
          <MenuItem value="low">Low</MenuItem>
          <MenuItem value="medL">Low/Medium</MenuItem>
          <MenuItem value="medH">Medium/High</MenuItem>
          <MenuItem value="high">High</MenuItem> 
        </Select></Grid>

        <Grid item xs={5} display="flex" textAlign="right" justifyContent="flex-end" alignItems="center" sx={{ pr: 2 }}><FormLabel><b>Section 4</b>:  Recommendation - Business relationship with merchant</FormLabel></Grid>
        <Grid item xs={6}>
        <Select
          // value={data.Q3[3]}
          defaultValue=""
          onBlur={(ev) => handleChange(ev, "Q3", 3)}
          fullWidth
        >
          <MenuItem value="establish">Establish</MenuItem>
          <MenuItem value="maintain">Maintain</MenuItem>
          <MenuItem value="decline">Decline</MenuItem> 
          <MenuItem value="terminate">Terminate</MenuItem>
        </Select></Grid>
      </Grid>
    </Box>
    </Box>
    </>
  )
}

export default Section3;