import { useEffect, useState, useCallback } from 'react';
import { Title, TraxxContainer, TraxxButton } from "components";
import { useLocation } from 'react-router-dom';
import { API } from "services";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import CreateURN from "./CreateURN";
import ViewApplications from './ViewApplications';
import SubmitMID from "./SubmitMID";

// import CreateMid from "./CreateMid";
// import ViewMid from "./ViewMid";
// import EditMid from "./EditMid";

const STYLES = {
  "btn": {
    ml: 1,
    backgroundColor: '#b3a1a1'
  },
  "selectedBtn": {
    ml: 1
  }
}

const FiservOnboarding = () => {

  // const navigate = useNavigate();

  const [choice, setChoice] = useState(0);
  // const [editData, setEditData] = useState({});
  // const location = useLocation();  

  // useEffect(() => {
  //   if (location.search) {
  //     setChoice(2);
  //     const searchParams = new URLSearchParams(location.search);
  //     const appURN = searchParams.get('edit') || searchParams.get('addmid') || searchParams.get('resubmit');
  //     get_data(appURN)
  //   }
  // }, [location])

  // const get_data = async (appURN) => {
  //   const application = await API.Others.FiservOnboarding.fetch_by_appURN(appURN);
  //   setEditData(application);
  // }

  const handleChoice = useCallback((index) => {
    setChoice(index);
    // navigate("/fiserv")
  },[]);

  const choices = [
    {
      key: 0,
      label: "View Applications",
      component: <ViewApplications /> 
    },
    {
      key: 1,
      label: "Create URN",
      component: <CreateURN handleChoice={handleChoice} />
    },
    {
      key: 2,
      label: "Submit New MID",
      component: <SubmitMID handleChoice={handleChoice} />
    },
    // {
    //   key: 3,
    //   label: "Edit MID",
    //   component: <EditMid />
    // }
  ]

  return <>
    {/* <Title text="Fiserv Onboarding" /> */}

    {
      choices.map((item) => {
        return <TraxxButton 
          key={item.key}
          text={item.label} 
          style={choice === item.key ? STYLES.selectedBtn : STYLES.btn } 
          onClick={() => handleChoice(item.key)} 
        />
      })
    }

    <TraxxContainer component={
      <>
        { choices[choice].component }
      </>
    }/>
  </>
}

export default FiservOnboarding;