import { useState, useEffect } from "react";
import { API } from "services";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useFetch from "hooks/useFetch";
import useSubmitData from "hooks/useSubmitData";
import { FiservDisplay } from "components/FiservDisplay";

const Step6 = ({ merchant }) => {

  const [ submission, setSubmission ] = useState();
  const { data, loading, error, fetchData } = useFetch();
  const { submitData, submitLoading, submitError } = useSubmitData();

  useEffect(() => {
    if (merchant) fetchData(() => API.Others.FiservOnboarding.fetch_by_appURN(merchant.appURN));
  }, [merchant])

  useEffect(() => {
    if (data) setSubmission({
      appURN: merchant.appURN,
      merchantDetails: JSON.parse(data.merchantDetails),
      principalDetails: JSON.parse(data.principalDetails),
      tradingLocations: JSON.parse(data.tradingLocations),
      uboDetails: JSON.parse(data.uboDetails),
      businessSummary: JSON.parse(data.businessSummary),
    })
  }, [data])

  const handleSubmit = async () => {
    await submitData(API.Others.FiservOnboarding.submit_new_mid, submission);
  }

  if (!submission) return;
  return <>

    {/* Step 1: Merchant Details */}
    <FiservDisplay name="Merchant Details" submission={submission.merchantDetails} />

    {/* Step 2: Trading Locations */}
    <FiservDisplay name="Trading Locations" submission={submission.tradingLocations} type="Location" />

    {/* Step 3: Principal Details */}
    <FiservDisplay name="Principal Owner Details" submission={submission.principalDetails} type="Principal Owner" />

    {/* Step 4: UBO Details */}
    <FiservDisplay name="UBO Details" submission={submission.uboDetails} type="UBO" />

    {/* Step 5: Business Summary */}
    <FiservDisplay name="Business Summary" submission={submission.businessSummary} />

    <Box sx={{ mt: 4 }}>
      {
        submitError && submitError.message.split(";").map(msg => (
          <Typography sx={{ color: "#e31224", mb: 2 }}>
            { msg }
          </Typography>
        ))
      }
      <Button variant="contained" fullWidth onClick={handleSubmit}>
        {submitLoading ? <CircularProgress size="1.3rem" sx={{ color: 'white' }}/> : 'Submit'}
      </Button>
    </Box>
    
  </>
}

export default Step6;