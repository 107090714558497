import { AuthLayout, DashboardLayout, FormLayout } from 'layout';

import { Login } from 'views/Access';
import { Dashboard } from 'views/Dashboard';
import { RiskAssessment, CreateAssessment, ViewAssessment, Fatf, Ewra, LatestEwra, EditAssessment } from 'views/Compliance/RiskAssessment';
import { StandingInstructions, ViewInstructions } from 'views/Finance/StandingInstructions';
import { MerchantAgreement, ViewAgreement } from 'views/Operations/MerchantAgreement';
import { Settlement } from 'views/Operations/Settlement';
import { CreateInstructions, SuccessForm } from 'views/Forms';
import { KYC } from 'views/Compliance/KYC';
import FiservOnboarding from 'views/Others/FiservOnboarding';
import Fiserv from 'views/Fiserv';

const route = (path, element, { children, exact, access, roles } = {}) => {
  return { path, element, children, exact, access, roles }
};

const routes = [
  route('access', <AuthLayout />, {
    children: [
      route('login', <Login />),
      route('*', <Login />),
    ]
  }),
  route('', <DashboardLayout />, {
    children: [
      route('', <Dashboard />),
      route('/compliance/risk-assessment', <RiskAssessment />, { access: 'com' }),
      route('/compliance/risk-assessment/create', <CreateAssessment />, { access: 'com' }),
      route('/compliance/risk-assessment/list', <ViewAssessment />, { access: 'com' }),
      route('/compliance/risk-assessment/edit', <EditAssessment />, { access: 'com' }),
      route('/compliance/risk-assessment/fatf', <Fatf />, { access: 'com' }),
      route('/compliance/risk-assessment/ewra', <Ewra />, { access: 'com' }),
      route('/compliance/risk-assessment/ewra/latest', <LatestEwra />, { access: 'com' }),
      route('/compliance/kyc', <KYC />, { access: 'com' }),

      route('/finance/standing-instructions', <StandingInstructions />, { access: 'fin' }),

      route('/operations/merchant-agreement', <MerchantAgreement />, { access: 'ops' }),
      route('/operations/merchant-agreement/list', <ViewAgreement />, { access: 'ops' }),
      route('/operations/settlement', <Settlement />, { access: 'ops' }),

      route('/others/fiserv', <FiservOnboarding />, { access: 'fiserv' }),

      route('/fiserv', <Fiserv />, { access: 'fiserv' }),

      route('*', <Dashboard />)
    ]
  }),
  route('forms', <FormLayout />, {
    children: [
      route('standing-instructions', <CreateInstructions />),
      route('success', <SuccessForm />),
      route('*', <CreateInstructions />)
    ],
  })
];

export default routes;