import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem"
import { TableContainer } from "@mui/material";
import { useTheme } from '@mui/material/styles'

const QuestionGrid = ({ data, question, handleChange, qNo, index }) => {

  const { text, type } = question
  const theme = useTheme();

  const STYLES = {
    "griddy": {
      display: 'flex', justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '25px'
      }
    },
    "boxalign": {
      textAlign: "right",
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      }
    },
    "container": {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display:'block', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height:'50px', flexWrap: 'nowrap', overflowY: "hidden", 
      }
    },
    "griditem": {
      [theme.breakpoints.down('sm')]: {
        display: 'flex', justifyContent: 'center'
      }
    },
    "gridquestion": {
      pb: 1,  my: 2, pr: 2, alignItems: 'flex-end', justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        pr: 0, 
      }
    },
    "desktop": {
      display: 'flex', justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    }
  }

  return <>

    { type.match(/notes/) ?
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" sx={{ pb: 1, pr: 2, my: 2 }}>
        <Box align="right">
          <FormLabel>{ text }</FormLabel>
          <div>
            <TextField
              id="outlined-multiline-static"
              label="Input"
              multiline
              rows={2}
              fullWidth
              onBlur={(ev) => handleChange(ev, qNo, index)}
              // value={data[qNo][index]}
            />
          </div>
        </Box>
      </Grid>
    :

    <Grid item xs={12} sm={8} display="flex" alignItems="flex-end" justifyContent="flex-end" sx={STYLES.gridquestion}>
      <Box sx={STYLES.boxalign}>
        <FormLabel>{ text }</FormLabel>
      </Box>
    </Grid>

    }

    { type.match(/check/) &&
    <Grid item xs={4} sx={{ pl: 2 }} display="flex" alignItems="center">
      {
        type.match(/check/) &&
          <RadioGroup
            name="radio-buttons-group"
            onBlur={(ev) => handleChange(ev, qNo, index)}
            // value={data[qNo][index]}
          >
            <Box>
              <FormControlLabel value="1" control={<Radio size="small"/>} label="Yes" />
              <FormControlLabel value="0" control={<Radio size="small"/>} label="No" />
            </Box>
          </RadioGroup>
      }      
    </Grid>
    }

    {
      type.match(/risk/) && 
      <>
      <TableContainer item display="flex" alignItems="center" sx={STYLES.container}>
          <Grid item xs={1} display="flex" alignItems="center" sx = {STYLES.griddy}>
            
            <Select
              // labelId="demo-select-small-label"
              // id="demo-select-small"
              // value="2"
              label="Low"
              onChange={(ev) => handleChange(ev, qNo, index)}
              defaultValue=""
              value={data[qNo][index]}
              sx={{ height: '2rem' }}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="l-yes">Yes</MenuItem>
              <MenuItem value="l-no">No</MenuItem>
              <MenuItem value="l-na">NA</MenuItem>
            </Select>

          </Grid>
            
          <Grid item xs={1} display="flex" alignItems="center" sx = {STYLES.griddy} >
            <Select
              // labelId="demo-select-small-label"
              // id="demo-select-small"
              // value="2"
              label="Low"
              onChange={(ev) => handleChange(ev, qNo, index)}
              defaultValue=""
              value={data[qNo][index]}
              sx={{ height: '2rem' }}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="m-yes">Yes</MenuItem>
              <MenuItem value="m-no">No</MenuItem>
              <MenuItem value="m-na">NA</MenuItem>
            </Select>

          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" sx = {STYLES.griddy}>
            <Select
              // labelId="demo-select-small-label"
              // id="demo-select-small"
              // value="2"
              label="Low"
              onChange={(ev) => handleChange(ev, qNo, index)}
              defaultValue=""
              value={data[qNo][index]}
              sx={{ height: '2rem' }}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="h-yes">Yes</MenuItem>
              <MenuItem value="h-no">No</MenuItem>
              <MenuItem value="h-na">NA</MenuItem>
            </Select>
          </Grid>
          </TableContainer>
          <Grid item xs={1} display="flex" alignItems="center" sx={STYLES.desktop}>
            
            <Select
              // labelId="demo-select-small-label"
              // id="demo-select-small"
              // value="2"
              label="Low"
              onChange={(ev) => handleChange(ev, qNo, index)}
              defaultValue=""
              value={data[qNo][index]}
              sx={{ height: '2rem' }}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="l-yes">Yes</MenuItem>
              <MenuItem value="l-no">No</MenuItem>
              <MenuItem value="l-na">NA</MenuItem>
            </Select>

          </Grid>
            
          <Grid item xs={1} display="flex" alignItems="center" sx={STYLES.desktop}>
            <Select
              // labelId="demo-select-small-label"
              // id="demo-select-small"
              // value="2"
              label="Low"
              onChange={(ev) => handleChange(ev, qNo, index)}
              defaultValue=""
              value={data[qNo][index]}
              sx={{ height: '2rem' }}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="m-yes">Yes</MenuItem>
              <MenuItem value="m-no">No</MenuItem>
              <MenuItem value="m-na">NA</MenuItem>
            </Select>

          </Grid>
          <Grid item xs={1} display="flex" alignItems="center" sx={STYLES.desktop}>
            <Select
              // labelId="demo-select-small-label"
              // id="demo-select-small"
              // value="2"
              label="Low"
              onChange={(ev) => handleChange(ev, qNo, index)}
              defaultValue=""
              value={data[qNo][index]}
              sx={{ height: '2rem' }}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              <MenuItem value="h-yes">Yes</MenuItem>
              <MenuItem value="h-no">No</MenuItem>
              <MenuItem value="h-na">NA</MenuItem>
            </Select>
          </Grid>
      </>
    }


  </>
};

export default QuestionGrid;