import { API } from 'services';
import { useState, useEffect } from "react";
import { CompanyInfo, FeeStructure, Signatory } from './components';
import { Title, TraxxContainer, TraxxButton } from "components";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { useNavigate } from 'react-router-dom';

const STYLES = {
  "viewLogsBtn": {
    position: "absolute",
    right: '1.5rem'
  }
}

const MerchantAgreement = () => {

  const [merchants, setMerchants] = useState();
  const [selectedMerchant, setSelectedMerchant] = useState('');
  const [data, setData] = useState({});
  const [fee, setFee] = useState({});
  const [signatory, setSignatory] = useState("first");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSignatoryChange = (event) => {
    setSignatory(event.target.value);
  }

  useEffect(() => {
    list_merchants();
  }, [])

  const list_merchants = async () => {
    const merchantArr = await API.Operations.Agreement.fetch_all_merchants({ nolimit: true });
    const riskArr = await API.Compliance.Risk.fetch_all_risk();

    const completedRisk = riskArr.filter(item => item.zohoStatus === 'completed');
    const completedMerchantArr = []
    completedRisk.forEach(item => completedMerchantArr.push(parseInt(item.merchantId)))

    if (merchantArr) {
      const completedMerchants = merchantArr.filter(item => item.current_status === 'completed' && completedMerchantArr.includes(item.id));
      setMerchants(completedMerchants);
    }
  }

  const handleChange = (event, newValue) => {
    setSelectedMerchant(newValue);
    if (newValue === null) {
      setData({})
      return
    }
    const current = merchants.find(item => JSON.parse(item.enquiry_info).companyName === newValue);
    if (current) {
      const { application_id, enquiry_info, id } = current;
      setData({
        id,
        application_id,
        enquiry_info: JSON.parse(enquiry_info)
      })
    }
  }

  const handleInputChange = (event, newValue) => {
    // setInputMerchant(newValue);
    setData({
      ...data,
      enquiry_info: {
        ...data.enquiry_info,
        companyName: newValue
      }
    })
  }

  const handleVariableChange = (event) => {
    if (event.target.name === 'checked') {
      setData({
        ...data,
        checked: event.target.checked
      })
    } else {
      setData({
        ...data,
        enquiry_info: {
          ...data.enquiry_info,
          [event.target.name]: event.target.value
        }
      })
    }
  }

  const handleCountryChange = (event, newValue) => {
    setData({
      ...data,
      enquiry_info: {
        ...data.enquiry_info,
        country: newValue?.label || ''
      }
    })
  }

  const handleFeeChange = (ev) => {
    setFee({
      ...fee,
      [ev.target.name]: ev.target.value
    })
  }

  const handleSubmit = async () => {
    const submitData = {
      data,
      fee,
      signatory
    }
    
    try {
      setLoading(true)
      // console.log('submitData: ', submitData);
      const response = await API.Operations.Agreement.create_agreement(submitData);
      if (response) {
        setSelectedMerchant();
        setData({});
        setFee({});
        setSignatory("first");
        enqueueSnackbar("Form successfully created!", {
          variant: 'success',
          autoHideDuration: 3000
        })
      } else {
        enqueueSnackbar("Error creating form", {
          variant: 'error',
          autoHideDuration: 3000
        })
      }
      setLoading(false);
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  return (
    <>
      <TraxxButton text="View List" onClick={() => navigate('list') } style={ STYLES.viewLogsBtn } />
      <Title text="Merchant Agreement and Declaration Form" />
      
      <TraxxContainer component={
        <CompanyInfo 
          data={data}
          merchants={merchants} 
          selectedMerchant={selectedMerchant}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          handleVariableChange={handleVariableChange}
          handleCountryChange={handleCountryChange}
        />
      }/>

      <TraxxContainer component={
        <FeeStructure fee={fee} handleFeeChange={handleFeeChange} />
      }/>

      <TraxxContainer component={
        <>
          <Signatory signatory={signatory} handleSignatoryChange={handleSignatoryChange} />
        </>
      }/>

      <Button sx={{ my: 3 }} variant="contained" fullWidth onClick={handleSubmit}>
        {loading ? <IconButton><CircularProgress size="1.5rem" sx={{ color: 'white' }}/></IconButton> : 'Submit'}
      </Button>
    </>
  )
};

export default MerchantAgreement;