import { useState, useEffect } from 'react';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Chip from '@mui/material/Chip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Button from '@mui/material/Button';
import { API } from 'services';
import IconButton from '@mui/material/IconButton';

import { Typography, useTheme } from '@mui/material';

const BodyComponent = ({ instructionsList, department, handleGenerate, handleAction }) => {

  // console.log('instructionsList; ', instructionsList);
  const theme = useTheme();

  return (
    <TableBody>
      { instructionsList.map((item, index) => {
          const { instrId, createdOn, companyName, fullName, email, zohoStatus, requestID, kycStatus } = item;
          const formattedDate = moment(createdOn).format('lll');
          return <TableRow key={index}>
            <TableCell>{ index + 1 }</TableCell>
            <TableCell>{ formattedDate }</TableCell>
            <TableCell>{ companyName }</TableCell>
            <TableCell>{ email }</TableCell>
            <TableCell>{ zohoStatus == "completed" ? <Chip color="success" label="Complete"/> : <Chip variant="outlined" color="warning" label="In Progress" />}</TableCell>
            <TableCell>{ requestID ? <IconButton onClick={() => API.Misc.fetch_zoho_doc(requestID)}><PictureAsPdfIcon /></IconButton> : <IconButton disabled><PictureAsPdfIcon /></IconButton> }</TableCell>
            <TableCell>
              {
                zohoStatus == "completed" &&
                (
                  department === 'compliance' ? 
                  (
                    kycStatus === 'accept' ? <Typography sx={{ color: theme.palette.green.main }}><b>ACCEPTED</b></Typography>        
                    :
                    kycStatus === 'decline' ? <Typography sx={{ color: theme.palette.red.main }}><b>DECLINED</b></Typography>
                    :
                    <>
                      <Button variant='outlined' onClick={() => handleAction(item, "accept")} sx={{ mr: 1 }}>Accept</Button>
                      <Button variant='outlined' onClick={() => handleAction(item, "decline")}>Decline</Button>
                    </>
                  )
                  : department === 'finance' ? 
                  (
                    kycStatus === 'accept' ? <Button variant='outlined' onClick={() => handleGenerate(instrId)}>Generate</Button>
                    :
                    kycStatus === 'decline' ? <Typography sx={{ color: theme.palette.red.main }}><b>DECLINED</b></Typography>
                    :
                    <Typography sx={{ color: '#ccc' }}>Awaiting KYC</Typography>
                  )
                  : ''
                )
              }
            </TableCell>
          </TableRow>
        })
      }
    </TableBody>
  )
};

export default BodyComponent;