import { useEffect, useState } from 'react';
import { Title, TraxxContainer, TraxxButton } from "components";
import CreateMid from "./CreateMid";
import ViewMid from "./ViewMid";
import { useLocation } from 'react-router-dom';
import { API } from "services";
import moment from 'moment';
import EditMid from "./EditMid";
import { useNavigate } from "react-router-dom";

const choices = [
  "Submit New MID",
  "View Applications"
]

const STYLES = {
  "btn": {
    ml: 1,
    backgroundColor: '#b3a1a1'
  },
  "selectedBtn": {
    ml: 1
  }
}

// const FiservOnboarding = () => {

//   const navigate = useNavigate();

//   const [choice, setChoice] = useState(1);
//   const [editData, setEditData] = useState({});
//   const location = useLocation();  

//   useEffect(() => {
//     if (location.search) {
//       setChoice(2);
//       const searchParams = new URLSearchParams(location.search);
//       const appURN = searchParams.get('edit') || searchParams.get('addmid') || searchParams.get('resubmit');
//       get_data(appURN)
//     }
//   }, [location])

//   const get_data = async (appURN) => {
//     const application = await API.Others.FiservOnboarding.fetch_by_appURN(appURN);
//     setEditData(application);
//   }

//   const handleChoice = (index) => {
//     setChoice(index);
//     navigate("/others/fiserv")
//   }

//   return <>
//     {/* <Title text="Fiserv Onboarding" /> */}

//     {
//       choices.map((item, index) => {
//         return <TraxxButton 
//           key={index}
//           text={item} 
//           style={choice === index ? STYLES.selectedBtn : STYLES.btn } 
//           onClick={() => handleChoice(index)} 
//         />
//       })
//     }

//     <TraxxContainer component={
//       <>
//         {
//           choice === 0 ? <CreateMid setTopChoice={setChoice} /> 
//           : choice === 1 ? <ViewMid /> 
//           : choice === 2 ? <EditMid data={editData} />
//           : <></>
//         }
//       </>
//     }/>
//   </>
// }

const FiservOnboarding = () => {
  return <></>
}

export default FiservOnboarding;