import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import { AssignMID, CreateMerchant } from "./components/Merchant";
import IconButton from '@mui/material/IconButton';

const choices = [
  'Create Merchant',
  'Assign MID'
]

const STYLES = {
  leftBox: {
    backgroundColor: 'primary.main',
    color: 'white',
    borderRadius: '6px',
    padding: 3,
    height: '100%'
  },
  rightBox: {
    padding: 3
  },
  stepsBtn: {
    my: 4,
    cursor: 'pointer'
  },
  stepsNo: {
    color: 'white',
    border: '1px solid white',
    height: '1.5rem',
    width: '1.5rem',
    fontSize: '1rem'
  }
}

const Merchant = () => {
  const [choice, setChoice] = useState(1);

  return <>
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box sx={ STYLES.leftBox }>
          <Typography variant="h5"><b>{choices[choice-1]}</b></Typography>
          { choices.map((item, index) => {
              return <Box key={index} onClick={() => setChoice(index + 1)} sx={ STYLES.stepsBtn }>
                <Grid container spacing={2}>
                  <Grid item>
                    <IconButton sx={ STYLES.stepsNo }>{index + 1}</IconButton>
                  </Grid>
                  <Grid item>
                    {item}
                  </Grid>
                </Grid>
              </Box>
            })
          }
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box sx={ STYLES.rightBox }>
        {
          choice === 1 ? <CreateMerchant />
          : <AssignMID />
        }
        </Box>
      </Grid>
    </Grid>
  </>
}

export default Merchant;