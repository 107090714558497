import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';

import { Title } from 'components';
import { Q2P1, Q2P2, Q2P3, Q2P4 } from '../texts'
import { Divider, TableContainer } from '@mui/material';
import QuestionGrid from './QuestionGrid'

const Section2 = ({ data, handleChange, count }) => {

  const theme = useTheme();

  const STYLES = {
    "griddy": {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      }
    },
  }

  return <>
    <Box display="flex" justifyContent="center" sx={{ pb: 1 }}>
      <Title text="Merchant AML / CFT Risk Assessment Questionnaire and Review – Level 2" color={ theme.palette.primary.main } />
    </Box>
    <Divider />

    
      <Typography sx={{ mt: 2 , textAlign: "center"}}>2.1 Merchant Background</Typography>
      
      <Box sx={{  display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: "70%" }}>
        
        <Grid container spacing={0}>
            <Grid item xs={8} display="flex" justifyContent="center" sx={{ }}></Grid>
            <Grid item sm={3} xs={12} sx={{ color: theme.palette.primary.main, display: 'flex', justifyContent: 'center', mt: 2 }}><Typography sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>Risk Level</Typography></Grid>
                      
            <Grid item sm={8} xs={0} display="flex" justifyContent="flex-end" sx={{ }}></Grid>
            <Grid item sm={1} xs={4} sx={{ color: theme.palette.green.main, display: 'flex', justifyContent: 'center', }}>Low</Grid>
            <Grid item sm={1} xs={4} sx={{ color: theme.palette.yellow.main, display: 'flex', justifyContent: 'center',  }}>Medium</Grid>
            <Grid item sm={1} xs={4} sx={{ color: theme.palette.red.main, display: 'flex', justifyContent: 'center',  }}>High</Grid>

            {
              Q2P1.map((question, index) => {
                return <Grid container spacing={2} key={index} >
                  <QuestionGrid data={data} question={question} qNo="Q2P1" index={index} handleChange={handleChange} />
                </Grid>
              })
            }
          
        </Grid>
      </Box>
      </Box>
      
    

    <Typography sx={{ mt: 2 , textAlign: "center"}}>2.2 Merchant Location</Typography>
    <Typography sx={{ textAlign: "center"}}>Applicable aspects to assess merchant location: Natural owners’ nationality; place of business incorporation; registered business address; location of operation.</Typography>
    <Box sx={{ m: 3, display: "flex", justifyContent: "center" }}>
    <Box sx={{ width: "70%" }}>
      <Grid container spacing={0}>
      <Grid item sm={8} xs={0} display="flex" justifyContent="flex-end" sx={{ }}></Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.green.main, display: 'flex', justifyContent: 'center', }}>Low</Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.yellow.main, display: 'flex', justifyContent: 'center',  }}>Medium</Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.red.main, display: 'flex', justifyContent: 'center',  }}>High</Grid>
        {
          Q2P2.map((question, index) => {
            return <Grid container spacing={2} key={index}>
              <QuestionGrid data={data} question={question} qNo="Q2P2" index={index} handleChange={handleChange} />
            </Grid>
          })
        }
      </Grid>
    </Box>
    </Box>

    <Typography sx={{ mt: 2 , textAlign: "center"}}>2.3 Merchant Product and Transaction History</Typography>
    <Box sx={{ m: 3, display: "flex", justifyContent: "center" }}>
    <Box sx={{ width: "70%" }}>
      <Grid container spacing={0}>
      <Grid item sm={8} xs={0} display="flex" justifyContent="flex-end" sx={{ }}></Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.green.main, display: 'flex', justifyContent: 'center', }}>Low</Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.yellow.main, display: 'flex', justifyContent: 'center',  }}>Medium</Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.red.main, display: 'flex', justifyContent: 'center',  }}>High</Grid>
        {
          Q2P3.map((question, index) => {
            return <Grid container spacing={2} key={index}>
              <QuestionGrid data={data} question={question} qNo="Q2P3" index={index} handleChange={handleChange} />
            </Grid>
          })
        }
      </Grid>
    </Box>
    </Box>

    <Typography sx={{ mt: 2 , textAlign: "center"}}>2.4 Merchant Contact Point and Relationship</Typography>
    <Box sx={{ m: 3, display: "flex", justifyContent: "center" }}>
    <Box sx={{ width: "70%" }}>
      <Grid container spacing={0}>
      <Grid item sm={8} xs={0} display="flex" justifyContent="flex-end" sx={{ }}></Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.green.main, display: 'flex', justifyContent: 'center', }}>Low</Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.yellow.main, display: 'flex', justifyContent: 'center',  }}>Medium</Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.red.main, display: 'flex', justifyContent: 'center',  }}>High</Grid>
        {
          Q2P4.map((question, index) => {
            return <Grid container spacing={2} key={index}>
              <QuestionGrid data={data} question={question} qNo="Q2P4" index={index} handleChange={handleChange} />
            </Grid>
          })
        }
      </Grid>
    </Box>
    </Box>

    <Typography sx={{ mt: 2 , textAlign: "center"}}>2.5 Overall Merchant Risk Assessment Rating</Typography>
    <Box sx={{ m: 3, display: "flex", justifyContent: "center" }}>
    <Box sx={{ width: "70%" }}>
      <Grid container spacing={0}>
      <Grid item sm={8} xs={0} display="flex" justifyContent="flex-end" sx={{ }}></Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.green.main, display: 'flex', justifyContent: 'center', }}>Low</Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.yellow.main, display: 'flex', justifyContent: 'center',  }}>Medium</Grid>
      <Grid item sm={1} xs={4} sx={{ color: theme.palette.red.main, display: 'flex', justifyContent: 'center',  }}>High</Grid>
        <Grid container spacing={2}>
          <Grid item xs={8} display="flex" justifyContent="flex-end" sx={{ pr: 2, my: 1 }}><FormLabel>2.1 Merchant Background</FormLabel></Grid>
          <Grid item xs={1} display="flex" alignItems="center" sx={{ ml: 1 }}>{ count.Q2P1.low }</Grid>
          <Grid item xs={1} display="flex" alignItems="center">{ count.Q2P1.med }</Grid>
          <Grid item xs={1} display="flex" alignItems="center">{ count.Q2P1.high }</Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8} display="flex" justifyContent="flex-end" sx={{ pr: 2, my: 1 }}><FormLabel>2.2 Merchant Location</FormLabel></Grid>
          <Grid item xs={1} display="flex" alignItems="center" sx={{ ml: 1 }}>{ count.Q2P2.low }</Grid>
          <Grid item xs={1} display="flex" alignItems="center">{ count.Q2P2.med }</Grid>
          <Grid item xs={1} display="flex" alignItems="center">{ count.Q2P2.high }</Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8} display="flex" justifyContent="flex-end" sx={{ pr: 2, my: 1 }}><FormLabel>2.3 Merchant Product and Transaction History</FormLabel></Grid>
          <Grid item xs={1} display="flex" alignItems="center" sx={{ ml: 1 }}>{ count.Q2P3.low }</Grid>
          <Grid item xs={1} display="flex" alignItems="center">{ count.Q2P3.med }</Grid>
          <Grid item xs={1} display="flex" alignItems="center">{ count.Q2P3.high }</Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8} display="flex" justifyContent="flex-end" sx={{ pr: 2, my: 1 }}><FormLabel>2.4 Merchant Contact Point and Relationship</FormLabel></Grid>
          <Grid item xs={1} display="flex" alignItems="center" sx={{ ml: 1 }}>{ count.Q2P4.low }</Grid>
          <Grid item xs={1} display="flex" alignItems="center">{ count.Q2P4.med }</Grid>
          <Grid item xs={1} display="flex" alignItems="center">{ count.Q2P4.high }</Grid>
        </Grid>


        <Grid container spacing={2}>
          <Grid item xs={8} display="flex" justifyContent="flex-end" sx={{ pr: 2, my: 1 }}><FormLabel><b>Overall Merchant Risk Assessment Rating Total</b></FormLabel></Grid>
          <Grid item xs={1} display="flex" alignItems="center" sx={{ ml: 1 }}><b>{ count.Q2P1.low + count.Q2P2.low + count.Q2P3.low + count.Q2P4.low }</b></Grid>
          <Grid item xs={1} display="flex" alignItems="center"><b>{ count.Q2P1.med + count.Q2P2.med + count.Q2P3.med + count.Q2P4.med }</b></Grid>
          <Grid item xs={1} display="flex" alignItems="center"><b>{ count.Q2P1.high + count.Q2P2.high + count.Q2P3.high + count.Q2P4.high }</b></Grid>
        </Grid>

  
      </Grid>
    </Box>
    </Box>

  </>
}

export default Section2;