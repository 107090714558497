import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'

const store = configureStore({
  reducer: {
    auth: authReducer
  }
})

export { userLogin, userRetrieve } from './authActions';
export default store;