import { useState, useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';

const useSubmitData = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const submitData = async (apiFunction, postData) => {
    setSubmitLoading(true);
    setSubmitError(null);
    try {
      const response = await apiFunction(postData);
      if (response) {
        enqueueSnackbar('success', { variant: 'success', autoHideDuration: 3000 })
      } else {
        enqueueSnackbar('error during submission', { variant: 'error', autoHideDuration: 3000 })
        setSubmitError('error during submission');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'unexpected error';
      console.error('error: ', errorMessage);
      enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 3000 })
      setSubmitError(errorMessage);
    } finally {
      setSubmitLoading(false);
    }
  }

  return { submitData, submitLoading, submitError };
}

export default useSubmitData;