import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const FiservDisplay = ({ type, name, submission }) => {

  if (type) {
    return  <Box sx={{ mt: 2 }}>
      <Typography sx={{ fontWeight: "bold" }}>{ name }</Typography>
      {
        submission && submission.map((item, itemIndex) => {
          return <Box key={item}>
            <Typography sx={{ mt: 1 }}>{type} {itemIndex + 1}</Typography>
            {
              Object.keys(item).map((key, index) => (
                <Grid container key={index}>
                  <Grid item sx={{ color: 'gray' }} xs={3}><Typography>{ key }:</Typography></Grid>
                  <Grid item xs={9}>{ item[key] ? item[key] : "-" }</Grid>
                </Grid>
              ))
            }
          </Box>
        })
      }
    </Box>
  } else {
    return <Box sx={{ mt: 2 }}>
      <Typography sx={{ fontWeight: "bold" }}>{ name }</Typography>
      {
        submission && Object.keys(submission).map((key, index) => (
          <Grid container key={index}>
            <Grid item sx={{ color: 'gray' }} xs={3}><Typography>{ key }:</Typography></Grid>
            <Grid item xs={9}>{ submission[key] ? submission[key] : "-" }</Grid>
          </Grid>
        ))
      }
    </Box>
  }
  
}

export default FiservDisplay;