import { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import { API } from 'services';
import { 
  Step1, 
  Step2, 
  Step3,
  Step4,
  Step5,
  Step6
} from "./components";
import { 
  initStep1 as initMerchantDetails,
  initStep2 as initTradingLocations, 
  initStep3 as initPrincipalDetails,
  initStep4 as initUboDetails,
  initStep5 as initBusinessSummary
} from "utils";

const choices = [
  'Merchant Details',
  'Trading Locations',
  'Principal Details',
  'UBO Details',
  'Business Summary',
  'Submit'
]

const STYLES = {
  leftBox: {
    backgroundColor: 'primary.main',
    color: 'white',
    borderRadius: '6px',
    padding: 3,
    height: '100%'
  },
  rightBox: {
    padding: 3
  },
  stepsBtn: {
    my: 4,
    cursor: 'pointer',
    color: '#8f5353'
  },
  selectedStepsBtn: {
    my: 4,
    cursor: 'pointer',
    fontWeight: 'bold'
  },
  stepsNo: {
    color: 'white',
    border: '1px solid #8f5353',
    height: '1.5rem',
    width: '1.5rem',
    fontSize: '1rem',
    color: '#8f5353'
  },
  selectedStepsNo: {
    color: 'white',
    border: '1px solid white',
    height: '1.5rem',
    width: '1.5rem',
    fontSize: '1rem'
  }
}

const CreateMid = ({ editData, setTopChoice }) => {
  const [choice, setChoice] = useState(1);
  const [data, setData] = useState({});
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    list_merchants();
    if (editData) setData(editData);
    else init_data();
  }, [])

  const init_data = () => {
    setData({
      selectedMerchant: {},
      merchantDetails: initMerchantDetails,
      tradingLocations: [initTradingLocations],
      principalDetails: [initPrincipalDetails],
      uboDetails: [initUboDetails],
      businessSummary: initBusinessSummary
    })
  }

  const list_merchants = async () => {
    const merchantArr = await API.Others.FiservOnboarding.fetch_all_merchants({ nolimit: true });
    if (merchantArr) {
      const completedMerchants = merchantArr.filter(item => item.current_status === 'completed');
      setMerchants(completedMerchants);
    }
  }

  return <>
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box sx={ STYLES.leftBox }>
          <Typography variant="h5"><b>Step {choice}</b></Typography>
          { choices.map((item, index) => {
              return <Box key={index} onClick={() => setChoice(index + 1)} sx={ choice === index+1 ? STYLES.selectedStepsBtn : STYLES.stepsBtn }>
                <Grid container spacing={2}>
                  <Grid item>
                    <IconButton sx={ choice === index+1 ? STYLES.selectedStepsNo : STYLES.stepsNo }>{index + 1}</IconButton>
                  </Grid>
                  <Grid item>
                    {item}
                  </Grid>
                </Grid>
              </Box>
            })
          }
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box sx={ STYLES.rightBox }>
        {
          choice === 1 ? <Step1 merchants={merchants} data={data} setData={setData} setChoice={setChoice} />
          : choice === 2 ? <Step2 data={data} setData={setData} setChoice={setChoice} />
          : choice === 3 ? <Step3 data={data} setData={setData} setChoice={setChoice} />
          : choice === 4 ? <Step4 data={data} setData={setData} setChoice={setChoice} />
          : choice === 5 ? <Step5 data={data} setData={setData} setChoice={setChoice} />
          : <Step6 data={data} setData={setData} setTopChoice={setTopChoice} />
        }
        </Box>
      </Grid>
    </Grid>
  </>
}

export default CreateMid;