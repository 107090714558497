import { sessioned_client } from '../auth';
import { get_api_path } from '../api';

export const fetch_all_logs = async () => {
  const url = get_api_path('logs/list');
  const response = await sessioned_client().get(url)
  return response.data;
}

export const create_logs = async (data) => {
  const url = get_api_path('logs/create');
  const response = await sessioned_client().post(url, data);
}