import React, {useState, useEffect, useRef} from 'react'
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { API } from 'services';
import { countries } from "utils";
import { Q1P2, Q2P3 } from './texts'
import moment from "moment";
import { enqueueSnackbar, SnackbarProvider } from 'notistack';

function EditAssessment() {

const merchantBackground = 
[
'Reliable information on the Merchant, beneficial owners, directors is publicly or easily available and matches the customer application business profile.',
'Credible and stable financial information on the Merchant is available from the bank letters / statements furnished by the Merchant during application.',
'Merchant or beneficial owner or directors matches with persons in the MAS control list.',
'Merchant or beneficial owners or connected party has adverse news (eg. Disputes, bankruptcy, offences etc) based on searches from independent search engines and credibility / reference checks.',
'Merchant is involved in High-Risk Industry or business activities / products involving conflict of cross-border jurisdictions and infringements.',
'Merchant is: a shell company, has complex shareholding structure (e.g., involving 3 layers or more of ownership structure, different jurisdictions, trusts); OR operates without an obvious commercial purpose.',
'Merchant is a charitable or non-profit organization that is NOT registered in Singapore (charities.gov.sg/charity/index.do).',
'Company is familiar with the merchant\’s AML/CFT controls due to previous similar business dealings with the merchant or 3rd party referrals and recommendations.',
'Merchant\’s business operations and processing history are verifiable - Existing business vs new start-up'
]

const riskLevel = ['yes', 'no', 'na']

const merchantLocation = 
[
'Merchant business activities and operations are in a high-risk jurisdictions country.',
'Merchant beneficial owners or natural persons are connected to high risk jurisdictions in any of the above listed aspects.',
'The merchant does not have equivalent AML/CFT measures that are able to mitigate the risks of being from a high risk jurisdictions country.',
]

const merchantProduct = 
[
'Merchant is involved in High-Risk Industry or business activities / products involving conflict of cross-border jurisdictions and infringements.',
'Merchant product mix history and pattern of sale changes frequently.',
'Product and services offered by merchant are subject to cross-border regulatory infringement checks.',
'General and common trading products and services with small transaction values.',
'Business transaction pattern and history is regular and reliable. (eg. Chargeback history within threshold limits set by credit card associations, refund history, payment processor history, etc)',
'Merchant has existing anti-fraud systems for their transaction monitoring.',
'Merchant business portal and operation mode is clear. Allows ID account log-in to check products and transaction flow.',
]

const merchantPoint = 
[
'Merchant relationship is established through non-face-to-face approach (eg. Online enquiry, telephone, postal modes)',
'Merchant relationship is established through referral or recommendation.'
]

const finalRisk = 
[
'na',
'low',
'medH',
'medL',
'high'
]

const recommendation = 
[
'establish',
'maintain',
'decline',
'terminate'
]

const [firstForm, setFirstForm] = useState({
    entity: '',
    country: '',
    clientType: '',
});

const [defaultData, setDefaultData] = useState({
    Q1P1a: Array(3).fill(''),
    Q1P1b: Array(2).fill(''),
    Q1P2: Array(Q1P2.length).fill(''),
    Q2P1: Array(merchantBackground.length).fill(''),
    Q2P2: Array(merchantLocation.length).fill(''),
    Q2P3: Array(merchantProduct.length).fill(''),
    Q2P4: Array(merchantPoint.length).fill(''),
    Q3: Array(4).fill('')
  });
  
const defaultCount = {
    Q2P1: { low: 0, med: 0, high: 0 },
    Q2P2: { low: 0, med: 0, high: 0 },
    Q2P3: { low: 0, med: 0, high: 0 },
    Q2P4: { low: 0, med: 0, high: 0 }
};

const [merchants, setMerchants] = useState();
const [director, setDirector] = useState();
const [ubo, setUbo] = useState();
const [merchantToggle, setMerchantToggle] = useState(false);

const list_merchants = async () => {
const merchantArr = await API.Operations.Agreement.fetch_all_merchants({ nolimit: true });
if (merchantArr) {
    const completedMerchants = merchantArr.filter(item => item.current_status === 'completed');
    
    setMerchants(completedMerchants);
}
}

const handleChange = (e) => {
    setShowOption(true);
    setFirstForm (prev => ({
        ...prev,
        [e.target.name]: e.target.value,
    }));
    
};

const firstQuestionChange = (e, merchantToggle) => {
    let arr = []
    if (merchantToggle) {
        arr = [ ...defaultData.Q1P1a]
    } else {
        arr = [ ...defaultData.Q1P1b]
    };
    arr.splice(parseInt(e.target.id),  1, e.target.value);
    setDefaultData (prev => ({
        ...prev,
        [e.target.name]: arr,
        
    }));
}

const directorChange = (e, directorIndex) => {
    const arr = [...director];
    arr[directorIndex].name = e.target.value;
    setDirector(arr);
}

const uboChange = (e, uboIndex) => {
    const arr = [...ubo];
    arr[uboIndex].name = e.target.value;
    setUbo(arr);
}


const [showOption, setShowOption] = useState(false);

const dCountryChange = (e, directorIndex) => {
   
    const arr = [...director];
    arr[directorIndex].country = e.target.value;
    setDirector(arr);
}

const uCountryChange = (e, uboIndex) => {
    
    const arr = [...ubo];
    arr[uboIndex].country = e.target.value;
    setUbo(arr);
}

const emptyAdd = {
    country: '',
    countryType: null,
    name: '',
}

const addDirector = () => {
    const arr = [...director];
    const added = arr.concat([emptyAdd]);
    setDirector(added);
}

const addUbo = () => {
    const arr = [...ubo];
    const added = arr.concat([emptyAdd]);
    setUbo(added);
}

const merchantClick = (e) => {
    const arr = [ ...defaultData.Q1P1a];
    arr.splice(0,  1, e.target.value);
    setDefaultData (prev => ({
        ...prev,
        Q1P1a: arr,
        
    }));
}

const radioClick = async (e, questionIndex) => {
    
    const arr = [ ...defaultData.Q1P2];
    arr.splice(questionIndex,  1, e.target.value);
    setDefaultData (prev => ({
        ...prev,
        Q1P2: arr,
        
    }));

    
    console.log('test ', defaultData)
    // console.log('people', JSON.parse(riskList[parseInt(index)].people).director[0]);
}

const lowChange = (e, questionIndex) => {
    
    console.log(e.target.name);
    const arr = [ ...defaultData[e.target.name]];
    arr.splice(questionIndex,  1, 'l-' + e.target.value.toLowerCase());
    setDefaultData (prev => ({
        ...prev,
        [e.target.name]: arr,
        
    }));
    
   
    // defaultData[e.target.name][parseInt(questionIndex)]=e.target.value; 
};

const midChange = (e, questionIndex) => {
    
    
    const arr = [ ...defaultData[e.target.name]]
    arr.splice(questionIndex,  1, 'm-' + e.target.value.toLowerCase())
    setDefaultData (prev => ({
        ...prev,
        [e.target.name]: arr,
        
    }));
    
    
    // defaultData[e.target.name][parseInt(questionIndex)]=e.target.value; 
};

const highChange = (e, questionIndex) => {
    
    
    const arr = [ ...defaultData[e.target.name]]
    arr.splice(questionIndex,  1, 'h-' + e.target.value.toLowerCase())
    setDefaultData (prev => ({
        ...prev,
        [e.target.name]: arr,
        
    }));
    
    
    // defaultData[e.target.name][parseInt(questionIndex)]=e.target.value; 
};

const thirdQuestionChange = (e) => {
    const arr = [ ...defaultData.Q3]
    arr.splice(e.target.id,  1,  e.target.value)
    setDefaultData (prev => ({
        ...prev,
        Q3: arr,
        
    }));
}

const getRiskLevel = (risk) => {
    let value = '';
    
    switch (risk) {
        case 0:
        value = 'Yes';
        break;

        case 1:
        value = 'No';
        break;

        case 2:
        value = 'NA';
        break;
    }

    return(value);
}

const getRiskRating = (results) => {
    let value = ''

    switch(results) {
        case 'high':
        value = 'High';
        break;

        case 'medL':
        value = 'Medium Low';
        break;

        case 'medH':
        value = 'Medium High';
        break;

        case 'low':
        value = 'Low';
        break;
    }

    return(value);
}

const getRiskOptions = (optionsIndex) => {
    
    let value = ''
    
    switch(optionsIndex) {
        case 0:
        value = 'NA';
        break;

        case 1:
        value = 'Low';
        break;

        case 2:
        value = 'Low/Medium';
        break;

        case 3:
        value = 'Medium/High';
        break;

        case 4:
        value = 'High';
    }

    return(value);
}

const getRecommendationOptions = (optionsIndex) => {
    let value = ''

    switch(optionsIndex) {
        case 0:
        value = 'Establish';
        break;

        case 1: 
        value = 'Maintain';
        break;

        case 2:
        value = 'Decline';
        break;

        case 3:
        value = 'Terminate';
    }

    return(value);
}



const [ count, setCount] = useState(defaultCount);
const [ result, setResult ] = useState('');

useEffect(() => {
    calculateRisk();
    initialRadio();
  }, [defaultData])

  const calculateRisk = () => {
    let newCount = defaultCount;
    for (const question in defaultData) {
      if (!question.match(/Q2/)) continue;
      let low = 0, med = 0, high = 0;
      for (const item of defaultData[question]) {
        if (item === "l-yes" || item === "l-no") low++
        if (item === "m-yes" || item === "m-no") med++
        if (item === "h-yes" || item === "h-no") high++
      }
      newCount = { ...newCount, [question]: { low, med, high }}
    }

    const sumMed = newCount.Q2P1.med + newCount.Q2P2.med + newCount.Q2P3.med + newCount.Q2P4.med;
    const sumHigh = newCount.Q2P1.high + newCount.Q2P2.high + newCount.Q2P3.high + newCount.Q2P4.high;

    // According to Risk Scoring Matrix
    let newResult = '';
    if (sumHigh > 0 || sumMed >= 5) newResult = 'high';
    else if (sumMed === 4) newResult = 'medH';
    else if (sumMed === 3) newResult = 'medL';
    else newResult = 'low'
    setResult(newResult);
    setCount(newCount);
  }

//GET RISK FROM DB
const [riskList, setRiskList] = useState([]);
const search = useLocation().search;

const index = () => {
    const arrNumber = new URLSearchParams(search).get("data");
    const newNumber = riskList.length - 1 - arrNumber;
    
    return(newNumber);
}

useEffect(() => {
list_merchants();
getRisks();

}, []);

useEffect(() => {
  setValues();
  initialRadio();
}, [riskList]);

const initialRadio = () => {
    if (defaultData.Q1P1a[0].toString() === '0' || defaultData.Q1P1a[0].toString() === '1') {
        setMerchantToggle(true);
    }
}

const getRisks = async () => {
  const result = await API.Compliance.Risk.fetch_all_risk();
  if (result) {
    setRiskList(result);
  }
};

const setValues = async () => {
  
  if (riskList[parseInt(index())]) {
    // console.log('riskList', riskList[parseInt(index)])
    // console.log('defaultData', defaultData)
    setFirstForm({
        entity: riskList[parseInt(index())].entity,
        country: riskList[parseInt(index())].country,
        clientType: riskList[parseInt(index())].clientType
    })
    setDirector(JSON.parse(riskList[parseInt(index())].people).director)
    setUbo(JSON.parse(riskList[parseInt(index())].people).ubo)
    
    const fetchedData = JSON.parse(riskList[parseInt(index())].answers);
    
    setDefaultData({
        Q1P1a: fetchedData.Q1P1a,
        Q1P1b: fetchedData.Q1P1b,
        Q1P2: fetchedData.Q1P2,
        Q2P1: fetchedData.Q2P1,
        Q2P2: fetchedData.Q2P2,
        Q2P3: fetchedData.Q2P3,
        Q2P4: fetchedData.Q2P4,
        Q3: fetchedData.Q3,
    });

    }
  }


const navigate = useNavigate();
const submitForm = async () => {
    let countryType = ""

    const fatfList = await API.Compliance.Risk.fetch_country_risk();
    const country_fatf = fatfList[fatfList.length-1];
    
    const code = countries.find(item => item.label === firstForm.country)?.code;
    if (country_fatf.blackList?.includes(code)) countryType = "black";
    else if (country_fatf.grayList?.includes(code)) countryType = "gray";
    else if (country_fatf.othersList?.includes(code)) countryType = "others";

    const categorise = (arr) => {
      return arr.map(item => {
        const cipher = countries.find(index => index.label === item.country)?.code;
        let countryType = ""
        if (!item.country) countryType = 'test1';
        else if (country_fatf.blackList?.includes(cipher)) countryType = "black"
        else if (country_fatf.grayList?.includes(cipher)) countryType = "gray"
        else if (country_fatf.othersList?.includes(cipher)) countryType = "others"
        else countryType = 'test2'
        return { ...item, countryType }
      })
    }


    const updatedPeople = {director: categorise(director), ubo: categorise(ubo)}

    const body = {
    entity: firstForm.entity,
    answers: JSON.stringify(defaultData),
    count: JSON.stringify(count),
    finalRisk: defaultData.Q3[2] !== '' ? defaultData.Q3[2] : result,
    status: defaultData.Q3[3],
    merchantId: '', //I assume this is supposed to be empty on subsequent entries of the same entity?
    country: firstForm.country,
    clientType: firstForm.clientType,
    countryType,
    people: JSON.stringify(updatedPeople)
    };

    console.log('body: ', body)
    try {
      const result = await API.Compliance.Risk.create_risk(body);
      if (result) {
        
        enqueueSnackbar("Form successfully created!", {
          variant: 'success',
          autoHideDuration: 3000
        })
        navigate(`/compliance/risk-assessment/list`)
      } else {
        enqueueSnackbar("Error creating form", {
          variant: 'error',
          autoHideDuration: 3000
        })
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
}

return (
    <div>
        <p className = 'text-2xl'>
            Add New Risk Assessment
        </p>

        <div className = 'p-2' />

        <div className = 'p-6 bg-white shadow-md rounded-md flex-col flex md:w-full'>

            <p>
                Date of Assessment: { moment().format('Do MMMM YYYY') }
            </p>

            <input className = 'border my-2 rounded-md h-[40px] px-2' value={firstForm.entity} name='entity' onChange={handleChange}>
                
            </input>

            <select className = 'border my-2 rounded-md h-[40px] px-2' value={firstForm.country} name='country' onChange={handleChange}>
                <option selected value disabled={showOption} className = {showOption || firstForm.country ? 'hidden' : ''}></option>
                {

                    countries.map((country, countryIndex) => {
                        return <option>{countries[countryIndex].label}</option>
                    })

                } 
            </select>

            <select className = 'border my-2 rounded-md h-[40px] px-2' value={firstForm.clientType} name='clientType' onChange={handleChange}>
                
                <option value='m'>
                    Merchant
                </option>
                <option value='p'>
                    Partner/Master Merchant
                </option>
                
            </select>

            
        </div>

        <div className = 'p-2' />

        <div className = 'p-6 bg-white shadow-md rounded-md flex-col md:flex-row flex md:w-full'>
            <div className = 'flex flex-col md:w-1/2 w-full'>
            {
                director && director.map((directors, directorIndex)=> (
                <div className = 'flex flex-row w-full justify-around py-1'>
                    <input className = 'w-1/2 border rounded-md h-[40px] px-2' value = {director[directorIndex].name} onChange={(e) => directorChange(e, directorIndex)}>
                    </input>
                    <div className = 'p-1'/>
                    <select className = 'w-1/2 border rounded-md h-[40px] px-2' value = {director[directorIndex].country} onChange={(e) => dCountryChange(e, directorIndex)}>
                    <option selected value disabled={director[directorIndex].country} className = {director[directorIndex].country ? 'hidden' : ''}></option>
                    {

                        countries.map((country, countryIndex) => {
                            return <option>{countries[countryIndex].label}</option>
                        })

                    }
                    </select>
                </div>
                ))
            }
                <div className = 'p-2'/>
                <button className = 'border h-[35px] rounded-md border-red-800 text-red-800' onClick = {addDirector}>
                    ADD DIRECTOR
                </button>
            </div>
            <div className ='p-2'/>
            <div className = 'flex flex-col md:w-1/2 w-full'>

            {
                ubo && ubo.map((ubos, uboIndex)=> (
                <div className = 'flex flex-row w-full justify-around py-1'>
                    <input className = 'w-1/2 border rounded-md h-[40px] px-2' value = {ubo[uboIndex].name} onChange={(e) => uboChange(e, uboIndex)}>
                    </input>
                    <div className = 'p-1'/>
                    <select className = 'w-1/2 border rounded-md h-[40px] px-2' value = {ubo[uboIndex].country} onChange={(e) => uCountryChange(e, uboIndex)}>
                        <option selected value disabled={ubo[uboIndex].country} className = {ubo[uboIndex].country ? 'hidden' : ''}></option>
                        {
                            countries.map((country, countryIndex) => {
                                return <option>{countries[countryIndex].label}</option>
                            })

                        }
                    </select>
                </div>
                ))
            }

                <div className = 'p-2'/>
                <button className = 'border h-[35px] rounded-md border-red-800 text-red-800' onClick = {addUbo}>
                    ADD UBO
                </button>
            </div>
        </div>

        <div className = 'p-2' />

        
        <div className = 'p-6 bg-white shadow-md rounded-md flex-col flex md:w-full '>
            <div className = 'flex flex-row justify-center'>
                <p className = 'text-2xl font-bold'>
                    Merchant AML / CFT Risk Assessment - Level 1
                </p>
                
            </div>
            <div className = 'p-2'/>
            <div className = 'flex flex-row justify-center'>
                <div className = 'w-[95%] flex flex-row justify-center border-b border-gray-200'/>
            </div>
            <div className = 'p-2'/>
            <div className = 'flex flex-row justify-center text-xl font-semibold'>
                <p>
                    1.1 Purpose of Merchant Evaluation
                </p>
            </div>
            <div className = 'p-3'/>
            {/* where am I storing this */}
            <div className = 'flex flex-row justify-center'>
                <input type='radio' className = 'accent-red-800 ' name='1.1.1' checked={merchantToggle} onClick = {() => setMerchantToggle(true)}>

                </input>
                <div className = 'p-1'/>
                <p>
                    Existing Merchant
                </p>
                <div className = 'p-2'/>
                <input type='radio' className = 'accent-red-800' name='1.1.1' checked={!merchantToggle} onClick = {() => setMerchantToggle(false)}>

                </input>
                <div className = 'p-1'/>
                <p>
                    New Merchant
                </p>
            </div>
            <div className = 'p-3'/>
            { merchantToggle && <div className = 'flex flex-row justify-center text-gray-500'>
                <p>
                    Routine Review of Existing Merchant
                </p>
            </div> }
            { merchantToggle && <div className = 'p-3'/> }
            { merchantToggle && <div className = 'flex flex-row justify-center'>
                <input type='radio' className = 'accent-red-800' name='1.1.2' value='1' checked={defaultData.Q1P1a[0].toString() === '1'} onChange = {(e)=>merchantClick(e)}>

                </input>
                <div className = 'p-1'/>
                <p>
                    Yes
                </p>
                <div className = 'p-2'/>
                <input type='radio' className = 'accent-red-800' name='1.1.2' value='0' checked={defaultData.Q1P1a[0].toString() === '0'} onChange = {(e)=>merchantClick(e)}>

                </input>
                <div className = 'p-1'/>
                <p>
                    No
                </p>
            </div> }
            { merchantToggle &&  <div className = 'p-3'/> }
            <div className = 'flex flex-row justify-center text-gray-500'>
                <p>
                    Review as a result of trigger event – Please indicate the trigger event:
                </p>
            </div>
            <div className = 'flex flex-row justify-center'>
                <textarea className = 'w-[550px] border rounded-md h-[100px] resize-none py-2 px-3' id={merchantToggle ? '1' : '0'} type='text' placeholder = 'Input' name={merchantToggle ? 'Q1P1a' : 'Q1P1b'} value = {merchantToggle ? defaultData.Q1P1a[1] : defaultData.Q1P1b[0]} onChange = {(e) => firstQuestionChange(e, merchantToggle)}>
                    
                </textarea>
            </div>
            <div className = 'p-3'/>
            <div className = 'flex flex-row justify-center text-gray-500'>
                <p>
                Please provide details regarding the nature of services required by client:
                </p>
            </div>
            <div className = 'flex flex-row justify-center'>
                <textarea className = 'w-[550px] border rounded-md h-[100px] resize-none p-3' id={merchantToggle ? '2' : '1'} type='text' placeholder = 'Input' name={merchantToggle ? 'Q1P1a' : 'Q1P1b'} value = {merchantToggle ? defaultData.Q1P1a[2] : defaultData.Q1P1b[1]} onChange = {(e) => firstQuestionChange(e, merchantToggle)}>
                    
                </textarea>
            </div>
            <div className = 'p-3'/>
            <div className = 'flex flex-row justify-center'>
                <p>
                1.2 Establishing or continuing business account with the Merchant is NOT allowed if the answer to any of the below questions is "NO"
                </p>
            </div>
            <div className = 'p-1'/>
            
            {
                Q1P2.map((question, questionIndex)=> (
                    <div key={questionIndex}>
                    
                        <div className = 'p-4'/>
                        <div className = 'flex flex-row justify-center'>
                            
                            <p className = 'break-normal text-right w-[40%] text-gray-500'>
                                {Q1P2[questionIndex].text}
                            </p>
                            <div className = 'p-2'/>
                                <input type='radio' name = {questionIndex.toString()} className = 'accent-red-800 ' value = '1' checked={defaultData.Q1P2[questionIndex].toString() === '1'} onClick = {(e) => radioClick(e, questionIndex)}>
                                </input>
                                <div className = 'p-1'/>
                                <p className = 'flex flex-col justify-center' >
                                    Yes
                                </p>
                                <div className = 'p-2'/>
                                <input type='radio' name = {questionIndex.toString()} className = 'accent-red-800' value = '0' checked={defaultData.Q1P2[questionIndex].toString() === '0'} onClick = {(e) => radioClick(e, questionIndex)}>

                                </input>
                                <div className = 'p-1'/>
                                <p className = 'flex flex-col justify-center'>
                                    No
                                </p>
                        </div>

                    </div>

                ))
            }

            <div className = 'p-10'/>
            <div className = 'flex flex-row justify-center'>
                <p className = 'text-2xl font-bold'>
                Merchant AML / CFT Risk Assessment Questionnaire and Review – Level 2
                </p>
                
            </div>
            <div className = 'p-2'/>
            <div className = 'flex flex-row justify-center'>
                <div className = 'w-[95%] flex flex-row justify-center border-b border-gray-200'/>
            </div>
            <div className = 'p-2'/>
            <div className = 'flex flex-row justify-center text-xl font-semibold'>
                <p>
                2.1 Merchant Background
                </p>
            </div>
            <div className = 'p-4'/>
            <div className = 'flex flex-row justify-center'>             
                <p className = 'break-words text-right w-[40%] '>
                    Risk Level
                </p>
                <div className = 'p-2'/>
                <div className = 'flex flex-col justify-center w-[60px] text-green-500'>
                    <p className= 'text-center text-green'>
                        Low
                    </p>
                </div>
                <div className = 'p-1'/>
                <div className = 'flex flex-col justify-center w-[60px] text-amber-500'>
                    <p className= 'text-center'>
                        Medium
                    </p>
                </div>
                <div className = 'p-1'/>
                <div className = 'flex flex-col justify-center w-[60px] text-red-700'>
                    <p className= 'text-center'>
                        High
                    </p>
                </div>
            </div>
                        
            {
                merchantBackground.map((question, questionIndex)=> (
                    <div key={questionIndex}>
                        <div className = 'p-4'/>
                        <div className = 'flex flex-row justify-center'>
                            
                            <p className = 'break-words text-right w-[40%] text-gray-500'>
                                {question}
                            </p>
                            <div className = 'p-2'/>
                            <div className = 'flex flex-col justify-center'>
                                <select name='Q2P1' id='l' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P1[questionIndex].split("-")[0] === 'l' ? defaultData.Q2P1[questionIndex].split("-")[1] : ''} onChange = {(e) => lowChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P1[questionIndex].split("-")[0] === 'l'} selected value className = {defaultData.Q2P1[questionIndex].split("-")[0] === 'l' ? 'hidden' : ''}>
                                  
                                    </option>
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value={riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            
                            <div className = 'p-1'/> 
                            <div className = 'flex flex-col justify-center'>
                                <select name='Q2P1' id='m' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P1[questionIndex].split("-")[0] === 'm' ? defaultData.Q2P1[questionIndex].split("-")[1] : ''} onChange = {(e) => midChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P1[questionIndex].split("-")[0] === 'm'} selected value className = {defaultData.Q2P1[questionIndex].split("-")[0] === 'm' ? 'hidden' : ''}>
                                  
                                    </option>
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value={riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className = 'p-1'/>
                            <div className = 'flex flex-col justify-center'>
                                <select name='Q2P1' id='h' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P1[questionIndex].split("-")[0] === 'h' ? defaultData.Q2P1[questionIndex].split("-")[1] : ''} onChange = {(e) => highChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P1[questionIndex].split("-")[0] === 'h'} selected value className = {defaultData.Q2P1[questionIndex].split("-")[0] === 'h' ? 'hidden' : ''}>
                                  
                                    </option>
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value={riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            
                        </div>
                    </div>
                ))
            }

           
            <div className = 'p-4'/>
            <div className = 'flex flex-row justify-center text-xl font-semibold'>
                <p>
                2.2 Merchant Location
                </p>
            </div>
            <div className = 'p-1'/>
            <div className = 'flex flex-row justify-center '>
                <p className = 'text-center'>
                Applicable aspects to assess merchant location: Natural owners’ nationality; place of business incorporation; registered business address; location of operation.
                </p>
            </div>

            {
                merchantLocation.map((question, questionIndex)=> (
                    <div key={questionIndex}>
                        <div className = 'p-4'/>
                        <div className = 'flex flex-row justify-center'>
                            
                            <p className = 'break-words text-right w-[40%] text-gray-500'>
                                {question}
                            </p>
                            <div className = 'p-2'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='l' name='Q2P2' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P2[questionIndex].split("-")[0] === 'l' ? defaultData.Q2P2[questionIndex].split("-")[1] : ''} onChange = {(e) => lowChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P2[questionIndex].split("-")[0] === 'l'} selected value className = {defaultData.Q2P2[questionIndex].split("-")[0] === 'l' ? 'hidden' : ''} />
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className = 'p-1'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='m' name='Q2P2' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P2[questionIndex].split("-")[0] === 'm' ? defaultData.Q2P2[questionIndex].split("-")[1] : ''} onChange = {(e) => midChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P2[questionIndex].split("-")[0] === 'm'} selected value className = {defaultData.Q2P2[questionIndex].split("-")[0] === 'm' ? 'hidden' : ''} />
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className = 'p-1'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='h' name='Q2P2' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P2[questionIndex].split("-")[0] === 'h' ? defaultData.Q2P2[questionIndex].split("-")[1] : ''} onChange = {(e) => highChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P2[questionIndex].split("-")[0] === 'h'} selected value className = {defaultData.Q2P2[questionIndex].split("-")[0] === 'h' ? 'hidden' : ''} />
                                  
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            
                        </div>
                    </div>
                ))
            
            }
            <div className = 'p-4'/>
            <div className = 'flex flex-row justify-center text-xl font-semibold'>
                <p>
                2.3 Merchant Product and Transaction History
                </p>
            </div>
            {
                merchantProduct.map((question, questionIndex)=> (
                    <div key={questionIndex}>
                        <div className = 'p-4'/>
                        <div className = 'flex flex-row justify-center'>
                            
                            <p className = 'break-words text-right w-[40%] text-gray-500'>
                                {question}
                            </p>
                            <div className = 'p-2'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='l' name='Q2P3' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P3[questionIndex].split("-")[0] === 'l' ? defaultData.Q2P3[questionIndex].split("-")[1] : ''} onChange = {(e) => lowChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P3[questionIndex].split("-")[0] === 'l'} selected value className = {defaultData.Q2P3[questionIndex].split("-")[0] === 'l' ? 'hidden' : ''} />

                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className = 'p-1'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='m' name='Q2P3' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P3[questionIndex].split("-")[0] === 'm' ? defaultData.Q2P3[questionIndex].split("-")[1] : ''} onChange = {(e) => midChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P3[questionIndex].split("-")[0] === 'm'} selected value className = {defaultData.Q2P3[questionIndex].split("-")[0] === 'm' ? 'hidden' : ''} />
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className = 'p-1'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='h' name='Q2P3' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P3[questionIndex].split("-")[0] === 'h' ? defaultData.Q2P3[questionIndex].split("-")[1] : ''} onChange = {(e) => highChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P3[questionIndex].split("-")[0] === 'h'} selected value className = {defaultData.Q2P3[questionIndex].split("-")[0] === 'h' ? 'hidden' : ''} />
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            
                        </div>
                    </div>
                ))
            
            }
            <div className = 'p-4'/>
            <div className = 'flex flex-row justify-center text-xl font-semibold'>
                <p>
                2.4 Merchant Contact Point and Relationship
                </p>
            </div>
            {
                merchantPoint.map((question, questionIndex)=> (
                    <div key={questionIndex}>
                        <div className = 'p-4'/>
                        <div className = 'flex flex-row justify-center'>
                            
                            <p className = 'break-words text-right w-[40%] text-gray-500'>
                                {question}
                            </p>
                            <div className = 'p-2'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='l' name='Q2P4' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P4[questionIndex].split("-")[0] === 'l' ? defaultData.Q2P4[questionIndex].split("-")[1] : ''} onChange = {(e) => lowChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P4[questionIndex].split("-")[0] === 'l'} selected value className = {defaultData.Q2P4[questionIndex].split("-")[0] === 'l' ? 'hidden' : ''} />
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className = 'p-1'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='m' name='Q2P4' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P4[questionIndex].split("-")[0] === 'm' ? defaultData.Q2P4[questionIndex].split("-")[1] : ''} onChange = {(e) => midChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P4[questionIndex].split("-")[0] === 'm'} selected value className = {defaultData.Q2P4[questionIndex].split("-")[0] === 'm' ? 'hidden' : ''} />
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            <div className = 'p-1'/>
                            <div className = 'flex flex-col justify-center'>
                                <select id='h' name='Q2P4' className = 'border border-gray-200 rounded-md h-[30px] w-[60px]' value = {defaultData.Q2P4[questionIndex].split("-")[0] === 'h' ? defaultData.Q2P4[questionIndex].split("-")[1] : ''} onChange = {(e) => highChange(e, questionIndex)}>
                                    <option disabled={defaultData.Q2P4[questionIndex].split("-")[0] === 'h'} selected value className = {defaultData.Q2P4[questionIndex].split("-")[0] === 'h' ? 'hidden' : ''} />
                                    {
                                        Object.keys(riskLevel).map(risk => {
                                            return <option value = {riskLevel[risk]}>{getRiskLevel(parseInt(risk))}</option>
                                        })
                                    }

                                </select>
                            </div>
                            
                        </div>
                    </div>
                ))
            
            }
            <div className = 'p-4'/>
            <div className = 'flex flex-row justify-center text-xl font-semibold'>
                <p>
                    2.5 Overall Merchant Risk Assessment Rating
                </p>
            </div>
            <div className = 'p-4'/>
            <div className = 'flex flex-row justify-center'>
                <div className = 'flex flex-col justify-center'>        
                    <p className = 'break-words text-right  '>
                        Risk
                    </p>    
                    <p className = 'break-words text-right  '>
                        2.1 
                    </p>
                    <p className = 'break-words text-right  '>
                        2.2
                    </p>
                    <p className = 'break-words text-right  '>
                        2.3 
                    </p>
                    <p className = 'break-words text-right  '>
                        2.4
                    </p>
                    <p className = 'break-words text-right  '>
                        Total
                    </p>

                </div> 
                <div className = 'p-2'/>
                <div className = 'flex flex-col justify-center  '>
                    <p className= 'text-center text-green-500 w-[60px]'>
                        Low
                    </p>
                    {
                        Object.keys(count).map((counts, countIndex)=> (
                            <div key = {countIndex}>
                                <p className = 'text-center'>
                                    {count[counts].low}
                                </p>
                            </div>

                        ))
                    }
                    <p className = 'text-center'>
                        {count.Q2P1.low + count.Q2P2.low + count.Q2P3.low + count.Q2P4.low}
                    </p>
                </div>
                <div className = 'p-1'/>
                <div className = 'flex flex-col justify-center  '>
                    <p className= 'text-center text-amber-500 w-[60px]'>
                        Medium
                    </p>
                    {
                        Object.keys(count).map((counts, countIndex)=> (
                            <div key = {countIndex}>
                                <p className = 'text-center'>
                                    {count[counts].med}
                                </p>
                            </div>

                        ))
                    }
                    <p className = 'text-center'>
                        { count.Q2P1.med + count.Q2P2.med + count.Q2P3.med + count.Q2P4.med }
                    </p>
                </div>
                <div className = 'p-1'/>
                <div className = 'flex flex-col justify-center '>
                    <p className= 'text-center text-red-700 w-[60px]'>
                        High
                    </p>
                    {
                        Object.keys(count).map((counts, countIndex)=> (
                            <div key = {countIndex}>
                                <p className = 'text-center'>
                                    {count[counts].high}
                                </p>
                            </div>

                        ))
                    }
                    <p className = 'text-center'>
                        { count.Q2P1.high + count.Q2P2.high + count.Q2P3.high + count.Q2P4.high }
                    </p>
                </div>
            </div>
            
            <div className = 'p-10'/>
            <div className = 'flex flex-row justify-center'>
                <p className = 'text-2xl font-bold'>
                Risk Evaluation and CDD Measures Application
                </p>
                
            </div>
            <div className = 'p-2'/>
            <div className = 'flex flex-row justify-center'>
                <div className = 'w-[95%] flex flex-row justify-center border-b border-gray-200'/>
            </div>
            
            <div className = 'p-4'/>
            <div className = 'flex-row flex justify-center '>
                <p className = 'w-[20%] text-right mx-2'>
                    <span className = 'font-semibold '>Section 1: </span>&nbsp;Overall Risk Rating of Client
                </p>
                <p className = 'w-[30%] mx-2'>
                    {getRiskRating(result)}
                    
                </p>
            </div>
            <div className = 'p-4'/>
            <div className = 'flex-row flex justify-center '>
                <p className = 'w-[20%] text-right mx-2 flex flex-col justify-center'>
                    <div className = ''>
                        <span className = 'font-semibold '>Section 2: </span>&nbsp;Justifications
                    </div>
                </p>
                <input id='1' className = 'border rounded-md h-[50px] w-[30%] mx-2 px-2' type='text' value={defaultData.Q3[1]} onChange={(e)=>thirdQuestionChange(e)}>
                    
                </input>
            </div>
            <div className = 'p-4'/>
            <div className = 'flex-row flex w-full justify-center '>
                <p className = 'w-[20%] text-right mx-2 flex flex-col justify-center'>
                    <div className = ''>
                        <span className = 'font-semibold '>Section 3: </span>&nbsp;Final Risk Rating – Adjusted
                    </div>
                </p>
                <select id='2' className = 'border rounded-md h-[50px] w-[30%] mx-2 px-2' value={defaultData.Q3[2]} onChange={(e)=>thirdQuestionChange(e)}>
                    {
                        finalRisk.map((options, optionsIndex) => (
                            <option key={optionsIndex} value={finalRisk[optionsIndex]}>
                                {getRiskOptions(optionsIndex)}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div className = 'p-4'/>
            <div className = 'flex-row flex w-full justify-center '>
                <p className = 'w-[20%] text-right mx-2 flex flex-col justify-center'>
                    <div className = ''>
                    <span className = 'font-semibold '>Section 4: </span>&nbsp;Recommendation - Business Relationship with Merchant
                    </div>
                </p>
                <select id='3' className = 'border rounded-md h-[50px] w-[30%] mx-2 px-2' value={defaultData.Q3[3]} onChange={(e)=>thirdQuestionChange(e)}>
                    {
                        recommendation.map((options, optionsIndex) => (
                            <option key={optionsIndex} value={recommendation[optionsIndex]}>
                                {getRecommendationOptions(optionsIndex)}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div className = 'p-4'/>
            <div className = 'w-full flex-row flex justify-center'>
                <button className = 'border h-[35px] rounded-md border-red-800 text-red-800 w-[175px] hover:bg-[#f8f5f5] transition-all duration-500 delay-75' onClick = {submitForm}>
                    SUBMIT
                </button>
            </div>
            <div className = 'p-4'/>
        </div>
        
    
    </div>
)
}

export default EditAssessment