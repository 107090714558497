import Box from "@mui/material/Box";

const STYLES = {
  "container": {
    mt: 2,
    backgroundColor: "white",
    boxShadow: '0px 0px 10px lightgray',
    borderRadius: "6px",
    p: 3
  }
}

const TraxxContainer = ({ component }) => {
  return <Box sx={ STYLES.container }>{ component }</Box>
}

export default TraxxContainer;