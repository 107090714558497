import logo from "assets/images/traxx_logo.png"

const TraxxLogo = ({ size }) => {
  switch (size) {
    case "small":
      return <img src={ logo } height="50px" />
    case "medium":
      return <img src={ logo } height="100px" />
    default:
      return <img src={ logo } />
  }
}

export default TraxxLogo;