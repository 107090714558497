import { enqueueSnackbar } from 'notistack';
import React, { useState, useEffect, useCallback, useMemo } from 'react';

const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();

  const fetchData = async (apiFunction) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiFunction();
      if (response) {
        setData(response)
      } else {
        enqueueSnackbar('error retrieving data', { variant: 'error', autoHideDuration: 3000 });
        setError('error retrieving data');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'unexpected error';
      console.error('error: ', errorMessage);
      enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 3000 });
      setError(errorMessage)
    } finally {
      setLoading(false);
    }
  }

  return { data, loading, error, fetchData };
}

export default useFetch;