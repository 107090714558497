import { sessioned_client } from '../auth';
import { get_api_path } from '../api';
import { create_logs } from 'services/api/logs';

export async function update_instructions(data, user, instructions) {
  const url = get_api_path('compliance/instructions/update/kycStatus');
  const response = await sessioned_client().post(url, data);

  if (response) {
    const action = data.kycStatus;

    create_logs({
      department: "com",
      type: "kyc",
      action,
      info: JSON.stringify({ user, instructions })
    })
    
    if (action === 'accept') {
      const url2 = get_api_path('mail/kycNotify', 'microservices');
      sessioned_client().post(url2, data);
    }
  }
  return response.data;
};