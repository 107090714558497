import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { API } from 'services';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import useMediaQuery from '@mui/material/useMediaQuery';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import moment from 'moment';
import Chip from '@mui/material/Chip';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const header = [
  {
    id: 'index',
    numeric: false,
    disablePadding: true,
    label: 'No.',
  },
  {
    id: 'created_on',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: false,
    label: 'Tag',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'User',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  }
];

const STYLES = {
  "box": {
    position: 'absolute',
    right: '1rem',
    top: '0.8rem'
  },
  "tableContainer": {
    overflowX: "initial"
  },
  "viewLogsBtn": {
    position: 'absolute',
    right: '1rem'
  }
}

const LogsDialog = ({ open, setOpen }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getAllLogs();
  }, [])

  const getAllLogs = async () => {
    const result = await API.Logs.fetch_all_logs();
    if (result) setLogs(result.reverse());
  }

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>KYC Logs</DialogTitle>    
        <DialogContent>
          <Box sx={ STYLES.box }><IconButton onClick={handleClose}><CloseIcon /></IconButton></Box>
          <TableContainer component={Paper} sx={ STYLES.tableContainer }>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {
                    header.map((item, index) => {
                      return <StyledTableCell key={index}>{ item.label }</StyledTableCell>
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log, index) => {
                  const { createdOn, department, type, action, info } = log;
                  const { user, instructions } = JSON.parse(info);
                  if (department === 'com' && type === 'kyc') return (
                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{ index+1 }</TableCell>
                      <TableCell>{ moment(createdOn).format('lll') }</TableCell>
                      <TableCell><Chip label={action} color={action === 'accept' ? 'success' : 'warning' } /></TableCell>
                      <TableCell>{ user.name }</TableCell>
                      <TableCell>
                        { `${action === 'accept' ? 'Accepted' : 'Declined'} ${instructions.companyName}'s application which was submitted on ${moment(instructions.createdOn).format('lll')}.` }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        
      </Dialog>
  );
}

export default LogsDialog;