import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TraxxButton } from 'components';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const STYLES = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
  },
  innermodal: {
    overflow: 'scroll',
    maxHeight: '80vh',
  }
}

const CountriesDisplay = ({ countriesList, color }) => {
  return <Grid container spacing={1}>
    {
      countriesList.map((item, index) => {
        return <Grid item key={index}>
          <Chip label={item} variant="outlined" color={color} />
        </Grid>
      })
    }
  </Grid>
}

const AddFatfDialog = (props) => {

  const { 
    remarks, 
    setRemarks, 
    handleSubmit, 
    selectedCountryBlack, 
    selectedCountryGray, 
    selectedCountryOthers,
    open, 
    setOpen,
  } = props;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <TraxxButton onClick={handleOpen} text="Update List" />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ STYLES.modal }>
          <Box sx={ STYLES.innermodal} >
          <Typography id="modal-modal-title" variant="h7" component="h2">
            Black List
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>Countries added:</Typography>
          { selectedCountryBlack?.add.length > 0
            ? <CountriesDisplay countriesList={selectedCountryBlack?.add} color="success" /> 
            : <Typography sx={{ color: 'gray' }}>-</Typography>
          }

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>Countries removed:</Typography>
          { selectedCountryBlack?.remove.length > 0 
            ? <CountriesDisplay countriesList={selectedCountryBlack?.remove} color="error" />
            : <Typography sx={{ color: 'gray' }}>-</Typography> 
          }
          
          <Typography id="modal-modal-title" variant="h7" component="h2" sx={{ mt: 4 }}>
            Gray List
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>Countries added:</Typography>
          { selectedCountryGray?.add.length > 0 
            ? <CountriesDisplay countriesList={selectedCountryGray?.add} color="success" /> 
            : <Typography sx={{ color: 'gray' }}>-</Typography> 
          }

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>Countries removed:</Typography>
          { selectedCountryGray?.remove.length > 0 
            ? <CountriesDisplay countriesList={selectedCountryGray?.remove} color="error" />
            : <Typography sx={{ color: 'gray' }}>-</Typography> 
          }

          <Typography id="modal-modal-title" variant="h7" component="h2" sx={{ mt: 4 }}>
            Others List
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>Countries added:</Typography>
          { selectedCountryOthers?.add.length > 0 
            ? <CountriesDisplay countriesList={selectedCountryOthers?.add} color="success" /> 
            : <Typography sx={{ color: 'gray' }}>-</Typography> 
          }

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>Countries removed:</Typography>
          { selectedCountryOthers?.remove.length > 0 
            ? <CountriesDisplay countriesList={selectedCountryOthers?.remove} color="error" />
            : <Typography sx={{ color: 'gray' }}>-</Typography> 
          }

          <Typography id="modal-modal-title" variant="h7" component="h2" sx={{ mt: 4 }}>
            Remarks
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            id="outlined-multiline-static"
            label="Remarks"
            multiline
            rows={4}
            fullWidth
            value={remarks}
            onChange={(ev) => setRemarks(ev.target.value)}
          />

          <Button variant="outlined" onClick={handleSubmit} fullWidth sx={{ mt: 4 }}>Confirm</Button>
        
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AddFatfDialog;