import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

const ConfirmDialog = ({ showDialog, handleClose, submitForm, data }) => {
  return (
    <div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ m: 1 }}>
          <DialogTitle id="alert-dialog-title">
            Confirmation
          </DialogTitle>
          <DialogContent>
            { data && <DialogContentText id="alert-dialog-description">
              Are you sure you want to { data.kycStatus } this entity?
            </DialogContentText>}
          </DialogContent>
          <DialogActions sx={{ mr: 1 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitForm} autoFocus>
              Proceed
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default ConfirmDialog;