import Typography from "@mui/material/Typography";

const Step2 = () => {
  return <>
      <Typography>Transaction Summary</Typography>
      <Typography>Debit Volume</Typography>
      <Typography>Credit Volume</Typography>
      <Typography>Refund</Typography>
      <Typography>Chargeback</Typography>
      <Typography>CB-Refund</Typography>
  </>
}

export default Step2;