import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import tradingLocationsFields from 'utils/fiserv/tradingLocations';
import { TraxxField } from 'components/TraxxField';
import STYLES from 'utils/fiserv/styles';

const defaultData = {
  tradingName: "",
  addressLine: "",
  addressLine2: "",
  addressLine3: "",
  city: "Singapore",
  state: "Singapore",
  pincode: "",
  country: "Singapore",
  phone1: "",
  phone2: "",
  contactName: "",
  email: "",
  packageId: "29215",
  packageName: "Traxx_SG_IC++ _VM0.19_C1.8_J2.2_PTF0.03U_CBR10U",
  packageDescription: "",
  count: 0,
  localTranCurr: "702",
  foreignTranCurr: "",
}

const Step2 = ({ merchant, handleNext }) => {

  const [ tradingLocations, setTradingLocations ] = useState([defaultData]);

  useEffect(() => {
    if (!merchant) return;
    if (merchant.tradingLocations) {
      setTradingLocations(JSON.parse(merchant.tradingLocations))
    } else if (merchant.selectedMerchant) {
      const info = JSON.parse(merchant.selectedMerchant);
      setTradingLocations([{
        ...defaultData,
        tradingName: info.companyName,
        addressLine: info.address,
        phone1: info.contactNum,
        contactName: info.firstName + " " + info.lastName,
        email: info.email,
        country: info.country,
        city: info.country === "Singapore" ? "Singapore" : "",
        state: info.country === "Singapore" ? "Singapore" : ""
      }])
    }
  }, [merchant])

  const removeLocation = (indexToRemove) => {
    setTradingLocations(prev => 
      prev.filter((item, index) => index !== indexToRemove)
    );
  }

  const addLocation = () => {
    setTradingLocations(prev => [
      ...prev,
      defaultData
    ]);
  }

  const handleChange = (ev, index) => {
    const { name, value } = ev.target;
    setTradingLocations(prev => {
      const updatedData = [...prev];
      updatedData[index] = { ...updatedData[index], [name]: value }
      return updatedData;
    })
  }
  
  return <>

    {
      tradingLocations.map((location, index) => (
        <Box key={index} sx={ STYLES.box }>
          <Box sx={ STYLES.header }>
            <Typography>Location {index+1}</Typography>
            { 
              tradingLocations.length !== 1 && 
              <IconButton aria-label="delete" onClick={() => removeLocation(index)}><CancelIcon /></IconButton>
            }
          </Box>
          <Grid container spacing={2} style={ STYLES.innerBox }>
            <TraxxField
              arr={tradingLocationsFields}
              data={location}
              handleChange={(ev) => handleChange(ev, index)}
            />
          </Grid>
        </Box>
      ))
    }

    { 
      tradingLocations.length < 3 && 
      <Button sx={{ mb: 1 }} variant="outlined" fullWidth onClick={addLocation}>Add Location</Button>
    }

    <Grid item xs={12} sx={{ mt: 3 }}>
      <Button variant="contained" fullWidth onClick={() => handleNext(tradingLocations, "tradingLocations")}>
        Save
      </Button>
    </Grid>

  </>
}

export default Step2;