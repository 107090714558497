import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const HeaderComponent = ({ header }) => {
  return (
    <TableHead>
      <TableRow>
        { header.map((item) => {
            return <TableCell key={item.id}><b>{ item.label }</b></TableCell>
          })
        }
      </TableRow>
    </TableHead>
  )
};

export default HeaderComponent;