const businessSummary = [
  {
    prop: "briefSummary",
    required: false,
    grid: { xs: 12 },
    label: "Summary of Merchant",
  },
  {
    prop: "mccCode",
    required: false,
    grid: { xs: 6 },
    label: "MCC of Merchant",
  },
  {
    prop: "mccDescription",
    required: false,
    grid: { xs: 6 },
    label: "MCC Description",
  },
  {
    prop: "cardTurnover",
    required: false,
    grid: { xs: 6 },
    label: "Card Turnover",
  },
  {
    prop: "totalTurnover",
    required: false,
    grid: { xs: 6 },
    label: "Total Turnover of Merchant",
  },
  {
    prop: "avgTicketAmt",
    required: false,
    grid: { xs: 12 },
    label: "Average Ticket Amount of Merchant",
  },
  {
    prop: "tranVolumeInternet",
    required: false,
    grid: { xs: 4 },
    label: "Transaction Volume (Internet)",
  },
  {
    prop: "tranVolumeMoto",
    required: false,
    grid: { xs: 4 },
    label: "Transaction Volume (Moto)",
  },
  {
    prop: "tranVolumeInStore",
    required: false,
    grid: { xs: 4 },
    label: "Transaction Volume (Store)",
  },
  {
    prop: "deliveryDays0",
    required: false,
    grid: { xs: 6 },
    label: "Delivery Days 0",
  },
  {
    prop: "deliveryDays7",
    required: false,
    grid: { xs: 6 },
    label: "Delivery Days 7",
  },
  {
    prop: "deliveryDays14",
    required: false,
    grid: { xs: 6 },
    label: "Delivery Days 14",
  },
  {
    prop: "deliveryDays30",
    required: false,
    grid: { xs: 6 },
    label: "Delivery Days 30",
  },
  {
    prop: "deliveryDaysOver30",
    required: false,
    grid: { xs: 12 },
    label: "Delivery Days Over 30",
  },
  {
    prop: "tranTypeMagStrip",
    required: false,
    grid: { xs: 4 },
    label: "Transaction Type (Magnetic Strip)",
  },
  {
    prop: "tranTypeChip",
    required: false,
    grid: { xs: 4 },
    label: "Transaction Type (Chip)",
  },
  {
    prop: "tranTypeKeyed",
    required: false,
    grid: { xs: 4 },
    label: "Transaction Type (Keyed)",
  }
]

export default businessSummary;