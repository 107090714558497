import React from "react";
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Title } from "components";
import { countries } from "utils";
import { Typography } from "@mui/material";
import { API } from "services";
import { riskScore } from "../texts/RiskScore";

const headers = [
  "Risk Level",
  "Risk Score",
  "Partner/Master Merchant",
  "Merchant",
  "Total No. of clients",
  "Score"
]

const defaultSum = {
  low: { partner: 0, merchant: 0 },
  medL: { partner: 0, merchant: 0 },
  medH: { partner: 0, merchant: 0 },
  high: { partner: 0, merchant: 0 },
}
const defaultTotal = { low: 0, medL: 0, medH: 0, high: 0 }
const defaultScore = { low: 0, medL: 0, medH: 0, high: 0 }

const ClientStats = ({ riskList }) => {

  const [summation, setSummation] = useState(defaultSum);
  const [total, setTotal] = useState(defaultTotal);
  const [totalScore, setTotalScore] = useState(defaultScore);
  const [average, setAverage] = useState(0);

  useEffect(() => {
    getSum();
  }, [riskList])

  const getSum = () => {
    const low = calculate(riskList.low)
    const medL = calculate(riskList.medL)
    const medH = calculate(riskList.medH)
    const high = calculate(riskList.high)
    
    setSummation({ low, medL, medH, high });

    const low_total = low.partner + low.merchant;
    const medL_total = medL.partner + medL.merchant;
    const medH_total = medH.partner + medH.merchant;
    const high_total = high.partner + high.merchant;

    setTotal({ 
      low: low_total,
      medL: medL_total,
      medH: medH_total,
      high: high_total
    })

    const low_score = low_total*1;
    const medL_score = medL_total*2;
    const medH_score = medH_total*3;
    const high_score = high_total*4;

    setTotalScore({
      low: low_score,
      medL: medL_score,
      medH: medH_score,
      high: high_score
    })

    const totalSum = low_total + medL_total + medH_total + high_total;
    let average = 0;
    if (totalSum !== 0) average = ((low_score + medL_score + medH_score + high_score)/totalSum).toFixed(2)

    setAverage(average)
  }

  const calculate = (arr) => {
    let partner = 0; 
    let merchant = 0;

    arr.forEach(item => {
      const { clientType } = item;
      if (clientType === "p") partner++;
      if (clientType === "m") merchant++;
    })

    return { partner, merchant }
  }

  return <>
    <Title text="Client Type Risk Scoring" />
    <TableContainer>
      <Table size="small">
        <TableHead>
        <TableRow>
          { headers.map((item, index) => <TableCell align={index === 0 ? 'left' : 'center'} key={index}><b>{ item }</b></TableCell>) }
        </TableRow>
        </TableHead>
        <TableBody>
          { riskScore.map((item, index) => {
            const { title, score, id } = item;
            return <TableRow key={index}>
              <TableCell>{ title }</TableCell>
              <TableCell align='center'>{ score }</TableCell>
              <TableCell align='center'>{ summation[id].partner}</TableCell>
              <TableCell align='center'>{ summation[id].merchant }</TableCell>
              <TableCell align='center'>{ total[id] }</TableCell>
              <TableCell align='center'>{ totalScore[id] }</TableCell>
            </TableRow>
          })}
          <TableRow>
            <TableCell><b>Total</b></TableCell>
            <TableCell></TableCell>
            <TableCell align='center'><b>{ summation.low.partner + summation.medL.partner + summation.medH.partner + summation.high.partner }</b></TableCell>
            <TableCell align='center'><b>{ summation.low.merchant + summation.medL.merchant + summation.medH.merchant + summation.high.merchant }</b></TableCell>
            <TableCell align='center'><b>{ total.low + total.medL + total.medH + total.high }</b></TableCell>
            <TableCell align='center'><b>{ totalScore.low + totalScore.medL + totalScore.medH + totalScore.high }</b></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Typography variant="subtitle2" sx={{ mt: 2, ml: 2, color: 'gray' }}>
      Average Score: <b>{ average }</b>
    </Typography>
  </>
}

export default ClientStats;