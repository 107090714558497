const riskScore = [
  {
    title: "High Risk",
    score: 4,
    id: "high"
  },
  {
    title: "Medium/High Risk",
    score: 3,
    id: "medH"
  },
  {
    title: "Low/Medium Risk",
    score: 2,
    id: "medL"
  },
  {
    title: "Low Risk",
    score: 1,
    id: "low"
  },
];

export { riskScore };